import { Box, Button, CssBaseline, Grid, IconButton, Paper } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import ColorPicker from 'material-ui-color-picker';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import Can, { Permissions } from '../../components/Can';
import CreateEditUpgradeRule from '../../components/CreateEditUpgradeRule';
import DeleteModalButton from '../../components/DeleteModalButton';
import EditStatusButton from '../../components/EditStatusButton';
import FileInput from '../../components/FileInput';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Title from '../../components/Title';
import { IMAGE_UPLOAD_ENTITY } from '../../constants';
import changePlanStatus, { IParams } from '../../services/api/requests/changePlanStatus';
import createProduct from '../../services/api/requests/createProduct';
import deletePlan from '../../services/api/requests/deletePlan';
import deleteUpgradeRule from '../../services/api/requests/deleteUpgradeRule';
import editProductAxios from '../../services/api/requests/editProduct';
import getPlanById from '../../services/api/requests/getPlanById';
import getProductPlans, {
  IPlan,
  IProductWithPlans,
  IUpgradeRuleItem,
} from '../../services/api/requests/getProductPlans';
import { editPlan } from '../../store/entities/actions';
import { selectProductForEditing } from '../../store/entities/selectors';
import { IOption } from '../../utils/commonTypes';
import { getSearchParam, modifyQueryParams } from '../../utils/helpers';
import { setSuccessNotification } from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('name is required').max(40, '40 characters max').trim(),
  rebillyProductId: Yup.string()
    .required('Rebilly Product Id is required')
    .matches(/^[a-zA-Z0-9-_]+([-_]?)+[a-zA-Z0-9]+$/, 'These characters are not supported'),
  description: Yup.string().max(200, '200 characters max '),
});

interface IFormValues {
  name: string;
  rebillyProductId: string;
  description: string;
  bgColor: string;
  color: string;
  priority: number;
  file?: any;
  id?: string;
  status: string;
  imageUrl?: string | null;
  fullImageUrl?: any;
  urlParam: string;
}

const initialValues: IFormValues = {
  name: '',
  rebillyProductId: '',
  description: '',
  file: '',
  bgColor: '#fff',
  color: '#000',
  priority: 0,
  status: 'active',
  imageUrl: null,
  fullImageUrl: null,
  urlParam: '',
};

export default function CreateProduct() {
  const classes = useStandardFormStyles();
  const productForEditing = useSelector(selectProductForEditing);
  const dispatch = useDispatch();

  const [fullProductData, setData] = useState<IProductWithPlans>({} as IProductWithPlans);
  const [selectedRule, editRule] = useState<IUpgradeRuleItem | null>();
  const [showCreateRulePanel, togglePanel] = useState<boolean>(false);
  const { plans, upgradeRules } = fullProductData;

  const productId = getSearchParam('productId');

  const isEdit = productForEditing || fullProductData.id;

  const fk: FormikProps<IFormValues> = useFormik({
    initialValues: fullProductData.id
      ? {
          name: fullProductData.name as string,
          description: (fullProductData.description as string) || '',
          rebillyProductId: fullProductData.rebillyProductId as string,
          priority: fullProductData.priority,
          color: fullProductData.color,
          bgColor: fullProductData.bgColor,
          status: fullProductData.status,
          urlParam: fullProductData.urlParam,
          fullImageUrl: fullProductData.fullImageUrl,
          imageUrl: fullProductData.imageUrl,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values: IFormValues) => {
      if (isEdit) {
        return editProductAxios({ ...values, name: values.name.trim(), id: productForEditing?.id });
      }

      try {
        const response = await createProduct({ ...values, name: values.name.trim() });

        dispatch(push(modifyQueryParams('productId', response.data.id)));

        fetchProduct(response.data.id);
      } catch (e) {
        console.error('ON_CREATE_PRODUCT', e);
      }
    },
  });

  const createInputField = createInput<IFormValues>(fk);

  const createPlan = () => {
    dispatch(
      push(pageLinks.createEditPlan + `?productId=${productForEditing?.id || fullProductData.id}`)
    );
    const data = { plans: plans, editPlanId: null };
    dispatch(editPlan(data));
  };

  const handleEditRule = (rule: any) => {
    editRule(rule);
    togglePanel(true);
  };

  const handleEdit = async (plan: IPlan) => {
    try {
      const fullPlanData = await getPlanById({
        productId: isEdit ? Number(fullProductData.id) : 0,
        planId: plan.id,
      });

      const data = { plans: [fullPlanData.data.data], editPlanId: plan.id };

      dispatch(editPlan(data));
      dispatch(
        push(pageLinks.createEditPlan + `?productId=${fullProductData?.id}&planId=${plan.id}`)
      );
    } catch (e) {
      console.warn('error', e);
    }
  };

  const createOptions = (): IOption[] | [] =>
    plans ? plans.map((plan) => ({ value: plan.rebillyPlanId, label: plan.name })) : [];

  const fetchProduct = async (prodId?: string) => {
    try {
      const id = productForEditing?.id || productId || prodId;
      const response = await getProductPlans(id as string);
      setData(response.data.data);
    } catch (e) {
      console.warn('Error', e);
    }
  };

  const handleDeleteRule = async (id: string) => {
    const response = await deleteUpgradeRule(id as string);
    if (response) {
      setSuccessNotification('Rules has been deleted');
      fetchProduct();
    }
  };

  const handleDeletePlan = async (id: number) => {
    const response = await deletePlan(id);
    if (response) {
      setSuccessNotification('Plan has been deleted');
      fetchProduct();
    }
  };

  const handleStatusChange = async (data: IParams) => {
    const response = await changePlanStatus(data);
    if (response) {
      setSuccessNotification();
      fetchProduct();
    }
  };

  const onUpdateRules = () => {
    fetchProduct();
    togglePanel(false);
    editRule(null);
  };

  const createEditText = isEdit ? 'Edit' : 'Create';

  useEffect(() => {
    if (productId) {
      fetchProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.products} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{createEditText} Product</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('name', 'Product Name*', 12)}
              {createInputField('description', 'Description', 12)}
              {createInputField('rebillyProductId', 'Rebilly Product Id*', 6)}
              {createInputField('priority', 'Product Priority', 6, 'number')}
              {createInputField('urlParam', 'Shop URL name', 6)}

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="status"
                  label={'Status'}
                  value={fk.values.status}
                  onChange={(e): void => fk.setFieldValue('status', e.target.value)}
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <ColorPicker
                  name="color"
                  variant="outlined"
                  fullWidth
                  label={'Text Color'}
                  defaultValue="Color"
                  value={fk.values.color || '#000'}
                  onChange={(color) => color && fk.setFieldValue('color', color)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <ColorPicker
                  name="bgColor"
                  variant="outlined"
                  fullWidth
                  label={'Background Color'}
                  defaultValue="Color"
                  value={fk.values.bgColor || '#000'}
                  onChange={(color) => color && fk.setFieldValue('bgColor', color)}
                />
              </Grid>

              <Grid item xs={12}>
                <FileInput
                  id={'imageUrl'}
                  label={'Product preview image'}
                  imageUrl={fk.values.imageUrl}
                  imageUrlField={'imageUrl'}
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.PRODUCT}
                />
              </Grid>

              <Grid item xs={12}>
                <FileInput
                  id={'fullImageUrl'}
                  label={'Plan Background'}
                  imageUrl={fk.values.fullImageUrl}
                  imageUrlField={'fullImageUrl'}
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.PRODUCT_FULL_IMAGE}
                />
              </Grid>
            </Grid>

            {isEdit && (
              <>
                <Grid container xs={12}>
                  <Box margin={'20px 0 0 0'} width="100%">
                    <Title>Plans</Title>
                  </Box>
                </Grid>
                <Grid container xs={12}>
                  <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    justifyContent="space-between"
                    width={'100%'}
                  >
                    <Box
                      display={'flex'}
                      justifyContent="space-between"
                      width={'100%'}
                      alignItems={'center'}
                      padding={'0 15px'}
                    >
                      <div>Level</div>
                      <div>Entry/Recurring</div>
                      <div>Status/Actions</div>
                    </Box>

                    {plans &&
                      (plans as Array<IPlan>).map((plan) => {
                        const name = `${plan.name}`;
                        const price = `$${plan.setupPrice}/$${plan.recurringPrice || '-'}`;

                        return (
                          <Button
                            className={classes.boxButton}
                            fullWidth
                            color="primary"
                            variant={'outlined'}
                          >
                            <Box
                              display={'flex'}
                              justifyContent="space-between"
                              width={'100%'}
                              alignItems={'center'}
                            >
                              <div>{name}</div>
                              <div>{price}</div>
                              <div>
                                <EditStatusButton
                                  status={plan.status}
                                  onChange={(status: string) =>
                                    handleStatusChange({ id: plan.id, status })
                                  }
                                />

                                <Can perform={Permissions.updateCoupon}>
                                  <IconButton
                                    aria-label="edit"
                                    title={'Edit'}
                                    size={'small'}
                                    onClick={() => handleEdit(plan)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Can>

                                <Can perform={Permissions.deleteCoupon}>
                                  <DeleteModalButton
                                    name={plan.name}
                                    entity={'Plan'}
                                    onDelete={() => handleDeletePlan(plan.id)}
                                  />
                                </Can>
                              </div>
                            </Box>
                          </Button>
                        );
                      })}
                    <Box
                      width={'100%'}
                      justifyContent="space-between"
                      alignItems={'center'}
                      padding={'20px 0 0 0'}
                      onClick={createPlan}
                    >
                      <Button fullWidth variant="outlined" color="primary">
                        + Create Plan
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}

            {plans?.length > 1 && (
              <>
                <Grid container xs={12}>
                  <Box margin={'20px 0 0 0'} width="100%">
                    <Title>Upgrade Rules</Title>
                  </Box>
                </Grid>
                <Grid container xs={12}>
                  <Box
                    display={'flex'}
                    width={'100%'}
                    flexWrap={'wrap'}
                    justifyContent="space-between"
                  >
                    {upgradeRules &&
                      plans &&
                      (upgradeRules as Array<IUpgradeRuleItem>).map((rule) => {
                        const fromPlanName = plans?.find(
                          (plan) => plan.rebillyPlanId === rule.fromId
                        )?.name;
                        const toPlanName = plans?.find(
                          (plan) => plan.rebillyPlanId === rule.toId
                        )?.name;

                        const name = `${fromPlanName} => ${toPlanName}`;

                        return (
                          <Button
                            className={classes.boxButton}
                            fullWidth
                            color="primary"
                            variant={'outlined'}
                          >
                            <Box
                              display={'flex'}
                              justifyContent="space-between"
                              width={'100%'}
                              alignItems={'center'}
                            >
                              {name}
                              <div>
                                <Can perform={Permissions.updatePlanUpgradeRules}>
                                  <IconButton
                                    aria-label="edit"
                                    title={'Edit'}
                                    size={'small'}
                                    onClick={() => handleEditRule(rule)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Can>

                                <Can perform={Permissions.deletePlanUpgradeRules}>
                                  <DeleteModalButton
                                    name={name}
                                    entity={'Rule'}
                                    onDelete={() => handleDeleteRule(rule.id)}
                                  />
                                </Can>
                              </div>
                            </Box>
                          </Button>
                        );
                      })}

                    {showCreateRulePanel ? (
                      <CreateEditUpgradeRule
                        options={createOptions()}
                        onCancelClick={() => {
                          togglePanel(!showCreateRulePanel);
                          editRule(null);
                        }}
                        ruleForEditing={selectedRule}
                        onSuccess={onUpdateRules}
                      />
                    ) : (
                      <Can perform={Permissions.createPlanUpgradeRules}>
                        <Box
                          width={'100%'}
                          justifyContent="space-between"
                          alignItems={'center'}
                          padding={'20px 0 0 0'}
                          onClick={() => togglePanel(!showCreateRulePanel)}
                        >
                          <Button fullWidth variant="outlined" color="primary">
                            + Add Rule
                          </Button>
                        </Box>
                      </Can>
                    )}
                  </Box>
                </Grid>
              </>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
}
