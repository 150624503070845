import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IResponseAxios } from '../types';

interface INestedPlanItem {
  id: number;
  name: string;
  sku: string;
}

export interface INestedRule {
  overlappedPlan: INestedPlanItem;
  nestedPlan: INestedPlanItem;
  id: number;
}

export interface INestedRulesResponse {
  data: {
    data: {
      items: INestedRule[];
      meta: IMeta;
    };
  };
}

const request = () => {
  return apiClient.get<IResponseAxios>(ApiUrls.nestedRules + `?page=1&limit=100`);
};

export default request;
