import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Fab,
  Grid,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import AddIcon from '@material-ui/icons/Add';
import { subMonths } from 'date-fns';
import React, { FC } from 'react';
import { useStandardTableStyles } from '../../components/DefaultTable';
import LogDataInput from '../../components/LogDataInput';
import LogList from '../../components/LogList';
import Title from '../../components/Title';
import useCustomTableStyles from './styles';

interface getHeaderProps {
  onTypeChange: (type: string) => void;
  setDataValues: (type: string, username: string | number) => void;
  action?: string;
  customerId?: number;
  username?: string;
  styles: any;
}

const logDataInputItems = ['instance'];
const getHeader = ({
  onTypeChange,
  setDataValues,
  action,
  username,
  customerId,
  styles,
}: getHeaderProps) => {
  return [
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
      styles: styles.smallCell,
      disableSort: true,
      component: (
        <TextField
          style={{ marginBottom: '10px' }}
          label="ID"
          type={'number'}
          value={customerId}
          onChange={(e) => setDataValues('customerId', e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Username',
      disableSort: true,
      styles: styles.cell,
      component: (
        <TextField
          style={{ marginBottom: '10px' }}
          label="Username"
          value={username}
          onChange={(e) => setDataValues('username', e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Action',
      disableSort: true,
      styles: styles.cell,
      component: (
        <>
          <Select
            fullWidth
            value={action || 0}
            label="Age"
            onChange={(e) => onTypeChange(e.target.value as string)}
          >
            <MenuItem disabled value={0}>
              Action
            </MenuItem>
            <MenuItem value={'VERIFY_ORDER_ITEMS'}>verify order items</MenuItem>
            <MenuItem value={'LOGIN'}>login</MenuItem>
            <MenuItem value={'all'}>all</MenuItem>
          </Select>
        </>
      ),
    },
    {
      id: 'ipAddress',
      numeric: false,
      disablePadding: false,
      label: 'User IP address',
      styles: styles.cell,
      disableSort: true,
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Date UTC',
      styles: styles.cell,
      disableSort: true,
    },
    {
      id: 'details',
      numeric: false,
      disablePadding: false,
      label: 'Details',
      disableSort: true,
      styles: styles.cell,
    },
  ];
};

const CustomersLogs: FC<any> = () => {
  const classes = useStandardTableStyles();
  const styles = useCustomTableStyles();
  const dateFns = new DateFnsUtils();
  const [data, setData] = React.useState<{
    clientId?: number;
    username?: string;
    instance?: string;
  }>({});
  const [action, setAction] = React.useState<string>('');
  const [from, setFrom] = React.useState<string>(
    dateFns.format(subMonths(new Date(), 1), 'yyyy-MM-dd')
  );
  const [to, setTo] = React.useState<string>(dateFns.format(new Date(), 'yyyy-MM-dd'));
  const [logDataInputs, setLogDataInputs] = React.useState<
    { id: number; component: React.ReactNode }[]
  >([]);
  const [activeLogDataInputsValues, setActiveLogDataInputsValues] = React.useState<string[]>([]);

  const onTypeChange = (type: string) => {
    setAction(type);
  };

  const setDataValues = (dataType: string, value: string | number) => {
    setActiveLogDataInputsValues((prevValue) => [...prevValue, dataType]);
    setData((prevValue) => ({ ...prevValue, [dataType]: value }));
  };

  const deleteInput = (id: number, key: string) => {
    setActiveLogDataInputsValues(activeLogDataInputsValues.filter((i) => i !== key));
    setLogDataInputs((prevValue) => prevValue.filter((v) => v.id !== id));
    if (data) {
      setData(
        Object.entries(data).reduce((acc, value) => {
          if (key !== value[0]) {
            return { ...acc, [value[0]]: value[1] };
          }
          return acc;
        }, {})
      );
    }
  };

  const addInput = () => {
    if (logDataInputItems.length > logDataInputs.length) {
      const id = Math.ceil(Math.random() * 1000);
      setLogDataInputs([
        {
          id,
          component: (
            <LogDataInput
              menuItems={logDataInputItems}
              activeItems={activeLogDataInputsValues}
              setDataValues={setDataValues}
              id={id}
              key={id}
              deleteInput={deleteInput}
            />
          ),
        },
        ...logDataInputs,
      ]);
    }
  };
  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Customers logs</Title>
          </div>
        </Paper>
      </Grid>

      <TableContainer>
        <div className={classes.searchRow}>
          <TextField
            id="date-from"
            label="from"
            type="date"
            defaultValue={from}
            className={classes.textField}
            onChange={(v) => setFrom(dateFns.format(new Date(v.target.value), 'yyyy-MM-dd'))}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date-to"
            label="to"
            type="date"
            defaultValue={new Date(to)}
            className={classes.textField}
            onChange={(v) => setTo(dateFns.format(new Date(v.target.value), 'yyyy-MM-dd'))}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={classes.searchRow}>
          {activeLogDataInputsValues.length < logDataInputItems.length &&
          logDataInputs.length < logDataInputItems.length ? (
            <Box>
              <Fab size="small" color="primary" aria-label="add" onClick={() => addInput()}>
                <AddIcon />
              </Fab>
            </Box>
          ) : null}
          {logDataInputs.map((item) => item.component)}
        </div>
        <TableHead>
          <TableRow>
            {getHeader({
              onTypeChange,
              setDataValues,
              action,
              username: data?.username || '',
              customerId: data.clientId,
              styles,
            }).map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                className={headCell.styles}
              >
                {headCell.component ? headCell.component : headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <LogList action={action} data={data} from={from} to={to} />
      </TableContainer>
    </div>
  );
};

export default CustomersLogs;
