import { Button, CssBaseline, Grid, IconButton, Paper, TextField } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import * as generator from 'generate-password';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import InputPassword from '../../components/InputPassword';
import InputSelect from '../../components/InputSelect';
import Title from '../../components/Title';
import useEmailValidator from '../../hooks/emailValidatior';
import createAdmin, { ICreateAdmin } from '../../services/api/requests/createAdmin';
import editAdmin from '../../services/api/requests/editAdmin';
import { editAdminUser } from '../../store/entities/actions';
import { selectAdminRoles, selectUserForEditing } from '../../store/entities/selectors';
import {
  anyLetterRegExp,
  hasConsecutiveSymbols,
  hasRequiredSymbols,
  hasSpecialCharacters,
} from '../../utils/helpers';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required')
    .min(2, 'First Name should contain at least two letters')
    .matches(anyLetterRegExp, 'These characters are not supported'),

  lastName: Yup.string()
    .required('Last name is required')
    .min(2, 'Last Name should contain at least two letters')
    .matches(anyLetterRegExp, 'These characters are not supported'),

  email: Yup.string().email('Email address is not valid').required('Email is required'),

  password: Yup.mixed()
    .required('this field is required')
    .test(
      'has-consecutive',
      'should not have 3 consecutive letters or numbers',
      hasConsecutiveSymbols
    )
    .test('has-special-characters', 'should have special characters', hasSpecialCharacters)
    .test(
      'letters - numbers',
      'should have 1 letter / 1 number / 1 uppercase / 1 special characters',
      hasRequiredSymbols
    )
    .test('is-long-enough', 'minimum 10 characters long', (value) => value?.length >= 10),
});

const initialValues: ICreateAdmin = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  roleId: '',
};

export default function CreateAdmin() {
  const classes = useStandardFormStyles();
  const userForEditing = useSelector(selectUserForEditing);
  const adminRoles = useSelector(selectAdminRoles);
  const { emailError, validateEmail } = useEmailValidator();
  const dispatch = useDispatch();

  useEffect((): any => {
    return () => dispatch(editAdminUser(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fk: FormikProps<ICreateAdmin> = useFormik({
    initialValues: userForEditing
      ? {
          email: userForEditing.email,
          firstName: userForEditing.firstName,
          lastName: userForEditing.lastName,
          password: '',
          roleId: userForEditing.role.id,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values: ICreateAdmin) => {
      try {
        if (userForEditing) {
          await editAdmin({ ...values, id: userForEditing.id });
        } else {
          await createAdmin(values);
        }
        dispatch(push(pageLinks.admins));
        setNotification('success', {
          message: 'Success',
        });
      } catch (e) {
        console.warn('erroror', e);
      }
    },
  });

  const validateEmailAddress = (e: React.FocusEvent<HTMLInputElement>) => {
    fk.handleBlur(e);
    validateEmail(e.target.value);
  };

  const generatePassword = () => {
    const password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
      excludeSimilarCharacters: true,
      strict: true,
    });
    fk.setFieldValue('password', password, true);
  };

  const options = adminRoles?.map((role) => ({ label: role.name, value: role.id })) || [];
  const createEditText = userForEditing ? 'Edit' : 'Create';

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.admins} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{createEditText} User</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  error={!!(fk.errors.firstName && fk.touched.firstName)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.firstName && fk.errors.firstName}
                  value={fk.values.firstName}
                  onChange={fk.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  error={!!(fk.errors.lastName && fk.touched.lastName)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.lastName && fk.errors.lastName}
                  value={fk.values.lastName}
                  onChange={fk.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="emailnnn"
                  error={!!((fk.errors.email && fk.touched.email) || emailError?.length)}
                  onBlur={validateEmailAddress}
                  helperText={fk.touched.email && (fk.errors.email || emailError)}
                  value={fk.values.email}
                  onChange={fk.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <InputSelect
                  id="permissionIds"
                  label={'Role'}
                  value={fk.values.roleId}
                  required
                  onChange={(e) => fk.setFieldValue('roleId', e.target.value)}
                  options={options}
                />
              </Grid>

              <Grid item xs={12}>
                <InputPassword
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="admin-password-nnn"
                  error={!!(fk.errors.password && fk.touched.password)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.password && fk.errors.password}
                  value={fk.values.password}
                  onChange={fk.handleChange}
                >
                  <IconButton
                    onClick={generatePassword}
                    edge="end"
                    title={'generate a new password'}
                  >
                    <CachedIcon />
                  </IconButton>
                </InputPassword>
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
}
