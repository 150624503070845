import setNotification from './notifications';

function onAxiosError(data: any): void {
  const messageResponse = data?.message;
  let text;
  try {
    if (Array.isArray(messageResponse)) {
      const getValue = (val: any) => {
        return val
          .map((item: { constraints: string }) => Object.values(item.constraints))
          .reduce((acc: string, value: string) => `${acc} ${value} `, '');
      };

      text =
        typeof messageResponse[0] === 'string' ? messageResponse[0] : getValue(messageResponse);
    }

    if (typeof messageResponse === 'string') {
      text = messageResponse;
    }
  } catch (e) {
    text = null;
  }
  setNotification('error', {
    message: text,
  });
}

export default onAxiosError;
