import { Button, CssBaseline, Grid, Paper, TextField } from '@material-ui/core';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import createGateway, { ICreateGateway } from '../../services/api/requests/createGateway';
import editGateway, { IEditGateway } from '../../services/api/requests/editGateway';
import { editGatewayData } from '../../store/entities/actions';
import { selectGatewayForEditing } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  processingFee: Yup.number().test('is-decimal', 'please use xx.xx format', (value) =>
    Boolean(String(value).match(/^([0-9]{0,2})((\.[0-9]{0,2})?)$/))
  ),
  invoiceAbbr: Yup.string().max(10).min(1),
  companyName: Yup.string().max(40),
  invoiceAddress: Yup.string().max(100),
});

const initialValues: ICreateGateway = {
  processingFee: 0.0,
  exigoTransactionField: '',
  invoiceAbbr: '',
  companyName: '',
  invoiceAddress: '',
};

const CreateEditGateway: FC = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const selectedGateway = useSelector(selectGatewayForEditing);

  const action = selectedGateway ? 'Edit' : 'Create';

  useEffect((): any => {
    return () => {
      dispatch(editGatewayData(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fk: FormikProps<ICreateGateway> = useFormik({
    initialValues: selectedGateway
      ? {
          processingFee: selectedGateway.processingFee,
          exigoTransactionField: selectedGateway.exigoTransactionField,
          invoiceAbbr: selectedGateway.invoiceAbbr,
          companyName: selectedGateway.companyName,
          invoiceAddress: selectedGateway.invoiceAddress,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const createData: ICreateGateway = {
        processingFee: values.processingFee,
        exigoTransactionField: values.exigoTransactionField,
        invoiceAbbr: values.invoiceAbbr,
        companyName: values.companyName,
        invoiceAddress: values.invoiceAddress,
      };

      if (selectedGateway) {
        const editData: IEditGateway = {
          ...createData,
          id: selectedGateway.id,
        };
        editGateway(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            dispatch(push(pageLinks.payments));
          }
        });
        return;
      }

      createGateway(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.payments));
        }
      });
    },
  });

  return (
    <Grid item xs={12}>
      <BackButton name="Back" link={pageLinks.payments} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{action} Gateway</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  autoComplete="Processing Fee"
                  name="processingFee"
                  variant="outlined"
                  required
                  fullWidth
                  id="processingFee"
                  type="number"
                  label="Processing Fee, %"
                  autoFocus
                  error={!!(fk.errors.processingFee && fk.touched.processingFee)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.processingFee && fk.errors.processingFee}
                  value={fk.values.processingFee}
                  onChange={fk.handleChange}
                  inputProps={{ step: 0.01 }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  autoComplete="Exigo Transaction Field"
                  name="exigoTransactionField"
                  variant="outlined"
                  required
                  fullWidth
                  id="exigoTransactionField"
                  type="text"
                  label="Exigo Transaction Field"
                  autoFocus
                  error={!!(fk.errors.exigoTransactionField && fk.touched.exigoTransactionField)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.exigoTransactionField && fk.errors.exigoTransactionField}
                  value={fk.values.exigoTransactionField}
                  onChange={fk.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  autoComplete="Invoice Abbr"
                  name="invoiceAbbr"
                  variant="outlined"
                  required
                  fullWidth
                  id="invoiceAbbr"
                  type="text"
                  label="Company Abbr"
                  autoFocus
                  error={!!(fk.errors.invoiceAbbr && fk.touched.invoiceAbbr)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.invoiceAbbr && fk.errors.invoiceAbbr}
                  value={fk.values.invoiceAbbr}
                  onChange={fk.handleChange}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  autoComplete="companyName"
                  name="companyName"
                  variant="outlined"
                  fullWidth
                  id="companyName"
                  type="text"
                  label="Company Descriptor"
                  autoFocus
                  error={!!(fk.errors.companyName && fk.touched.companyName)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.companyName && fk.errors.companyName}
                  value={fk.values.companyName}
                  onChange={fk.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  autoComplete="iАnvoiceAddress"
                  name="invoiceAddress"
                  variant="outlined"
                  fullWidth
                  id="invoiceAddress"
                  type="text"
                  label="Address"
                  autoFocus
                  error={!!(fk.errors.invoiceAddress && fk.touched.invoiceAddress)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.invoiceAddress && fk.errors.invoiceAddress}
                  value={fk.values.invoiceAddress}
                  onChange={fk.handleChange}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {action}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditGateway;
