import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';

interface ISignedDocumentPlan {
  createdAt: Date;
  id: number;
  name: string;
  productId: number;
  rebillyPlanId: string;
  status: string;
}

export interface ISignedDocument {
  createdAt: Date;
  data: { firstName: string; lastName: string; siteUrl?: string };
  userId: number;
  id: number;
  plans: ISignedDocumentPlan[];
  type: string;
  url: string;
}

export interface IParams extends IPaginationParams {
  search: string;
}

export interface ISignedDocumentResponse {
  items: ISignedDocument[];
  meta: IMeta;
}

const request = (params: IParams) => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get<ISignedDocumentResponse>(ApiUrls.signedDocuments + `?${query}`)
    .then((response) => response);
};

export default request;
