import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/Image';
import clsx from 'clsx';
import * as React from 'react';
import { IProductListItem } from '../../services/api/requests/getProductList';
import Modal from '../Modal';
import './styles.css';

const PreviewModalButton = ({ data }: { data: IProductListItem }) => {
  return (
    <Modal
      button={
        <IconButton aria-label="preview" title={'Preview'}>
          <ImageIcon />
        </IconButton>
      }
      renderContent={() => {
        const img = data?.imageUrl
          ? data?.imageUrl.replace('.png', '')
          : data?.imageUrl || data?.fullImageUrl;
        return (
          <div className={'card'}>
            <div className={'card-overlay'} />
            <div
              className={'card-background'}
              style={{
                background: `url(${img}) no-repeat center/cover`,
              }}
            />
            <div className={'card-content'} style={{ zIndex: 1 }}>
              <div className={'card-body'} style={{ zIndex: 1 }}>
                <p className={clsx('title', 'dont-translate')}>{data?.name}</p>

                <p className={'text'}>
                  starting from &nbsp;
                  <span className={'price'}>$100/month</span>
                </p>

                <p className={'text'}>click to see more</p>
              </div>
              <div
                className={'card-actions'}
                style={{
                  backgroundColor: data?.bgColor || '#5d01a2',
                  color: data?.color || '#fff',
                }}
              >
                <a className={clsx('link')} href={'/'}>
                  <span>Become a member</span>
                </a>
                <i className={clsx('link', 'icon-arrow-right')} />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default PreviewModalButton;
