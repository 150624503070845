interface ILinks {
  home: string;
  login: string;
  forgotPassword: string;
  forgotPasswordSuccess: string;
  createNewPassword: string;
  createNewPasswordSuccess: string;
  createEditAdmin: string;
  customers: string;
  orders: string;
  admins: string;
  instances: string;
  coupons: string;
  createEditCoupon: string;
  createEditPermissionRole: string;
  createEditPermissionGroup: string;
  permissions: string;
  createEditInstance: string;
  products: string;
  createEditProduct: string;
  createEditPlan: string;
  payments: string;
  settings: string;
  createEditSetting: string;
  createEditGateway: string;
  createEditPaymentMethod: string;
  csvReport: string;
  addCsvReport: string;
  createEditCombo: string;
  createEditComboProduct: string;
  customerPayouts: string;
  userMigrationsReport: string;
  payoutDetails: string;
  nestedRules: string;
  massPaymentRequests: string;
  massPayoutRequestDetails: string;
  whiteList: string;
  createWhiteListIp: string;
  legalDocuments: string;
  createEditLegalDocument: string;
  signedDocuments: string;
  kyc: string;
  countriesGroups: string;
  editCountriesGroup: string;
  createCountriesGroup: string;
  customersLogs: string;
  productsActivations: string;
  createEditConversionTemplate: string;
}

const links: ILinks = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  forgotPasswordSuccess: '/forgot-password-success',
  createNewPassword: '/create-new-password',
  createNewPasswordSuccess: '/create-new-password-success',
  createEditAdmin: '/user-create-edit',
  customers: '/customers',
  admins: '/admins',
  instances: '/instances',
  orders: '/orders',
  coupons: '/coupons',
  createEditCoupon: '/create-edit-coupon',
  createEditPermissionRole: '/create-edit-permission-role',
  createEditPermissionGroup: '/create-edit-permission-group',
  permissions: '/admin-permissions',
  createEditInstance: '/create-edit-instance',
  products: '/products',
  createEditProduct: '/create-edit-product',
  payments: '/payments',
  settings: '/settings',
  createEditSetting: '/create-edit-setting',
  createEditGateway: '/create-edit-gateway',
  createEditPaymentMethod: '/create-edit-payment-method',
  csvReport: '/payout-export',
  addCsvReport: '/create-payout-report',
  createEditPlan: '/create-edit-plan',
  createEditCombo: '/create-edit-combo',
  customerPayouts: '/customer-payouts',
  userMigrationsReport: '/user-migrations-report',
  payoutDetails: '/payout-details',
  createEditComboProduct: '/create-edit-combo-product',
  nestedRules: '/nested-rules',
  massPayoutRequestDetails: '/payout-request-details',
  massPaymentRequests: '/payments-requests',
  legalDocuments: '/legal-documents',
  createEditLegalDocument: '/create-edit-legal-documents',
  signedDocuments: '/signed-documents',
  whiteList: '/white-list',
  createWhiteListIp: '/create-white-list-ip',
  kyc: '/kyc',
  countriesGroups: '/country-groups',
  editCountriesGroup: '/edit-country-group',
  createCountriesGroup: '/create-country-group',
  customersLogs: '/customers-logs',
  productsActivations: '/products-activations',
  createEditConversionTemplate: '/create-edit-conversion-template',
};

export default links;
