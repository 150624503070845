interface ITokens {
  refreshToken: string | null;
  accessToken: string | null;
}

export function getTokens(): ITokens {
  return {
    refreshToken: localStorage.getItem('RT'),
    accessToken: localStorage.getItem('AT'),
  };
}

export function setTokens(data: ITokens): void {
  if (data.refreshToken && data.accessToken) {
    localStorage.setItem('RT', data.refreshToken);
    localStorage.setItem('AT', data.accessToken);
  }
}
