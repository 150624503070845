import theme1 from './theme1';
import theme2 from './theme2';
import theme3 from './theme3';

export interface ITheme {
  colors: {
    primaryText: string;
  };
  palette: {
    primary: {
      contrastText: string;
      main: string;
    };
  };
  transparentBackground: {
    dark: string;
    light: string;
  };
}

export const themes: { [key: string]: ITheme } = {
  theme1,
  theme2,
  theme3,
};
