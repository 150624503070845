import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit">Admin Panel</Link>
      &nbsp;
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
