import { Box, Fab, MenuItem, Select, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import useStyles from './styles';

interface IProps {
  menuItems: string[];
  activeItems: string[];
  setDataValues: (select: string, text: string) => void;
  id: number;
  deleteInput: (id: number, value: string) => void;
}

const LogDataInput: React.FC<IProps> = ({
  menuItems,
  activeItems,
  setDataValues,
  id,
  deleteInput,
}: IProps) => {
  const classes = useStyles();
  const [selectData, setSelectData] = useState<string>('');
  const [textFieldData, setTextFieldData] = useState<string>('');
  return (
    <Box className={classes.wrapper}>
      <div className={classes.selectWrapper}>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Age"
          placeholder={'type'}
          onChange={(e) => {
            setSelectData(e.target.value as string);
            setDataValues(e.target.value as string, textFieldData);
          }}
        >
          {menuItems.map((item) => (
            <MenuItem disabled={!!activeItems.find((i) => i === item)} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={classes.inputWrapper}>
        <TextField
          label=""
          size={'small'}
          disabled={!selectData}
          onChange={(e) => {
            setTextFieldData(e.target.value as string);
            setDataValues(selectData, e.target.value as string);
          }}
          variant="standard"
        />
      </div>
      <div>
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          onClick={() => deleteInput(id, selectData)}
        >
          <DeleteIcon />
        </Fab>
      </div>
    </Box>
  );
};

export default LogDataInput;
