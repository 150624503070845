import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { ICreatePaymentMethod } from './createPaymentMethod';

export interface IEditPaymentMethod extends ICreatePaymentMethod {
  id: number;
}

const request = (data: IEditPaymentMethod) => {
  return apiClient
    .put<IResponseAxios>(ApiUrls.editPaymentMethod + data.id, data)
    .then((response) => response);
};

export default request;
