import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { FC, useEffect } from 'react';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import getProductsActivations, {
  changeOrderStatus,
  IProductActivationParams,
} from '../../services/api/requests/getProductsActivations';
import { IMeta } from '../../services/api/types';
import useCustomStyles from './style';

interface IHeaderProps extends IProductActivationParams {
  onInputDataChange: (type: string, value: string | number | boolean) => void;
  styles: any;
}

const getTableHeader = ({
  onInputDataChange,
  searchByUser,
  verifyFilter,
  styles,
}: IHeaderProps) => {
  return [
    {
      id: 'searchByUser',
      numeric: false,
      disablePadding: false,
      disableSort: true,
      style: styles.smallCell,
      component: (
        <TextField
          placeholder={'ID'}
          type={'number'}
          value={searchByUser}
          onChange={(e) => onInputDataChange('searchByUser', e.target.value)}
        />
      ),
    },
    {
      id: 'username',
      numeric: false,
      disablePadding: false,
      label: 'Username',
      disableSort: true,
      style: styles.cell,
    },
    {
      id: 'product',
      numeric: false,
      disablePadding: false,
      label: 'Product',
      disableSort: true,
      style: styles.cell,
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: false,
      label: 'Created at',
      disableSort: true,
      style: styles.cell,
    },
    {
      id: 'verifyFilter',
      numeric: false,
      disablePadding: false,
      label: 'Verified',
      disableSort: true,
      style: styles.cell,
      component: (
        <>
          <Select
            fullWidth
            value={verifyFilter === 'all' ? verifyFilter : Number(verifyFilter)}
            label="Verified"
            onChange={(e) =>
              onInputDataChange(
                'verifyFilter',
                e.target.value === 'all' ? e.target.value : e.target.value === 1
              )
            }
          >
            <MenuItem value={'all'}>All</MenuItem>
            <MenuItem value={1}>Verified</MenuItem>
            <MenuItem value={0}>Unverified</MenuItem>
          </Select>
        </>
      ),
    },
  ];
};

const ProductsActivations: FC<any> = () => {
  const styles = useCustomStyles();
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [page, setPage] = React.useState<number>(1);
  const [data, setData] = React.useState<IProductActivationParams>({ verifyFilter: 'all' });
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [productsActivationsList, setList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const fetchProductsActivations = async () => {
    if (productsActivationsList.length) {
      setList([]);
    }

    try {
      const response = await getProductsActivations({
        page,
        limit: rowsPerPage,
        ...data,
      });

      setList(response.data.items);
      setMeta(response.data.meta);
    } catch (e) {
      console.warn(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchProductsActivations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, data.searchByUser, data.statusFilter, data.verifyFilter]);

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const onInputDataChange = (type: string, value: string | number | boolean) => {
    setData((prevState) => ({ ...prevState, [type]: value }));
  };

  const onStatusChange = async (orderId: number) => {
    try {
      await changeOrderStatus(orderId);
      setList((prevState) =>
        prevState.map((item) => {
          if (item.orderId === orderId) {
            return { ...item, status: true };
          }
          return item;
        })
      );
    } catch (e) {
      console.warn(e.message);
    }
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Products Activations</Title>
          </div>
        </Paper>
      </Grid>
      <TableContainer>
        <TableHead>
          {getTableHeader({
            onInputDataChange,
            searchByUser: data.searchByUser,
            verifyFilter: data.verifyFilter,
            styles,
          }).map((headCell) => (
            <TableCell
              className={headCell.style}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
            >
              {headCell.component || headCell.label}
            </TableCell>
          ))}
        </TableHead>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <TableBody>
            {loading && <TableSkeleton />}
            {!loading && !productsActivationsList?.length ? (
              <TableRow>
                <TableCell>There are no products</TableCell>
              </TableRow>
            ) : (
              productsActivationsList.map((item, index) => {
                return (
                  <TableRow key={item.orderId}>
                    <TableCell className={styles.smallCell}>{item.userId}</TableCell>
                    <TableCell className={styles.cell}>{item.username}</TableCell>
                    <TableCell className={styles.cell}>{item.product}</TableCell>
                    <TableCell className={styles.cell}>
                      {dateFns.format(new Date(item.createdAt), 'dd/MM/yyyy hh:mm')}
                    </TableCell>
                    <TableCell className={styles.cell}>
                      <Can perform={Permissions.verifyOrderItems}>
                        <Switch
                          color="primary"
                          checkedIcon={<CheckCircleIcon />}
                          checked={item.status}
                          onChange={(e) => onStatusChange(item.orderId)}
                          disabled={item.status}
                        />
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default ProductsActivations;
