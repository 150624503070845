import { createAction } from '@reduxjs/toolkit';
import { IDocumentBody } from '../../services/api/requests/addLegalDocument';
import { ICreateCombo } from '../../services/api/requests/createComboPlan';
import { ICoupon } from '../../services/api/requests/createCoupon';
import { IAdmin } from '../../services/api/requests/getAdminList';
import { IAdminRoles } from '../../services/api/requests/getAdminRoles';
import { IProductComboItem } from '../../services/api/requests/getComboProducts';
import { IConversionTemplate } from '../../services/api/requests/getConversionTemplatesList';
import { ICountryGroup } from '../../services/api/requests/getCountries';
import { INormalizedCouponUsageRuleSet } from '../../services/api/requests/getCouponUsageRules';
import { INormalizedPayout } from '../../services/api/requests/getCustomerPayoutList';
import { IGateway } from '../../services/api/requests/getGatewayList';
import { ICountry, IGetInstance } from '../../services/api/requests/getInstances';
import { IPaymentMethod } from '../../services/api/requests/getPaymentMethodList';
import { IProductListItem } from '../../services/api/requests/getProductList';
import { IPlan } from '../../services/api/requests/getProductPlans';
import { IProduct } from '../../services/api/requests/getProducts';
import { INormalizedPurchaseRuleSet } from '../../services/api/requests/getPurchaseRules';
import { IService } from '../../services/api/requests/getServices';
import { ISetting } from '../../services/api/requests/getSettingsList';
import { UserDetails } from '../../services/api/requests/getUserDetails';
import { IPayoutAdminRequestListItem } from '../../services/api/requests/massPayouts';
import { IPermission, IPermissionGroup } from '../../services/api/requests/permissions';

export const setProducts = createAction<IProduct[]>('SET_PRODUCTS');
export const fetchStartData = createAction('FETCH_START_DATA');
export const setPermissions = createAction<IPermission[]>('SET_PERMISSIONS');
export const setPermissionGroups = createAction<IPermissionGroup[]>('SET_PERMISSION_GROUPS');
export const editProduct = createAction<IProductListItem | null>('EDIT_PRODUCT');
export const editAdminUser = createAction<IAdmin | null>('EDIT_ADMIN_USER');
export const setAdminRoles = createAction<IAdminRoles[]>('FETCH_ADMIN_ROLES_LIST_SUCCESS');
export const editCombo = createAction<IProductComboItem | null>('EDIT_COMBO');
export const editCouponData = createAction<ICoupon | null>('EDIT_COUPON');
export const editInstanceData = createAction<IGetInstance | null>('EDIT_INSTANCE');
export const editGatewayData = createAction<IGateway | null>('EDIT_GATEWAY');
export const editSettingData = createAction<ISetting | null>('EDIT_SETTING');
export const editConversionTemplate = createAction<IConversionTemplate | null>(
  'EDIT_CONVERSION_TEMPLATE'
);
export const editPaymentMethodData = createAction<IPaymentMethod | null>('EDIT_PAYMENT_METHOD');
export const saveInstances = createAction<IGetInstance[] | []>('SAVE_INSTANCES');
export const saveCountries = createAction<ICountry[]>('SAVE_COUNTRIES');
export const saveCountryGroups = createAction<ICountryGroup[]>('SAVE_COUNTRY_GROUPS');
export const editPlan = createAction<{ plans: IPlan[]; editPlanId: number | null } | null>(
  'EDIT_PLAN'
);
export const editLegalDocument = createAction<IDocumentBody | null>(
  'SET_LEGAL_DOCUMENT_FOR_EDITING'
);
export const editComboPlan = createAction<ICreateCombo | null>('CREATE_COMBO_PLAN');
export const payoutDetails = createAction<INormalizedPayout | null>('PAYOUT_DETAILS');
export const fetchCountries = createAction('FETCH_COUNTRIES');
export const fetchAdminRoles = createAction('FETCH_ADMIN_ROLES_LIST');
export const fetchPermissions = createAction('FETCH_PERMISSIONS');
export const updateInstanceList = createAction('UPDATE_INSTANCE_LIST');
export const saveServices = createAction<IService[]>('SAVE_SERVICES');
export const savePaymentMethods = createAction<IPaymentMethod[]>('SAVE_PAYMENT_METHODS');
export const fetchUserDetails = createAction<number>('GET_USER_DETAILS');
export const setUserDetails = createAction<UserDetails>('SET_USER_DETAILS');
export const showPayoutRequestInfo = createAction<IPayoutAdminRequestListItem[] | null>(
  'SHOW_PAYOUT_REQUEST_DETAILS'
);
export const syncServices = createAction('SYNC_SERVICES');

export const setPurchaseRules = createAction<INormalizedPurchaseRuleSet[] | []>(
  'SET_PURCHASE_RULES'
);

export const setCouponUsageRules = createAction<INormalizedCouponUsageRuleSet[] | []>(
  'SET_COUPON_USAGE_RULES'
);

export const fetchStartDataByUrl = createAction('FETCH_START_DATA_BY_URL');
