import { Button, CssBaseline, Grid, Paper, TextField } from '@material-ui/core';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import createPaymentMethod, {
  ICreatePaymentMethod,
} from '../../services/api/requests/createPaymentMethod';
import editPaymentMethod, {
  IEditPaymentMethod,
} from '../../services/api/requests/editPaymentMethod';
import { editPaymentMethodData } from '../../store/entities/actions';
import { selectPaymentMethodForEditing } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  defaultProcessingFee: Yup.number().test('is-decimal', 'please use xx.xx format', (value) =>
    Boolean(String(value).match(/^([0-9]{0,2})((\.[0-9]{0,2})?)$/))
  ),
  priority: Yup.number().test('is-integer', 'please select integer from 0 to 99', (value) =>
    Boolean(String(value).match(/^([0-9]{0,2})$/))
  ),
});

const initialValues: ICreatePaymentMethod = {
  defaultProcessingFee: 0.0,
  priority: 1,
};

const CreateEditPaymentMethod: FC = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const selectedPaymentMethod = useSelector(selectPaymentMethodForEditing);

  const action = selectedPaymentMethod ? 'Edit' : 'Create';

  useEffect((): any => {
    return () => {
      dispatch(editPaymentMethodData(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fk: FormikProps<ICreatePaymentMethod> = useFormik({
    initialValues: selectedPaymentMethod
      ? {
          defaultProcessingFee: selectedPaymentMethod.defaultProcessingFee,
          priority: selectedPaymentMethod.priority,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const createData: ICreatePaymentMethod = {
        defaultProcessingFee: values.defaultProcessingFee,
        priority: values.priority,
      };

      if (selectedPaymentMethod) {
        const editData: IEditPaymentMethod = {
          ...createData,
          id: selectedPaymentMethod.id,
        };
        editPaymentMethod(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            dispatch(push(pageLinks.payments));
          }
        });
        return;
      }

      createPaymentMethod(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.payments));
        }
      });
    },
  });

  return (
    <Grid item xs={12}>
      <BackButton name="Back" link={pageLinks.payments} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{action} Payment Method</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete="Default Processing Fee"
                  name="defaultProcessingFee"
                  variant="outlined"
                  required
                  fullWidth
                  id="defaultProcessingFee"
                  type="number"
                  label="Default Processing Fee, %"
                  autoFocus
                  error={!!(fk.errors.defaultProcessingFee && fk.touched.defaultProcessingFee)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.defaultProcessingFee && fk.errors.defaultProcessingFee}
                  value={fk.values.defaultProcessingFee}
                  onChange={fk.handleChange}
                  inputProps={{ step: 0.01 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="priority"
                  variant="outlined"
                  required
                  fullWidth
                  id="priority"
                  type="number"
                  label="UI priority order, number"
                  error={!!(fk.errors.priority && fk.touched.priority)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.priority && fk.errors.priority}
                  value={fk.values.priority}
                  onChange={fk.handleChange}
                  inputProps={{ step: 1 }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {action}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditPaymentMethod;
