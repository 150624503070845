import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface INestedRuleBody {
  nestedPlanId: number | '';
  overlappedPlanId: number | '';
}

const request = (params: INestedRuleBody) => {
  return apiClient
    .post<IResponseAxios>(ApiUrls.nestedRules, params)
    .then((response) => response.data);
};

export default request;
