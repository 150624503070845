import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import 'date-fns';
import { FieldArray, FormikProps, FormikProvider, useFormik } from 'formik';
import { History, Location } from 'history';
import { isEmpty } from 'lodash';
import ColorPicker from 'material-ui-color-picker';
import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'react-router';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import BulletDescriptions from '../../components/BulletDescriptions';
import Can, { Permissions } from '../../components/Can';
import CountrySelect from '../../components/CountrySelect';
import FileInput from '../../components/FileInput';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import IconMoonSelect from '../../components/IconMoonSelect';
import InputSelect from '../../components/InputSelect';
import PlanPurchaseRules from '../../components/PurchaseRules';
import Title from '../../components/Title';
import UpsellingRulesList from '../../components/UpsellingRulesList';
import { AVAILABLE_REGIONAL_DATASET_FIELDS, IMAGE_UPLOAD_ENTITY } from '../../constants';
import createProductPlan, {
  ICreatePlan,
  IDataSet,
} from '../../services/api/requests/createProductPlan';
import editProductPlan from '../../services/api/requests/editProductPlan';
import { INormalizedPurchaseRuleSet } from '../../services/api/requests/getPurchaseRules';
import { getAvailableServices, IServiceResponse } from '../../services/api/requests/getServices';
import { setPurchaseRules } from '../../store/entities/actions';
import {
  selectPaymentMethodOptions,
  selectPlanForEditing,
  selectPurchaseRules,
  selectServicesOptions,
} from '../../store/entities/selectors';
import { IOption } from '../../utils/commonTypes';
import { getSearchParam, normalizePlanData } from '../../utils/helpers';
import setNotification, { setSuccessNotification } from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const requiredMessage = 'This field is required';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(requiredMessage).max(40, "Value can't be more than 40"),
  fullDescription: Yup.string().max(200, "Value can't be more than 40"),
  itemDescription: Yup.string().max(200, "Value can't be more than 40"),
  recurringSKU: Yup.string().matches(/^[a-zA-Z0-9-_]+$/, 'These characters are not supported'),
  sku: Yup.string()
    .required(requiredMessage)
    .matches(/^[a-zA-Z0-9-_]+$/, 'These characters are not supported'),
  color: Yup.string().required(requiredMessage),
  bgColor: Yup.string().required(requiredMessage),
  rebillyPlanId: Yup.string().required(requiredMessage),
  discountExigoItem: Yup.string().required(requiredMessage),
  priority: Yup.string().required(requiredMessage),
  setupPrice: Yup.number().required(requiredMessage),
  CV: Yup.number().required(requiredMessage),
  FSB: Yup.number().required(requiredMessage),
  QV: Yup.number().required(requiredMessage),
  SCV: Yup.number().required(requiredMessage),
  SQV: Yup.number().required(requiredMessage),
  isCashbackActive: Yup.boolean(),
  enrollerCashback: Yup.number()
    .min(0, "Value can't be less than 0")
    .max(100, "Value can't be more than 100")
    .required(requiredMessage),
  customerCashback: Yup.number()
    .min(0, "Value can't be less than 0")
    .max(100, "Value can't be less than 100")
    .required(requiredMessage),

  splitFSB: Yup.number().when('splitValues', (splVal: number) => {
    if (splVal) {
      return Yup.number().test(
        'has-min-required-characters',
        'Should be the same as SplitValues or 0 and shouldn\'t be equal "1"',
        (fsb) => {
          const splitValue = Number(splVal);
          if (fsb === 1) {
            return false;
          } else if (!fsb) {
            return true;
          }
          return splitValue === fsb;
        }
      );
    }

    return Yup.number().test(
      'has-min-required-characters',
      'Shouldn\'t be equal "1"',
      (val) => val !== 1
    );
  }),

  splitValues: Yup.number().test(
    'has-min-required-characters',
    'Shouldn\'t be equal "1"',
    (val) => val !== 1
  ),
});

const createDataSetFields = (props: {
  hasRetail: boolean;
  isRecurring: boolean;
  fsbEnabled: boolean;
}) => {
  const { hasRetail, isRecurring, fsbEnabled } = props;
  const { defaultFields, recurring, retail, retailRecurring } = AVAILABLE_REGIONAL_DATASET_FIELDS;

  const regionalDatasetsFields = [...defaultFields];

  if (hasRetail) {
    regionalDatasetsFields.push(...retail);
  }
  if (isRecurring) {
    //? TEMP REMOVED intervalSize FROM REG DATASET
    const fields = recurring.filter((item) => item !== 'intervalSize');
    regionalDatasetsFields.push(...fields);
  }
  if (hasRetail && isRecurring) {
    regionalDatasetsFields.push(...retailRecurring);
  }

  const filterOptions = fsbEnabled
    ? ['recurringSKU', 'retailExigoItem', 'FSB']
    : ['recurringSKU', 'retailExigoItem'];

  return regionalDatasetsFields.filter((item) => !filterOptions.includes(item));
};

const getValuesFromEditedPlan = (plan: ICreatePlan): ICreatePlan => {
  const data = { ...initialValues } as ICreatePlan;
  for (let key in plan) {
    if (plan.hasOwnProperty(key) && plan[key as keyof ICreatePlan] !== null) {
      //@ts-ignore
      data[key as typeof key] = plan[key];
    }
  }
  return data;
};

const initialValues: ICreatePlan = {
  name: '',
  recurringSKU: '',
  sku: '', //+
  countryCodes: [],
  isAddon: true,
  additionalInfo: '',
  bullets: [],
  color: '#000',
  bgColor: '#000',
  currency: 'USD',
  description: '',
  discountExigoItem: '',
  fullDescription: '',
  file: {},
  intervalSize: 30,
  intervalUnit: 'day',
  itemDescription: '',
  priority: 1,
  fsbEnabled: false,
  autopayable: false,
  noExigo: false,
  rebillyPlanId: '',
  questDollarsReward: 0,
  recurringCV: 0,
  recurringFSB: 0,
  recurringPrice: 0,
  recurringQV: 0,
  recurringRetailPrice: 0,
  isRecurring: false,
  icon: '',
  hasRetail: false,
  recurringSCV: 0,
  recurringSQV: 0,
  retailPrice: 0,
  setupPrice: 0,
  serviceIDs: [],
  CV: 0,
  FSB: 0,
  QV: 0,
  SCV: 0,
  SQV: 0,
  splitValues: 0,
  splitFSB: 0,
  splitSKU: '',
  splitBaseValue: 0,
  regionalDatasets: [],
  retailExigoItem: '',
  retailRecurringCV: 0,
  retailRecurringQV: 0,
  oneDayRecurringSKU: '',
  retailRecurringSCV: 0,
  retailRecurringSQV: 0,
  retailRecurringFSB: 0,
  retailCV: 0,
  retailQV: 0,
  retailSCV: 0,
  retailSQV: 0,
  retailFSB: 0,
  retailOnly: false,
  gatewayMethodIds: [],
  primaryServiceId: '',
  status: 'active',
  imageUrl: null,
  requireAgreement: false,
  requireVerification: true,
  enrollerCashback: 0,
  customerCashback: 0,
  isCashbackActive: false,
};

const CreateEditPlan: React.FC<{
  match: match;
  history: History;
  location: Location;
  staticContext: any;
}> = (props) => {
  const classes = useStandardFormStyles();
  const planForEditing = useSelector(selectPlanForEditing);
  const services: IOption[] = useSelector(selectServicesOptions);
  const gateways: IOption[] = useSelector(selectPaymentMethodOptions);
  const purchaseRules: INormalizedPurchaseRuleSet[] | [] = useSelector(selectPurchaseRules);
  const [splitOption, setSplitOption] = useState<boolean>(!!planForEditing?.formData?.splitSKU);
  const isEdit = !!planForEditing?.formData;

  const initialPrimaryService = planForEditing?.formData?.primaryServiceId
    ? services.filter((item) => item.value === planForEditing?.formData?.primaryServiceId)
    : [];

  const [primaryServices, setServices] = useState<IOption[]>(initialPrimaryService);
  const productId = getSearchParam('productId');

  const dispatch = useDispatch();

  const fk: FormikProps<ICreatePlan> = useFormik({
    initialValues: isEdit
      ? {
          ...getValuesFromEditedPlan(planForEditing?.formData as ICreatePlan),
          isCashbackActive:
            planForEditing?.formData &&
            (planForEditing?.formData.enrollerCashback > 0 ||
              planForEditing?.formData?.customerCashback > 0),
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const { isRecurring, hasRetail } = values;

        const data = {
          ...normalizePlanData<ICreatePlan>(values, isRecurring, hasRetail),
          intervalUnit: initialValues.intervalUnit,
          regionalDatasets: values.regionalDatasets.length
            ? (values.regionalDatasets as IDataSet[]).map((item) =>
                normalizePlanData<IDataSet>(item, isRecurring, hasRetail)
              )
            : [],
          id: planForEditing?.id || void 0,
        };

        if (isEdit) {
          await editProductPlan(data as any, productId as string);
        } else {
          await createProductPlan(data, productId as string);
        }

        dispatch(push(pageLinks.createEditProduct + `?productId=${productId}`));
        setSuccessNotification();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleCashbackToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      fk.setFieldValue('enrollerCashback', 0);
      fk.setFieldValue('customerCashback', 0);
    }
    fk.handleChange(e);
  };

  useEffect(() => {
    updateServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fk.values.serviceIDs]);

  useEffect(
    () => () => {
      dispatch(setPurchaseRules([]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateServices = async () => {
    try {
      const response: AxiosResponse<IServiceResponse> = await getAvailableServices();
      const serviceIds = fk.values.serviceIDs.map((id) => +id);
      // @ts-ignore
      const serviceOptions: IOption[] = response.data.data
        .map((s) => {
          if (serviceIds?.includes(s.id)) {
            return {
              value: s.id,
              label: `${s.name} (exigoID: ${s.exigoId})`,
            };
          } else {
            return null;
          }
        })
        .filter((s) => s);

      setServices(serviceOptions);
    } catch (e) {
      console.warn('ERROR SERVICES', e);
    }
  };

  const createEditText = isEdit ? 'Edit' : 'Create';

  const createInputField = createInput<ICreatePlan>(fk);

  const onPlanTypeToggle = (
    e: ChangeEvent<HTMLInputElement>,
    fieldName: 'isAddon' | 'comboData'
  ) => {
    const bollFlag = !!Number(e.target.value);
    if (!isEmpty(purchaseRules) && !bollFlag) {
      setNotification('warning', {
        message: `Plans can't contain purchase rules, please remove all the purchase rules first`,
      });
      return;
    }

    fk.setFieldValue(fieldName, bollFlag);
    if (bollFlag && fieldName === 'isAddon') {
      fk.setFieldValue('name', 'Addons');
    }
  };

  return (
    <Grid item xs={12}>
      <BackButton
        name={'Back'}
        link={pageLinks.createEditProduct + `?productId=${productId}`}
        margin={'0 0 10px 0'}
      />
      <Paper className={classes.paper}>
        <Title>{createEditText} Plan </Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Type</FormLabel>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="isAddon"
                    value={fk.values.isAddon ? 1 : 0}
                    onChange={(e) => onPlanTypeToggle(e, 'isAddon')}
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio color="primary" />}
                      label="Subscription Plan"
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio color="primary" />}
                      label="Add-on"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id={'fsbEnabled'}
                          checked={fk.values.fsbEnabled}
                          onChange={(e) => {
                            fk.handleChange(e);
                            fk.setFieldValue('FSB', 0);
                            fk.values.regionalDatasets.forEach((item, index) => {
                              fk.setFieldValue(`regionalDatasets.${index}.FSB`, 0);
                            });
                          }}
                          name="fsbEnabled"
                          color="primary"
                        />
                      }
                      label="FSB calculated from price"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id={'requireAgreement'}
                          checked={fk.values.requireAgreement}
                          onChange={(e) => {
                            fk.handleChange(e);
                            fk.setFieldValue('requireAgreement', e.target.checked);
                          }}
                          name="requireAgreement"
                          color="primary"
                        />
                      }
                      label="Require agreement"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id={'requireVerification'}
                          checked={fk.values.requireVerification}
                          onChange={(e) => {
                            fk.handleChange(e);
                            fk.setFieldValue('requireVerification', e.target.checked);
                          }}
                          name="requireVerification"
                          color="primary"
                        />
                      }
                      label="Require verification"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          id={'noExigo'}
                          checked={fk.values.noExigo}
                          onChange={(e) => {
                            fk.handleChange(e);
                          }}
                          name="noExigo"
                          color="primary"
                        />
                      }
                      label="Should exclude exigo flow"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {createInputField('name', 'Level*')}
              {createInputField('sku', 'SKU*')}
              {createInputField('discountExigoItem', 'Discount Exigo Item*')}
              {createInputField('additionalInfo', 'Average Saving')}
              {createInputField('description', 'Description')}
              {createInputField('fullDescription', 'Full Description')}
              {createInputField('itemDescription', 'Item Description')}
              {createInputField('rebillyPlanId', 'Rebilly Plan Id*')}
              <Grid item xs={12} md={6}>
                <CountrySelect
                  id={'countryCodes'}
                  value={fk.values.countryCodes}
                  onChange={fk.setFieldValue}
                  withInstances
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="currency"
                  label={'Currency'}
                  value={fk.values.currency}
                  onChange={(e) => fk.setFieldValue('currency', e.target.value)}
                  options={[
                    { value: 'USD', label: 'USD' },
                    { value: 'EUR', label: 'EUR' },
                  ]}
                />
              </Grid>

              {createInputField('setupPrice', 'Setup Price*', 6, 'number')}
              {createInputField('CV', 'CV', 6, 'number')}
              {createInputField('QV', 'QV', 6, 'number')}
              {createInputField('SCV', 'SCV', 6, 'number')}
              {createInputField('SQV', 'SQV', 6, 'number')}

              {!fk.values.fsbEnabled && createInputField('FSB', 'FSB', 6, 'number')}

              {createInputField('questDollarsReward', 'Quest Dollars Reward', 6, 'number')}
              {createInputField('priority', 'Plan Priority', 6, 'number')}

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="status"
                  label={'Status'}
                  value={fk.values.status}
                  onChange={(e): void => fk.setFieldValue('status', e.target.value)}
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Gateways</InputLabel>
                  <Select
                    id="serviceIDs"
                    label={'Gateways'}
                    variant="outlined"
                    multiple
                    value={fk.values.gatewayMethodIds}
                    onChange={(e): void => fk.setFieldValue('gatewayMethodIds', e.target.value)}
                    input={<Input />}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {gateways.map(({ value, label }) => (
                      <MenuItem className={classes.textWrap} key={label} value={value}>
                        <Checkbox checked={fk.values.gatewayMethodIds.includes(value)} />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Services</InputLabel>
                  <Select
                    id="serviceIDs"
                    label={'Services'}
                    variant="outlined"
                    multiple
                    value={fk.values.serviceIDs}
                    onChange={(e): void => fk.setFieldValue('serviceIDs', e.target.value)}
                    input={<Input />}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {services.map(({ value, label }) => (
                      <MenuItem className={classes.textWrap} key={label} value={value}>
                        <Checkbox checked={fk.values.serviceIDs.includes(value)} />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Primary Service</InputLabel>
                  <Select
                    id="serviceIDs"
                    label={'Services'}
                    variant="outlined"
                    value={fk.values.primaryServiceId}
                    onChange={(e): void => fk.setFieldValue('primaryServiceId', e.target.value)}
                    input={<Input />}
                    // @ts-ignore
                    renderValue={(selected) => selected || initialPrimaryService[0]?.label}
                    MenuProps={MenuProps}
                  >
                    {primaryServices.map(({ value, label }) => (
                      <MenuItem className={classes.textWrap} key={label} value={value}>
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {!primaryServices.length && <span>No available services </span>}
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Recurring/Retail/Split Settings </FormLabel>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          id="hasRetail"
                          name="hasRetail"
                          checked={fk.values.hasRetail}
                          onChange={fk.handleChange}
                        />
                      }
                      label="Enable Retail fields"
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          id="isRecurring"
                          name="isRecurring"
                          disabled={isEdit}
                          checked={fk.values.isRecurring}
                          onChange={fk.handleChange}
                        />
                      }
                      label="Enable Recurring fields"
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          id="retailOnly"
                          name="retailOnly"
                          checked={fk.values.retailOnly}
                          onChange={fk.handleChange}
                        />
                      }
                      label="Show this plan only for retail customers"
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          id="isSplit"
                          name="isSplit"
                          checked={splitOption}
                          onChange={(e) => {
                            const flag = e.target.checked;
                            setSplitOption(flag);
                            if (!flag) {
                              fk.setValues({
                                ...fk.values,
                                splitSKU: '',
                                splitBaseValue: 0,
                                splitValues: 0,
                                splitFSB: 0,
                              });
                            }
                          }}
                        />
                      }
                      label="Show Splitting section"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              {splitOption && (
                <>
                  <Grid item xs={12}>
                    <Typography color="primary">Splitting section</Typography>
                  </Grid>

                  {createInputField('splitSKU', 'Split SKU', 6, 'text', true)}
                  {createInputField('splitBaseValue', 'Split Base Value', 6, 'number')}
                  {createInputField('splitValues', 'splitValues', 6, 'number')}
                  {createInputField('splitFSB', 'splitFSB', 6, 'number')}
                </>
              )}

              {fk.values.isRecurring && (
                <>
                  <Grid item xs={12}>
                    <Typography color="primary">Recurring Info</Typography>
                  </Grid>
                  {createInputField('recurringSKU', 'Recurring SKU', 6, 'string')}
                  {createInputField('oneDayRecurringSKU', 'One Day Recurring SKU', 6, 'string')}
                  {createInputField('recurringPrice', 'Recurring Price', 6, 'number')}
                  {createInputField('recurringCV', 'Recurring CV', 6, 'number')}
                  {createInputField('recurringQV', 'Recurring QV', 6, 'number')}
                  {createInputField('recurringSCV', 'Recurring SCV', 6, 'number')}
                  {createInputField('recurringSQV', 'Recurring SQV', 6, 'number')}
                  {createInputField('recurringFSB', 'Recurring FSB', 6, 'number')}
                  {createInputField('intervalSize', 'Interval Size(Days)', 6, 'number')}

                  <Grid
                    item
                    xs={12}
                    md={6}
                    direction={'row'}
                    alignItems={'center'}
                    className={classes.checkbox}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          id={'autopayable'}
                          checked={fk.values.autopayable}
                          onChange={fk.handleChange}
                          name="autopayable"
                          color="primary"
                        />
                      }
                      label="Autopayable"
                    />
                  </Grid>
                </>
              )}

              {fk.values.hasRetail && (
                <>
                  <Grid item xs={12}>
                    <Typography color="primary">Retail Info</Typography>
                  </Grid>
                  {createInputField('retailExigoItem', 'Retail Exigo Item', 6, 'string')}
                  {createInputField('retailPrice', 'Retail Price', 6, 'number')}
                  {createInputField('retailCV', 'Retail CV', 6, 'number')}
                  {createInputField('retailQV', 'Retail QV', 6, 'number')}
                  {createInputField('retailSCV', 'Retail SCV', 6, 'number')}
                  {createInputField('retailSQV', 'Retail SQV', 6, 'number')}
                  {createInputField('retailFSB', 'Retail FSB', 6, 'number')}
                  {fk.values.isRecurring && (
                    //! если только обa включены и показывать вместе с полями ретейл
                    <>
                      <Grid item xs={12}>
                        <Typography color="primary">Retail Recurring Info</Typography>
                      </Grid>

                      {createInputField(
                        'recurringRetailPrice',
                        'Recurring Retail Price',
                        6,
                        'number'
                      )}
                      {createInputField('retailRecurringCV', 'Retail Recurring CV', 6, 'number')}
                      {createInputField('retailRecurringQV', 'Retail Recurring QV', 6, 'number')}
                      {createInputField('retailRecurringSCV', 'Retail Recurring SCV', 6, 'number')}
                      {createInputField('retailRecurringSQV', 'Retail Recurring SQV', 6, 'number')}
                      {createInputField('retailRecurringFSB', 'Retail Recurring FSB', 6, 'number')}
                    </>
                  )}
                </>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      id={'isCashbackActive'}
                      checked={fk.values.isCashbackActive}
                      onChange={handleCashbackToggle}
                      name="isCashbackActive"
                      color="primary"
                    />
                  }
                  label="E-wallet cashback"
                />
              </Grid>
              {createInputField(
                'enrollerCashback',
                'Enroller cashback percent',
                6,
                'number',
                true,
                undefined,
                !fk.values.isCashbackActive
              )}
              {createInputField(
                'customerCashback',
                'Customer cashback percent',
                6,
                'number',
                true,
                undefined,
                !fk.values.isCashbackActive
              )}

              <Grid item xs={12}>
                <Typography color="primary">Plan Style Settings</Typography>

                <FileInput
                  id={'imageUrl'}
                  label={'Plan Background'}
                  imageUrl={fk.values.imageUrl}
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.PLAN}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <IconMoonSelect id={'icon'} value={fk.values.icon} onChange={fk.setFieldValue} />
              </Grid>

              <Grid item xs={12} md={6}>
                <ColorPicker
                  name="color"
                  variant="outlined"
                  fullWidth
                  label={'Text Color'}
                  defaultValue="Color"
                  value={fk.values.color || '#000'}
                  onChange={(color) => color && fk.setFieldValue('color', color)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ColorPicker
                  name="bgColor"
                  variant="outlined"
                  fullWidth
                  label={'Background Color'}
                  defaultValue="Color"
                  value={fk.values.bgColor || '#000'}
                  onChange={(color) => color && fk.setFieldValue('bgColor', color)}
                />
              </Grid>

              <Grid item xs={12}>
                {planForEditing?.id && planForEditing?.formData?.isAddon && fk.values.isAddon && (
                  <Can perform={Permissions.readPurchaseRules}>
                    <Typography color="primary">Purchase Rules</Typography>
                    <InputLabel className={classes.rulesDescription}>
                      In order to buy {planForEditing.formData.name} plan customer should have{' '}
                      <strong>every</strong> subscription of <strong>some</strong> purchase rule
                    </InputLabel>
                    <PlanPurchaseRules planId={planForEditing.id} />
                  </Can>
                )}
              </Grid>

              {fk.values.countryCodes.length > 1 && (
                <Grid item xs={12}>
                  <Typography color="primary"> Difference by Instance/Country</Typography>
                  <FormikProvider value={fk}>
                    <FieldArray name="regionalDatasets">
                      {({ remove, push }) => (
                        <>
                          {fk.values?.regionalDatasets?.length > 0 &&
                            (fk.values.regionalDatasets as IDataSet[]).map(
                              (item: IDataSet, index: number) => (
                                <Grid container spacing={2} className={classes.bordered}>
                                  <Grid item xs={12}>
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      justifyContent={'space-between'}
                                    >
                                      <Typography>Regional Dataset Item</Typography>
                                      <IconButton
                                        aria-label="delete"
                                        title={'Delete'}
                                        onClick={() => remove(index)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={12} md={6}>
                                    <CountrySelect
                                      id={`regionalDatasets.${index}.countryCodes`}
                                      availableOptions={fk.values.countryCodes}
                                      value={
                                        (fk.values.regionalDatasets[index]
                                          .countryCodes as string[]) || []
                                      }
                                      onChange={fk.setFieldValue}
                                      required={
                                        !(
                                          (fk.values?.regionalDatasets[index]
                                            ?.countryCodes as string[]) || []
                                        ).length
                                      }
                                    />
                                  </Grid>
                                  {createDataSetFields({
                                    isRecurring: fk.values.isRecurring,
                                    hasRetail: fk.values.hasRetail,
                                    fsbEnabled: fk.values.fsbEnabled,
                                  }).map((item) => (
                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      direction={'row'}
                                      alignItems={'center'}
                                    >
                                      <TextField
                                        key={item}
                                        variant="outlined"
                                        type={'number'}
                                        fullWidth
                                        id={`regionalDatasets.${index}.${item}`}
                                        label={item}
                                        required
                                        name={`regionalDatasets.${index}.${item}`}
                                        autoComplete="new-password"
                                        onBlur={fk.handleBlur}
                                        value={
                                          fk.values.regionalDatasets[index][item as keyof IDataSet]
                                        }
                                        onChange={fk.handleChange}
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              )
                            )}

                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() =>
                              push({
                                countryCodes: [],
                                setupPrice: 0,
                                retailPrice: 0,
                                recurringPrice: 0,
                                recurringRetailPrice: 0,
                                CV: 0,
                                QV: 0,
                                SCV: 0,
                                SQV: 0,
                                FSB: 0,
                                recurringCV: 0,
                                recurringQV: 0,
                                recurringSCV: 0,
                                recurringSQV: 0,
                                recurringFSB: 0,
                                retailCV: 0,
                                retailQV: 0,
                                retailSCV: 0,
                                retailSQV: 0,
                                retailFSB: 0,
                                retailRecurringCV: 0,
                                retailRecurringQV: 0,
                                retailRecurringSCV: 0,
                                retailRecurringSQV: 0,
                                retailRecurringFSB: 0,
                              })
                            }
                          >
                            Create
                          </Button>
                        </>
                      )}
                    </FieldArray>
                  </FormikProvider>
                </Grid>
              )}
              <Grid item xs={12} direction={'row'} alignItems={'center'}>
                <BulletDescriptions
                  onChange={(list: string[]) => fk.setFieldValue('bullets', list)}
                  bullets={fk.values.bullets || []}
                />
              </Grid>
            </Grid>
            {planForEditing?.id && <UpsellingRulesList planId={planForEditing?.id} />}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditPlan;
