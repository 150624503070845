import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IRoleRequest {
  roleId: number;
}

const request = (data: IRoleRequest) =>
  apiClient
    .delete<any>(ApiUrls.adminRoleDelete + data.roleId, { data })
    .then((response) => response);

export default request;
