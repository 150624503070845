import { toast } from 'react-toastify';

interface INotifications {
  message: string | null;
}

enum DEFAULT_MESSAGES {
  success = 'Success',
  error = 'Something went wrong',
  info = '',
  warning = '',
  dark = '',
}

export const setSuccessNotification = (message = 'Success'): void => notify('success', { message });

const notify = (
  type: 'success' | 'info' | 'error' | 'warning' | 'dark',
  params?: INotifications
): void => {
  toast.clearWaitingQueue();

  toast[type](`${params?.message || DEFAULT_MESSAGES[type]}`, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default notify;
