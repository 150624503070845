import { AxiosResponse } from 'axios';
import ApiUrls from '../apiUrls';
import apiClient from '../client';

interface IRegion {
  id: number;
  code: string;
  name: string;
  priority: number;
  defaultBaseUrl: string;
  companyName: string;
  createdAt: Date;
  updatedAt: Date;
}

interface IRole {
  id: number;
  name: string;
}

interface IBillingAddress {
  id: number;
  country: string;
  state: string;
  city: string;
  address: string;
  address2: string;
  phone: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  primary: boolean;
  exigo: boolean;
}

export interface UserDetails {
  isRetailCustomer: false;
  shouldAddPaymentMethod: false;
  region: IRegion;
  roles: IRole[];
  customerId: number;
  rankId: number;
  unverifiedProducts: {
    has: boolean;
    list: any[];
  };
  enroller: {
    customerId: number;
    fullName: string;
    email: string;
  };
  languageId: string;
  fullName: string;
  firstName: string;
  lastName: string;
  username: string;
  dob: Date;
  dateOfIncorporation: string;
  taxFilingNumber: string;
  companyRegistrationNumber: string;
  coapplicantFirstName: string;
  coapplicantLastName: string;
  companyName: string;
  email: string;
  emailVerified: boolean;
  phoneVerified: boolean;
  phone: string;
  country: string;
  city: string;
  address: string;
  zipCode: string;
  billingAddresses: IBillingAddress[];
  createdAt: Date;
}

const request = (id: number): Promise<AxiosResponse> => {
  return apiClient
    .get<AxiosResponse<UserDetails>>(`${ApiUrls.userDetails}${id}`)
    .then((response) => response);
};
export default request;
