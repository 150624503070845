import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams, IResponseAxios } from '../types';

interface IFile {
  id: number;
  name: string;
  bucket: string;
}

export interface IPayoutItem {
  admin: { id: number; firstName: string; lastName: string; email: string };
  createdAt: null | Date;
  createdFrom: null | Date;
  createdTo: null | Date;
  editedFrom: null | Date;
  editedTo: null | Date;
  files: IFile[];
  id: string;
  method: string;
}

const request = (params: IPaginationParams) => {
  const query = createQueryParams<IPaginationParams>(params);
  return apiClient
    .get<IResponseAxios<{ items: IPayoutItem[]; meta: IMeta }>>(ApiUrls.payoutBase + `?${query}`)
    .then((response) => response);
};

export default request;
