import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    border: '2px solid #673ab7',
    borderRadius: 5,
    width: '400px',
    padding: '10px',
    marginLeft: '20px',
    height: '60px',
  },
  selectWrapper: {
    width: '30%',
  },
  inputWrapper: {
    width: '50%',
    margin: '0 10px 0 10px',
  },
}));
export default useStyles;
