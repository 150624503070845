import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IUpgradeRule {
  fromId: string;
  toId: string;
  upgradeSku: string;
  deductUpgradeSku: string;
  CV: number;
  QV: number;
  SCV: number;
  SQV: number;
  FSB: number;
}

const request = (data: IUpgradeRule) =>
  apiClient.post<IResponseAxios>(ApiUrls.createUpgradeRule, data).then((response) => response);

export default request;
