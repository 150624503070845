import { SUBSCRIPTION_STATUS } from '../../../utils/commonTypes';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

const request = (id: number, shouldHaveStatus: SUBSCRIPTION_STATUS | null) => {
  const url = ApiUrls.updateRequiredPlan.replace('{id}', `${id}`);
  return apiClient.put<IResponseAxios>(url, { shouldHaveStatus }).then((response) => response);
};

export default request;
