import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';
import { ICountry } from './getInstanceList';

export interface IRegionResponse {
  data: {
    items: ICountry[];
    meta: IMeta;
  };
}

interface IParams extends IPaginationParams {
  searchTerm: string;
  sortDirection: 'asc' | 'desc';
}

const request = (params: IParams) => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get<IRegionResponse>(ApiUrls.countryList + `?${query}`)
    .then((response) => response);
};

export default request;
