import DateFnsUtils from '@date-io/date-fns';
import { CircularProgress, Portal } from '@material-ui/core';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICountry } from '../../services/api/requests/getCountries';
import { ILog } from '../../services/api/requests/getCustomersLogs';
import { fetchUserDetails } from '../../store/entities/actions';
import { selectCountries, selectUserDetails } from '../../store/entities/selectors';
import { selectLoading } from '../../store/system/selectors';
import { useStandardTableStyles } from '../DefaultTable';

interface IProps {
  logsList: ILog[];
  activeLogId: number;
}

const agreementListVal = {
  termsOfService: 'Terms of services agreements',
  privacy: 'Private policy',
  procedures: 'Policies and Procedures',
  gdpr: 'GDPR policy',
  termsOfUse: 'Terms of Use',
  ibiAgreement: 'IBI Agreement',
  compensationPlan: 'Compensation plan',
};

const agreementList = [
  agreementListVal.termsOfService,
  agreementListVal.privacy,
  agreementListVal.procedures,
  agreementListVal.gdpr,
  agreementListVal.termsOfUse,
  agreementListVal.ibiAgreement,
  agreementListVal.compensationPlan,
];

const filterList = (isIbiUser: boolean) => {
  if (!isIbiUser) {
    return agreementList.filter(
      (item) => item !== agreementListVal.ibiAgreement && item !== agreementListVal.compensationPlan
    );
  }

  return agreementList;
};

const initialValues = {
  date: '',
  customerType: '',
  customerId: '',
  name: '',
  email: '',
  country: '',
  ipAddress: '',
  site: '',
};

const CustomersLogsPortal = ({ logsList, activeLogId }: IProps) => {
  const [height, setHeight] = useState(0);
  const [userData, setUserData] = useState({ ...initialValues });
  const log = logsList.find((v) => v.id === activeLogId);
  const dispatch = useDispatch();
  const user = useSelector(selectUserDetails);
  const isLoading = useSelector(selectLoading);
  const isIbiUser =
    user?.roles?.some((role) => role?.name && ['IBP', 'IBI'].includes(role.name)) || false;
  const isUserAsString = Boolean(user)?.toString();
  const countries = useSelector(selectCountries);
  const classes = useStandardTableStyles();
  const portalAgreementList = filterList(isIbiUser);
  useEffect(() => {
    setUserData({ ...initialValues });
    if (log?.data.customerId) {
      dispatch(fetchUserDetails(log.data.customerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLogId]);

  useEffect(() => {
    if (user?.customerId && log) {
      const { customerId, fullName, email, country: userCountry } = user;
      const date = dateFns.format(new Date(log?.createdAt || ''), 'dd/MM/yyyy hh:mm');
      const customerType = user?.roles?.map((role) => role?.name).join(' | ') || '';
      const country =
        (userCountry &&
          countries &&
          countries.find((c: ICountry) => c.code === userCountry)?.name) ||
        '';
      const ipAddress = log?.data?.ip || '';
      const site = log?.data.instance || '';

      setUserData({
        date,
        customerType,
        customerId: `${customerId}`,
        name: fullName,
        email,
        country,
        ipAddress,
        site,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.customerId, log?.data.customerId]);

  useEffect(() => {
    if (isLoading && log?.data.customerId !== user?.customerId) {
      setUserData({ ...initialValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const dateFns = new DateFnsUtils();

  useEffect(() => {
    let top = window.innerHeight / 2 + window.pageYOffset;
    setHeight(top);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.pageYOffset]);

  return (
    <Portal>
      <ul id="portal" className={classes.dropdown} style={{ top: height }}>
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <li>
              <b>Date</b> : <span>{userData.date}</span>
            </li>
            <li>
              <b>Customer Type</b> : <span>{userData.customerType}</span>
            </li>
            <li>
              <b>Customer ID</b> : <span>{userData.customerId}</span>
            </li>
            <li>
              <b>Name</b> : <span>{userData.name}</span>
            </li>
            <li>
              <b>Email</b> : <span>{userData.email}</span>
            </li>
            <li>
              <b>Country Resident</b> : <span>{userData.country}</span>
            </li>
            <li>
              <b>IP Address</b> : <span>{userData.ipAddress}</span>
            </li>
            <li>
              <b>Site</b> : <span>{userData.site}</span>
            </li>
            <br />
            {userData?.customerId && user && (
              <>
                <li>
                  <b>Signed Agreements</b>
                </li>
                {portalAgreementList.map(
                  (title: string): ReactNode => (
                    <li key={title}>
                      <b>{title}</b> :{' '}
                      <span className={classes.dropdownValue}>{isUserAsString} </span>
                    </li>
                  )
                )}
              </>
            )}
          </>
        )}
      </ul>
    </Portal>
  );
};

export default CustomersLogsPortal;
