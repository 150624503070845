import DateFnsUtils from '@date-io/date-fns';
import { Box, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../components/BackButton';
import { INormalizedPayout } from '../../services/api/requests/getCustomerPayoutList';
import { payoutDetails } from '../../store/entities/actions';
import { selectPayoutDetails } from '../../store/entities/selectors';
import pageLinks from '../../utils/pageLinks';

const labels: { [key: string]: string } = {
  method: 'Payment Method',
  //CASH
  city: 'City',
  address1: 'Address Line 1',
  address2: 'Address Line 2',
  mailingAddress1: 'Mailing Address Line 1',
  mailingAddress2: 'Mailing Address Line 2',
  mailingCity: 'Mailing City',
  //PayPal
  currency: 'Currency',
  paypalEmail: 'PayPal Email',
  //BankWire
  accountHolderName: 'Bank Account Name',
  dateOfBirth: 'Date of Birth',
  countryOfBirth: 'Country of Birth',
  swiftCode: 'SWIFT Code',
  bankAddress: 'Bank Address',
  //ACH
  accountType: 'Account Type',
  routingNumber: 'Routing Number',
  bankName: 'Bank Name',
  accountNumber: 'Account Number',
};
const dateFns = new DateFnsUtils();
const createValue = (key: keyof INormalizedPayout, data: INormalizedPayout) => {
  window.scrollTo(0, 0);
  switch (key) {
    case 'dateOfBirth':
      return dateFns.format(new Date(data.dateOfBirth as string), 'dd/MM/yyyy');
    default:
      return data[key];
  }
};

const PayoutDetails: FC<{}> = () => {
  const payout = useSelector(selectPayoutDetails) as INormalizedPayout;
  const dispatch = useDispatch();

  useEffect((): any => {
    window.scrollTo(0, 0);
    return () => {
      // TODO: scrollTo doesn't work
      window.scrollTo(0, 0);
      dispatch(payoutDetails(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = Object.keys(payout as INormalizedPayout).filter(
    (item) => !!payout[item as keyof INormalizedPayout] && !!labels[item as keyof INormalizedPayout]
  );
  return (
    <Grid container spacing={2} direction="column">
      <BackButton name="Back" link={pageLinks.customerPayouts} margin="0 0 10px 0" />
      <Grid item xs={12}>
        <Box display="flex" padding="0 20px" alignItems="center">
          <Grid item xs={8} sm={5} lg={3}>
            <Typography variant="h6" component="h6">
              Payout Details
            </Typography>
          </Grid>
        </Box>
        ​
        <List>
          {values.map((item) => {
            const value = createValue(item as keyof INormalizedPayout, payout as INormalizedPayout);
            return (
              <ListItem divider key={item}>
                <Grid item xs={6} sm={3}>
                  {labels[item]}
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ListItemText secondary={value} />
                </Grid>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

export default PayoutDetails;
