import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface CountriesGroupRequest {
  countryIds: number[];
  countryCodes: string[];
  name: string;
}

const request = (data: CountriesGroupRequest) => {
  return apiClient
    .post<CountriesGroupRequest>(ApiUrls.countriesGroupsCreate, data)
    .then((response) => response.data);
};

export default request;
