import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import TableSkeleton from '../../components/TableSkeleton';
import deleteGateway from '../../services/api/requests/deleteGateway';
import getGatewayList, { IGateway } from '../../services/api/requests/getGatewayList';
import { IMeta } from '../../services/api/types';
import { editGatewayData } from '../../store/entities/actions';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import DeleteModalButton from '../DeleteModalButton';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', disableSort: true },
  {
    id: 'rebillyId',
    numeric: false,
    disablePadding: false,
    label: 'Rebilly Id',
    disableSort: true,
  },
  {
    id: 'currencies',
    numeric: false,
    disablePadding: false,
    label: 'Currencies',
    disableSort: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    disableSort: true,
  },
  {
    id: 'processingFee',
    numeric: false,
    disablePadding: false,
    label: 'Processing Fee',
    disableSort: true,
  },
  {
    id: 'exigoTransactionField',
    numeric: false,
    disablePadding: false,
    label: 'Exigo Transaction Field',
    disableSort: true,
  },
  {
    id: 'invoiceAbbr',
    numeric: false,
    disablePadding: false,
    label: 'Invoice Abbr',
    disableSort: true,
  },
  {
    id: 'gatewayMethod',
    numeric: false,
    disablePadding: false,
    label: 'Method',
    disableSort: true,
  },
  {
    id: 'companyName',
    numeric: false,
    disablePadding: false,
    label: 'Company Descriptor',
    disableSort: true,
  },
  {
    id: 'invoiceAddress',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    disableSort: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    disableSort: true,
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Updated',
    disableSort: true,
  },
];

const GatewayList: FC<{
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setMeta: (meta: IMeta) => void;
  page: number;
  rowsPerPage: number;
}> = (props) => {
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();
  const dispatch = useDispatch();
  const [list, setList] = useState<IGateway[] | null>(null);

  const { loading, setLoading, setMeta, page, rowsPerPage } = props;

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchGatewayList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const fetchGatewayList = () => {
    getGatewayList({ page, limit: rowsPerPage }).then((response) => {
      if (response) {
        setLoading(false);
        setList(response.data.data.items);
        setMeta(response.data.data.meta);
      }
    });
  };

  const handleGatewayDelete = (id: number) => {
    deleteGateway(id).then((response) => {
      if (response) {
        setNotification('success', {
          message: 'Success',
        });
        fetchGatewayList();
      }
    });
  };

  const handleGatewayEdit = (gateway: IGateway) => {
    dispatch(editGatewayData(gateway));
    dispatch(push(pageLinks.createEditGateway));
  };

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <CustomTableHead headCells={headCells} classes={classes} />
        <TableBody>
          {loading && <TableSkeleton />}

          {!loading &&
            list?.map((gateway: IGateway) => {
              const {
                id,
                rebillyId,
                name,
                currencies,
                status,
                processingFee,
                exigoTransactionField,
                invoiceAbbr,
                gatewayMethod,
                createdAt,
                updatedAt,
                companyName,
                invoiceAddress,
              } = gateway;
              const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
              const update = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');

              return (
                <TableRow key={id}>
                  <TableCell align="left">{name}</TableCell>
                  <TableCell align="left">{rebillyId}</TableCell>
                  <TableCell align="left">
                    <Box display="flex" flexWrap="wrap">
                      {currencies.map((currency: string, idx: number, arr: any) => (
                        <Box m={0.5} display="flex" flexWrap="wrap" key={currency}>{`${currency}${
                          arr.length - 1 === idx ? '' : ', '
                        }`}</Box>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell align="left">{status}</TableCell>
                  <TableCell align="left">{processingFee}</TableCell>
                  <TableCell align="left">{exigoTransactionField}</TableCell>
                  <TableCell align="left">{invoiceAbbr}</TableCell>
                  <TableCell align="left">{gatewayMethod?.name}</TableCell>
                  <TableCell align="left">{companyName}</TableCell>
                  <TableCell align="left">{invoiceAddress}</TableCell>
                  <TableCell>{create}</TableCell>
                  <TableCell>{update}</TableCell>
                  <TableCell align="left">
                    <Can perform={Permissions.updatePaymentGateway}>
                      <IconButton
                        aria-label="edit"
                        title="Edit"
                        onClick={() => handleGatewayEdit(gateway)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Can>
                  </TableCell>
                  <TableCell align="left">
                    <Can perform={Permissions.deletePaymentGateway}>
                      <DeleteModalButton
                        name={name}
                        entity={'Gateway'}
                        onDelete={() => handleGatewayDelete(id)}
                      />
                    </Can>
                  </TableCell>
                </TableRow>
              );
            })}

          {!loading && !list?.length && (
            <TableRow>
              <TableCell>There are no gateways</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GatewayList;
