import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import * as React from 'react';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  disableSort?: boolean;
  component?: React.ReactNode;
}

interface IProps {
  headCells: HeadCell[];
  order?: 'asc' | 'desc';
  orderBy?: string;
  onRequestSort?(e: React.SyntheticEvent, p: string): void;
  classes: any;
}

export const CustomTableHead: React.FC<IProps> = (props: IProps) => {
  const { classes, order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (property: any) => (event: any) => {
    if (onRequestSort) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.component ? (
              headCell.component
            ) : headCell.disableSort ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const useStandardTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  hoveredRow: {
    transition: 'background 0.3s ease-out',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },

  wrongField: {
    border: '1px solid red',
    padding: 5,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  table: {
    minWidth: 750,
  },
  paperModal: {
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '80%',
    overflowY: 'scroll',
  },

  searchRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px 16px',
  },

  boxButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },

  paperHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '10px',
  },

  filterRow: {
    padding: '0 16px',
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  hidden: {
    position: 'relative',
    outline: 'none',
    width: 'auto',
    height: '30px',

    '&:before': {
      content: 'Select some files',
      display: 'flex',
      background: 'linear-gradient(top, #f9f9f9, #e3e3e3)',
      border: '1px solid #999',
      borderRadius: 3,
      padding: '5px 8px',
      outline: 'none',
      whiteSpace: 'nowrap',
      position: 'absolute',
      cursor: 'pointer',
    },
  },
  marginRight: {
    marginRight: 10,
  },
  marginBottom: {
    marginBottom: 20,
  },
  whiteListCell: {
    width: '40%',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  dropdown: {
    minHeight: 190,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    width: 300,
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    '& li': {
      listStyleType: 'none',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dropdownValue: {
    textTransform: 'capitalize',
  },
}));
