import { Button, CssBaseline, Grid, Paper, TextField } from '@material-ui/core';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import PermissionSelect from '../../components/PermissionSelect';
import Title from '../../components/Title';
import addAdminRole, { IAddRoleRequest } from '../../services/api/requests/addAdminRole';
import editAdminRole, { IEditRoleRequest } from '../../services/api/requests/editAdminRole';
import { selectAdminRoleById } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  roleName: Yup.string()
    .required()
    .matches(/^[a-zA-Z_]+$/, 'Only latin letters and underscore sign')
    .max(50, "Value can't be more than 50 characters long"),
  permissionIds: Yup.string().required(),
});

const initialValues: any = {
  roleName: '',
  permissionIds: [],
};

const CreatePermissionRole: React.FC<{ match: any }> = (props) => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const roleId = props.match.params.roleId;
  const selectedRole = useSelector(selectAdminRoleById(roleId as number));
  const isEditAction = roleId && selectedRole;
  const buttonText = isEditAction ? 'Edit' : 'Create';

  const fk: FormikProps<IAddRoleRequest> = useFormik({
    initialValues: selectedRole
      ? {
          roleName: selectedRole.name,
          permissionIds: selectedRole?.permissions?.map((permission) => [permission.id]),
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values: IAddRoleRequest) => {
      if (isEditAction && selectedRole) {
        const data: IEditRoleRequest = {
          roleId: selectedRole.id,
          roleName: values.roleName,
          permissionIds: values.permissionIds?.flat(),
        };

        editAdminRole(data).then((response) => {
          if (response) {
            setNotification('success', { message: 'Success' });
            dispatch(push(pageLinks.permissions));
          }
        });
        return;
      }

      addAdminRole(values).then((response) => {
        if (response) {
          setNotification('success', { message: 'Success' });
          dispatch(push(pageLinks.permissions));
        }
      });
    },
  });

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.permissions} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>Create Role</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  autoComplete={'roleName'}
                  name={'roleName'}
                  variant="outlined"
                  required
                  fullWidth
                  id={'roleName'}
                  type={'text'}
                  label={'Role'}
                  autoFocus
                  error={!!(fk.errors.roleName && fk.touched.roleName)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.roleName && fk.errors.roleName}
                  value={fk.values.roleName}
                  onChange={fk.handleChange}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <PermissionSelect value={fk.values.permissionIds} onChange={fk.setFieldValue} />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {buttonText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreatePermissionRole;
