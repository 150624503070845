import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IEditRoleRequest {
  roleId: number;
  roleName: any;
  permissionIds: number[];
}

export interface IEditRoleResponse {
  data: {
    accessToken: string;
    email: string;
    id: number;
    refreshToken: string;
    role: string;
  };
}

const request = (data: IEditRoleRequest) =>
  apiClient
    .put<IEditRoleResponse>(ApiUrls.adminRoleEdit + data.roleId, { ...data })
    .then((response) => response);

export default request;
