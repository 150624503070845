import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IPermission } from './permissions';

export interface ITokenRequest {
  email: string;
  password: string;
}

export interface ITokenData {
  accessToken: string;
  email: string;
  id: number;
  permissions: IPermission[];
  refreshToken: string;
  role: string;
}

export interface ITokenResponse {
  data: ITokenData;
}

const request = (data: ITokenRequest) =>
  apiClient.post<ITokenResponse>(ApiUrls.signIn, { ...data }).then((response) => response);
export default request;
