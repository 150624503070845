import { setSuccessNotification } from '../../../utils/notifications';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IProductRequestData } from './createProduct';
import { IProductListItem } from './getProductList';

const request = (data: IProductRequestData) => {
  return apiClient
    .put<IResponseAxios<IProductListItem>>(ApiUrls.editProduct + data.id, data)
    .then((response) => setSuccessNotification());
};

export default request;
