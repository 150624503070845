import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';

export interface IIp {
  id: number;
  ipAddress: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IWhiteListResponse {
  data: {
    items: IIp[];
    meta: IMeta;
  };
}
export interface IWhiteListAllResponse {
  data: IIp[];
}

const request = ({ page, limit }: IPaginationParams) => {
  const query = `?page=${page}&limit=${limit}`;
  return apiClient
    .get<IWhiteListResponse>(ApiUrls.readWhiteList + query)
    .then((response) => response.data);
};

export default request;
