const iconList = [
  'save',
  'card',
  'house',
  'mail-confirmed',
  'add-user',
  'arrow-right-long',
  'whatsapp',
  'twitter',
  'heart',
  'briefcase',
  'menu',
  'edit',
  'close',
  'flights',
  'hotel',
  'reserved',
  'vacation',
  'alltags',
  'help',
  'mail',
  'media',
  'compensation_equity',
  'health_wellness',
  'learning_growth',
  'vacation_leaves',
  'integrity',
  'unity',
  'edification',
  'loyalty',
  'honor',
  'bell',
  'beach',
  'broom',
  'colonel',
  'dish',
  'field',
  'schedule',
  'service',
  'speedy',
  'tie',
  'time-hands',
  'vacation-time',
  'weights',
  'yachting',
  'lock',
  'radio-button-unchecked',
  'radio-button-checked',
  'check-rounded',
  'minus-collapse',
  'trash-bin',
  'boat-silver',
  'plus',
  'compass-gold',
  'compass-silver',
  'plus-expand',
  'compass',
  'compass-bronze',
  'concierge',
  'boat',
  'book',
  'download',
  'link1',
  'check-small',
  'calendar',
  'boat-gold',
  'boat-bronze',
  'check-square',
  'book-bronze',
  'book-basic',
  'book-rookie',
  'book-gold',
  'book-silver',
  'book-pro',
  'check-round',
  'arrow-right',
  'users',
  'user-check',
  'file',
  'info',
  'arrow-left',
  'eye',
  'arrow-down',
  'user',
  'tiktok',
  'cart',
  'link',
  'facebook',
  'instagram',
  'youtube',
  'logo',
];

export default iconList;
