import { Button, CssBaseline, Grid, Paper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import {
  addPermissionGroup,
  editPermissionGroup,
  IAddPermissionGroupRequest,
  IEditPermissionGroupRequest,
} from '../../services/api/requests/permissions';
import { fetchPermissions } from '../../store/entities/actions';
import {
  selectPermissionGroupById,
  selectPermissionGroups,
  selectPermissions,
} from '../../store/entities/selectors';
import { getPermissionOptions } from '../../utils/helpers';
import { setSuccessNotification } from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

interface IOption {
  value: number;
  label: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().max(50, "Value can't be more than 50 characters long"),
  permissionIds: Yup.string().required(),
});

const initialValues: IAddPermissionGroupRequest = {
  name: '',
  permissionIds: [],
};

const CreatePermissionGroup: React.FC<{ match: any }> = (props) => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const groupId = props.match.params.groupId;
  const permissions = useSelector(selectPermissions);
  const permissionGroups = useSelector(selectPermissionGroups);
  const selectedGroup = useSelector(selectPermissionGroupById(groupId as number));
  const isEditAction = groupId && selectedGroup;
  const buttonText = isEditAction ? 'Edit' : 'Create';

  const options = getPermissionOptions(permissions, permissionGroups);

  const fk: FormikProps<IAddPermissionGroupRequest> = useFormik({
    initialValues: selectedGroup
      ? {
          name: selectedGroup.name,
          permissionIds: selectedGroup.permissions.map((permission) => permission.id),
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values: IAddPermissionGroupRequest) => {
      if (isEditAction && selectedGroup) {
        const data: IEditPermissionGroupRequest = {
          groupId: selectedGroup.id,
          name: values.name,
          permissionIds: values.permissionIds,
        };

        const response = await editPermissionGroup(data);
        if (response) {
          setSuccessNotification();
          dispatch(fetchPermissions());
          dispatch(push(pageLinks.permissions));
          return;
        }
      }

      const response = await addPermissionGroup(values);
      if (response) {
        setSuccessNotification();
        dispatch(fetchPermissions());
        dispatch(push(pageLinks.permissions));
      }
    },
  });

  const onSelectChange = (e: any, selectedOptions: IOption[]) => {
    fk.setFieldValue(
      'permissionIds',
      selectedOptions.map((i) => i.value)
    );
  };

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.permissions} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>Create Permission Group</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  autoComplete="name"
                  name="name"
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  type="text"
                  label="Group"
                  autoFocus
                  error={!!(fk.errors.name && fk.touched.name)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.name && fk.errors.name}
                  value={fk.values.name}
                  onChange={fk.handleChange}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="permissionIds"
                  options={options}
                  multiple
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a permission"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  onChange={onSelectChange}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {buttonText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreatePermissionGroup;
