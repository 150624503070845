import { CssBaseline, Grid, Paper } from '@material-ui/core';
import * as React from 'react';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import NestedRules from '../../components/NestedRules';
import Title from '../../components/Title';
import pageLinks from '../../utils/pageLinks';

const NestedRulesPage: React.FC = () => {
  const classes = useStandardFormStyles();

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.products} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>Nested Rules</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <NestedRules />
        </div>
      </Paper>
    </Grid>
  );
};

export default NestedRulesPage;
