import { Box, Button, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import createCouponUsageRule from '../../services/api/requests/createCouponUsageRule';
import deleteCouponUsageRule from '../../services/api/requests/deleteCouponUsageRule';
import deleteRequiredPlanOfCouponUsageRule from '../../services/api/requests/deleteRequiredPlanOfCouponUsageRule';
import getCouponUsageRules, {
  INormalizedCouponUsageRule,
  INormalizedCouponUsageRuleSet,
} from '../../services/api/requests/getCouponUsageRules';
import updateRequiredPlanOfCouponUsageRule from '../../services/api/requests/updateRequiredPlanOfCouponUsageRule';
import { setCouponUsageRules } from '../../store/entities/actions';
import { selectCouponUsageRules } from '../../store/entities/selectors';
import { SELECT_OPTION, SUBSCRIPTION_STATUS } from '../../utils/commonTypes';
import Can, { Permissions } from '../Can';
import CouponUsageRulesControl from '../CouponUsageRulesControl';
import DeleteModalButton from '../DeleteModalButton';
import EditStatusButton from '../EditStatusButton';
import { useStandardFormStyles } from '../FormBase';
import { shouldHaveStatusOptions } from '../PurchaseRules';

const CouponUsageRules: FC<{ couponId: number }> = ({ couponId }) => {
  const dispatch = useDispatch();
  const classes = useStandardFormStyles();
  const usageRules: INormalizedCouponUsageRuleSet[] | [] = useSelector(selectCouponUsageRules);

  useEffect(() => {
    fetchUsageRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsageRules = async () => {
    try {
      const response = await getCouponUsageRules(couponId);
      dispatch(setCouponUsageRules(response));
    } catch (e) {
      console.warn('fetch coupon usage rules error', e);
    }
  };

  const handleCreateCouponUsageRule = async () => {
    try {
      await createCouponUsageRule(couponId);
      fetchUsageRules();
    } catch (e) {
      console.warn('create coupon usage rule error', e);
    }
  };

  const handleDeleteCouponUsageRule = async (id: number) => {
    try {
      await deleteCouponUsageRule(id);
      fetchUsageRules();
    } catch (e) {
      console.warn('delete coupon usage rule error', e);
    }
  };

  const handleEditRequiredPlan = async (
    id: number,
    shouldHaveStatus: SUBSCRIPTION_STATUS | null
  ) => {
    try {
      await updateRequiredPlanOfCouponUsageRule(id, shouldHaveStatus);
      fetchUsageRules();
    } catch (e) {
      console.warn('create coupon usage rule error', e);
    }
  };

  const handleDeleteRequiredPlan = async (id: number) => {
    try {
      await deleteRequiredPlanOfCouponUsageRule(id);
      fetchUsageRules();
    } catch (e) {
      console.warn('delete required plan of coupon usage rule error', e);
    }
  };

  return (
    <Grid container xs={12}>
      <Box className={classes.purchaseRuleSetsContainer}>
        {usageRules &&
          !isEmpty(usageRules) &&
          (usageRules as Array<INormalizedCouponUsageRuleSet>).map(
            ({ id: ruleSetId, usageRules }) => {
              return (
                <Grid container spacing={2} className={classes.bordered}>
                  <Box className={classes.purchaseRuleContainer}>
                    <Typography>Usage Rule</Typography>
                    <Can perform={Permissions.deleteCouponUsageRules}>
                      <DeleteModalButton
                        name="Coupon Usage Rule Set"
                        title="Delete Coupon Usage Rule Set"
                        entity="Plan"
                        onDelete={() => handleDeleteCouponUsageRule(ruleSetId)}
                      />
                    </Can>
                  </Box>
                  <Grid item xs={12}>
                    <Box justifyContent={'space-between'}>
                      {usageRules &&
                        !isEmpty(usageRules) &&
                        (usageRules as Array<INormalizedCouponUsageRule>).map(
                          ({ id, requiredPlanName, shouldHaveStatus }) => {
                            return (
                              <Button
                                className={classes.purchaseRulePlanContainer}
                                fullWidth
                                color="primary"
                                variant={'outlined'}
                              >
                                <Box
                                  className={`${classes.buttonContainer} ${classes.spaceBetween}`}
                                >
                                  <Grid container item xs={12} spacing={3}>
                                    <div>{`${requiredPlanName}`}</div>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    spacing={3}
                                    className={`${classes.buttonContainer} ${classes.flexEnd}`}
                                  >
                                    <div>
                                      <EditStatusButton
                                        status={shouldHaveStatus || SELECT_OPTION.ANY}
                                        options={
                                          shouldHaveStatusOptions as SUBSCRIPTION_STATUS &
                                            SELECT_OPTION
                                        }
                                        onChange={(status: SUBSCRIPTION_STATUS & SELECT_OPTION) => {
                                          handleEditRequiredPlan(
                                            id,
                                            status === SELECT_OPTION.ANY ? null : status
                                          );
                                        }}
                                      />
                                      <Can
                                        perform={Permissions.deleteRequiredPlanOfCouponUsageRules}
                                      >
                                        <DeleteModalButton
                                          name={requiredPlanName}
                                          entity={'Plan'}
                                          onDelete={() => handleDeleteRequiredPlan(id)}
                                        />
                                      </Can>
                                    </div>
                                  </Grid>
                                </Box>
                              </Button>
                            );
                          }
                        )}
                    </Box>
                  </Grid>

                  <Can perform={Permissions.createRequiredPlanOfCouponUsageRules}>
                    <CouponUsageRulesControl
                      usageRuleId={ruleSetId}
                      usageRules={usageRules}
                      fetchUsageRules={fetchUsageRules}
                    />
                  </Can>
                </Grid>
              );
            }
          )}
        <Can perform={Permissions.createCouponUsageRules}>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateCouponUsageRule}
          >
            create coupon usage rule
          </Button>
        </Can>
      </Box>
    </Grid>
  );
};

export default CouponUsageRules;
