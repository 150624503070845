import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IBody {
  email: string;
  url: string;
}

const request = (data: IBody) => {
  return apiClient.post<IResponseAxios>(ApiUrls.forgotPassword, data).then((response) => response);
};

export default request;
