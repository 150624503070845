import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import Modal from '../Modal';

interface IProps {
  status: string;
  options?: string[];
  onChange(st: string): void;
}

const useStyles = makeStyles(() => ({
  boxButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },

  statusButton: {
    borderColor: (props: IProps) => (props.status === 'inactive' ? '#b82525' : '#4ae91e'),
  },
}));

const EditStatusButton: React.FC<IProps> = ({
  status,
  onChange,
  options = ['active', 'inactive'],
}) => {
  const classes = useStyles({ status, onChange });

  const [value, setValue] = React.useState<string>(status);

  const handleClick = () => {
    if (value !== status) {
      onChange(value);
    }
  };

  return (
    <Modal
      button={
        <Button className={classes.statusButton} variant="outlined" endIcon={<EditIcon />}>
          {status}
        </Button>
      }
      renderContent={(onClose: any) => (
        <div>
          <h2>Please select a status</h2>

          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup row value={value} onChange={(e) => setValue(e.target.value)}>
                {options.map((it) => (
                  <FormControlLabel value={it} control={<Radio color="primary" />} label={it} />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Box className={classes.boxButton}>
            <Button
              variant="contained"
              color={'primary'}
              onClick={() => {
                handleClick();
                onClose();
              }}
            >
              Save
            </Button>

            <Button variant="contained" color="default" onClick={() => onClose()}>
              Cancel
            </Button>
          </Box>
        </div>
      )}
    />
  );
};

export default EditStatusButton;
