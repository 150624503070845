import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

export const selectRouter = (state: RootState) => state.router;

const selectSystem = (state: RootState) => {
  return state && state.system;
};

const selectUserData = createSelector(selectSystem, (system) => system.userData);

export const selectUserPermissions = createSelector(selectUserData, (data) => {
  return data?.permissions?.map((item) => item.name);
});

export const selectLoading = createSelector(selectSystem, (data) => {
  return data.loading;
});
