import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  smallCell: {
    width: '10%',
  },
  cell: {
    width: '22%',
  },
}));

export default useStyles;
