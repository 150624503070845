import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IDataSet } from './createProductPlan';
import { IProduct } from './getProducts';

export interface IPlanResponse {
  CV: number;
  FSB: number;
  QV: number;
  SCV: number;
  SQV: number;
  additionalInfo: null | string;
  adjustPrice: number;
  adjustRecurringPrice: number;
  autopayable: boolean;
  bgColor: null | string;
  bullets: null | Array<string>;
  color: null | string;
  countries: string[];
  currency: 'USD' | 'EURO';
  discountExigoItem: null | string;
  fsbEnabled: boolean;
  fullDescription: null | string;
  intervalSize: null | number;
  intervalUnit: 'day';
  isAddon: boolean;
  itemDescription: null | string;
  priority: number;
  product?: IProduct;
  createdAt?: Date;
  description: null | string;
  id: number;
  imageUrl: null | string;
  name: string;
  questDollarsReward: number;
  rebillyPlanId: string;
  recurringCV: number;
  recurringFSB: number;
  recurringPrice: number;
  recurringQV: number;
  recurringRetailPrice: number;
  recurringSCV: number;
  recurringSKU: null | string;
  recurringSQV: number;
  regionalDatasets: IDataSet[];
  retailExigoItem: null | string;
  retailPrice: number;
  setupPrice: number;
  sku: string;
  updatedAt?: Date;
  isRecurring: boolean;
  hasRetail: boolean;
  noExigo: boolean;
  requireAgreement: boolean;
  enrollerCashback: number;
  customerCashback: number;
}

const request = (data: any, productId: string) => {
  return apiClient
    .put<IResponseAxios<IPlanResponse[]>>(
      ApiUrls.baseProduct + +productId + '/plan/' + data.id,
      data
    )
    .then((response) => response);
};

export default request;
