import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import editCountry, { IEditCountry } from '../../services/api/requests/editCountry';
import setNotification from '../../utils/notifications';

const urlValidationSchema = Yup.object().shape({
  value: Yup.string()
    .trim()
    .optional()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url'
    ),
});

const companyValidationSchema = Yup.object().shape({
  value: Yup.string()
    .trim()
    .optional()
    .matches(/^[a-zA-Z\s]+$/, 'Only latin letters and spaces')
    .max(40, "Value can't be more than 40 characters long"),
});

export enum COUNTRY_EDIT_FIELD {
  URL = 'customBaseUrl',
  COMPANY_NAME = 'customCompanyName',
}

const validationSchema = {
  [COUNTRY_EDIT_FIELD.COMPANY_NAME]: companyValidationSchema,
  [COUNTRY_EDIT_FIELD.URL]: urlValidationSchema,
};

const uiFieldName = {
  [COUNTRY_EDIT_FIELD.COMPANY_NAME]: 'Company name',
  [COUNTRY_EDIT_FIELD.URL]: 'Custom URL',
};

interface IProps {
  fetchCountryList: () => void;
  field: COUNTRY_EDIT_FIELD;
  value: string;
  id: number;
}

interface IFormValues {
  value: string;
}

const EditCountry: React.FC<IProps> = (props) => {
  const { fetchCountryList, field, value = '', id } = props;
  const [showEditInput, toggleInput] = useState<boolean>(false);

  const fk: FormikProps<IFormValues> = useFormik({
    initialValues: { value },
    validationSchema: validationSchema[field],
    validateOnChange: true,
    onSubmit: ({ value }) => {
      const updateData: IEditCountry = {};

      if (field === COUNTRY_EDIT_FIELD.URL) {
        updateData.customBaseUrl = value;
      }

      if (field === COUNTRY_EDIT_FIELD.COMPANY_NAME) {
        updateData.customCompanyName = value;
      }

      editCountry(updateData, id).then((res) => {
        if (res) {
          setNotification('success', {
            message: 'Success',
          });
          fetchCountryList();
          toggleInput(false);
        }
      });
    },
  });

  if (!showEditInput) {
    return (
      <Box display={'flex'} alignItems="center">
        {value || '---'}
        <IconButton aria-label="edit" title={'Edit'} onClick={() => toggleInput(true)}>
          <EditIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <form onSubmit={fk.handleSubmit}>
      <TextField
        autoComplete="fname"
        name="value"
        variant="outlined"
        id="value"
        label={uiFieldName[field]}
        autoFocus
        error={!!(fk.errors.value && fk.touched.value)}
        onBlur={fk.handleBlur}
        helperText={fk.touched.value && fk.errors.value}
        value={fk.values.value}
        onChange={fk.handleChange}
      />

      <IconButton
        onClick={() => {
          fk.setFieldValue('value', '');
          toggleInput(false);
        }}
        edge="end"
      >
        <CloseIcon />
      </IconButton>

      {fk.isValid && (
        <IconButton type="submit" color={'primary'} edge="end">
          <CheckIcon />
        </IconButton>
      )}
    </form>
  );
};

export default EditCountry;
