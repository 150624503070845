import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CachedIcon from '@material-ui/icons/Cached';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Can, { Permissions } from '../../components/Can';
import Copyright from '../../components/Copyright';
import MenuCmp from '../../components/Menu';
import { syncServices } from '../../store/entities/actions';
import { logOutUser } from '../../store/system/actions';
import pageLinks from '../../utils/pageLinks';
import AdminPermission from '../AdminPermission';
import Admins from '../Admins';
import CountriesGroups from '../CountriesGroups';
import Coupons from '../Coupons';
import CreateCountriesGroups from '../CreateCountriesGroup';
import CreateCsvRequest from '../CreateCsvRequest';
import CreateAdmin from '../CreateEditAdmin';
import CreateEditCombo from '../CreateEditComboPlan';
import CreateEditComboProduct from '../CreateEditComboProduct';
import CreateEditConversionTemplates from '../CreateEditConversionTemplates';
import CreateCoupon from '../CreateEditCoupon';
import CreateEditGateway from '../CreateEditGateway';
import CreateEditInstance from '../CreateEditInstance';
import CreateEditLegalDocument from '../CreateEditLegalDocument';
import CreateEditPaymentMethod from '../CreateEditPaymentMethod';
import CreateEditPlan from '../CreateEditPlan';
import CreateEditProduct from '../CreateEditProduct';
import CreateEditSetting from '../CreateEditSetting';
import CreatePermissionGroup from '../CreatePermissionGroup';
import CreatePermissionRole from '../CreatePermissionRole';
import CreateWhiteLIstIp from '../CreateWhiteListIp';
import CustomerPayouts from '../CustomerPayouts';
import CustomersLogs from '../CustomersLogs';
import Instances from '../Instances';
import KYCList from '../KYCList';
import LegalDocuments from '../LegalDocuments';
import MassPayoutDetails from '../MassPayoutDetails';
import PayoutRequests from '../MassPayoutRequests';
import NestedRulesPage from '../NestedRulesPage';
import Orders from '../Orders';
import Payments from '../Payments';
import PayoutDetails from '../PayoutDetails';
import CSVs from '../PayoutExport';
import Products from '../Products';
import ProductsActivations from '../ProductsActivations';
import Settings from '../Settings';
import SignedDocuments from '../SignedDocuments';
import UserMigrationsReport from '../UserMigrationsReport';
import WhiteList from '../WhiteList';
import useStyles from './styles';

export default function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const openMenu = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleMenu = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const onSyncServiceClick = () => {
    dispatch(syncServices());
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleLogOut = () => {
    dispatch(logOutUser());
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {/*Dashboard*/}
          </Typography>
          <div>
            <Button onClick={onSyncServiceClick} color="inherit">
              <CachedIcon />
              <Typography component="h6">Sync Data</Typography>
            </Button>

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <MenuCmp open={open} setOpen={setOpen} />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Switch>
              <Route exact path={pageLinks.home} component={Products} />
              <Route exact path={pageLinks.products} component={Products} />
              <Route exact path={pageLinks.admins} component={Admins} />
              <Route exact path={pageLinks.orders} component={Orders} />
              <Route exact path={pageLinks.createEditAdmin} component={CreateAdmin} />
              <Route exact path={pageLinks.coupons} component={Coupons} />
              <Route exact path={pageLinks.createEditCoupon} component={CreateCoupon} />
              <Route exact path={pageLinks.customers} component={() => <div>Cust</div>} />
              <Route exact path={pageLinks.instances} component={Instances} />
              <Route exact path={pageLinks.payments} component={Payments} />
              <Route exact path={pageLinks.settings} component={Settings} />
              <Route exact path={pageLinks.addCsvReport} component={CreateCsvRequest} />
              <Route exact path={pageLinks.permissions} component={AdminPermission} />
              <Route exact path={pageLinks.createEditProduct} component={CreateEditProduct} />
              <Route exact path={pageLinks.createEditSetting} component={CreateEditSetting} />
              <Route exact path={pageLinks.createEditGateway} component={CreateEditGateway} />
              <Route exact path={pageLinks.nestedRules} component={NestedRulesPage} />
              <Route exact path={pageLinks.massPaymentRequests} component={PayoutRequests} />
              <Route exact path={pageLinks.legalDocuments} component={LegalDocuments} />
              <Route exact path={pageLinks.countriesGroups} component={CountriesGroups} />
              <Route exact path={pageLinks.customersLogs} component={CustomersLogs} />
              <Route
                exact
                path={pageLinks.createCountriesGroup}
                component={CreateCountriesGroups}
              />
              <Route
                exact
                path={pageLinks.editCountriesGroup + '/:groupId'}
                component={CreateCountriesGroups}
              />
              <Route exact path={pageLinks.productsActivations} component={ProductsActivations} />
              <Route
                exact
                path={pageLinks.createEditLegalDocument}
                component={CreateEditLegalDocument}
              />
              <Route
                exact
                path={pageLinks.createEditPaymentMethod}
                component={CreateEditPaymentMethod}
              />
              <Route exact path={pageLinks.createEditInstance} component={CreateEditInstance} />
              <Route exact path={pageLinks.createEditPlan} component={CreateEditPlan} />
              <Route exact path={pageLinks.createEditCombo} component={CreateEditCombo} />
              <Route
                exact
                path={pageLinks.createEditComboProduct}
                component={CreateEditComboProduct}
              />

              <Route exact path={pageLinks.csvReport} component={CSVs} />
              <Route exact path={pageLinks.customerPayouts} component={CustomerPayouts} />
              <Route exact path={pageLinks.userMigrationsReport} component={UserMigrationsReport} />
              <Route exact path={pageLinks.payoutDetails} component={PayoutDetails} />
              <Route exact path={pageLinks.whiteList} component={WhiteList} />
              <Route exact path={pageLinks.createWhiteListIp} component={CreateWhiteLIstIp} />
              <Route
                exact
                path={pageLinks.createEditConversionTemplate}
                component={CreateEditConversionTemplates}
              />
              <Route exact path={pageLinks.signedDocuments} component={SignedDocuments} />
              <Route exact path={pageLinks.kyc} component={KYCList} />

              <Route
                exact
                path={pageLinks.massPayoutRequestDetails}
                component={MassPayoutDetails}
              />

              <Route
                exact
                path={pageLinks.createEditPermissionRole + '/:roleId'}
                component={CreatePermissionRole}
              />

              <Route
                exact
                path={pageLinks.createEditPermissionRole}
                component={CreatePermissionRole}
              />

              <Route
                exact
                path={pageLinks.createEditPermissionGroup + '/:groupId'}
                component={CreatePermissionGroup}
              />

              <Route
                exact
                path={pageLinks.createEditPermissionGroup}
                component={CreatePermissionGroup}
              />

              <Can
                perform={Permissions.readUsersList}
                no={() => (
                  <Switch>
                    <Redirect to={pageLinks.home} />
                  </Switch>
                )}
              >
                <Route exact path={pageLinks.admins} component={Admins} />
              </Can>
            </Switch>
          </Grid>

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
