import { Button, CssBaseline, Grid, Paper } from '@material-ui/core';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import CountrySelect from '../../components/CountrySelect';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import createCountriesGroup from '../../services/api/requests/createCountriesGroup';
import editCountriesGroup from '../../services/api/requests/editCountriesGroup';
import { ICountry } from '../../services/api/requests/getInstances';
import { selectCountries, selectCountryGroups } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  name: Yup.string().required().trim().min(3, 'minimal length of string 3'),
});
const CreateCountriesGroup: FC<{ match: any }> = (props) => {
  const dispatch = useDispatch();
  const classes = useStandardFormStyles();
  const groupId = props.match.params.groupId;
  const countries: ICountry[] = useSelector(selectCountries);
  const groups = useSelector(selectCountryGroups);
  const group = groups?.find((i) => i.id === +groupId);
  const [countriesToSave, setCountriesToSave] = React.useState<string[]>([]);
  const fk: FormikProps<{ name: string }> = useFormik({
    initialValues: { name: group?.name || '' },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        countryCodes: countriesToSave,
        countryIds: countries
          .filter((value) => countriesToSave.find((v) => v === value.code))
          .map((i) => i.id),
      };
      try {
        if (group) {
          await editCountriesGroup(group.id, data);
        } else {
          await createCountriesGroup(data);
        }
        dispatch(push(pageLinks.countriesGroups));
        setNotification('success', { message: 'Success' });
      } catch (e) {
        console.warn(e.message);
      }
    },
  });
  useEffect(() => {
    setCountriesToSave(group?.countries.map((v) => v.code) as string[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createInputField = createInput<{ name: string }>(fk);
  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.countriesGroups} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`${group ? 'Edit' : 'Create'} countries group`}</Title>
        <CssBaseline />
        <Grid style={{ padding: '10px' }}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
              {createInputField('name', 'Name', 6)}
            </Grid>

            <Grid container spacing={2} style={{ padding: 10 }}>
              <CountrySelect
                id={'create-countries-select'}
                value={countriesToSave || []}
                availableOptions={countries.map((v) => v.code) || []}
                onChange={(id, value: string[]) => setCountriesToSave(value)}
                multiple
              />
            </Grid>
            <Button type="submit" variant="contained" color="primary" className={classes.submit}>
              {group ? 'Edit' : 'Add'}
            </Button>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default CreateCountriesGroup;
