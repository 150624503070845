import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IAddRoleRequest {
  roleName: string | number;
  permissionIds: number[];
}

//: TODO added interface roles
export interface IAddRoleResponse {
  data: {
    accessToken: string;
    email: string;
    id: number;
    refreshToken: string;
    role: string;
  };
}

const request = (data: IAddRoleRequest) =>
  apiClient.post<IAddRoleResponse>(ApiUrls.adminRole, data).then((response) => response);

export default request;
