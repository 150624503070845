import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface ICreatePaymentMethod {
  defaultProcessingFee: number;
  priority: number;
}

const request = (data: ICreatePaymentMethod) =>
  apiClient.post<IResponseAxios>(ApiUrls.createPaymentMethod, data).then((response) => response);

export default request;
