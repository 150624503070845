import { createSelector } from '@reduxjs/toolkit';
import { IPlanResponse } from '../../services/api/requests/editProductPlan';
import { ICountry } from '../../services/api/requests/getInstances';
import { IService } from '../../services/api/requests/getServices';
import { normalizePlan } from '../../utils/planHelper';
import { RootState } from '../index';

const selectEntities = (state: RootState) => state.entities;

const selectProducts = createSelector(selectEntities, (state) => state.products);

const selectPlans = createSelector(selectProducts, (products) => {
  return products
    .map((product) => ({
      ...product,
      plans: product.plans.map((plan) => ({ ...plan, productName: product.name })),
    }))
    .flatMap((product) => product.plans);
});

export const selectPermissions = createSelector(selectEntities, (state) => state.permissions);

export const selectPermissionGroups = createSelector(
  selectEntities,
  (state) => state.permissionGroups
);

export const selectAdminRoles = createSelector(selectEntities, (state) => state.roles);

export const selectInstances = createSelector(selectEntities, (state) => state.instances);

export const selectServices = createSelector(selectEntities, (state) => state.services);

export const selectCountries = createSelector(selectEntities, (state) => state.countries);

export const selectCountryGroups = createSelector(selectEntities, (state) => state.countryGroups);

export const selectCountryGroupOptions = createSelector(selectCountryGroups, (groups) => {
  if (groups) {
    return groups.map((group) => ({ name: group.name, code: group.name }));
  }
  return [];
});

export const selectCountryList = (withInstances: boolean, withGroups: boolean) =>
  createSelector(selectEntities, (state) => {
    if (state.instances.length) {
      const countries: any = {};
      countries.all = { name: 'All', code: 'all' };
      if (withInstances) {
        state.instances.forEach((instance) => {
          if (instance.countries.length && withInstances) {
            countries[instance.code] = { name: instance.name, code: instance.code };
          }
        });
      }
      if (withGroups && state.countryGroups) {
        state.countryGroups.forEach((gr) => {
          if (gr.countries.length) {
            countries[gr.id] = { name: gr.name, code: gr.id };
          }
        });
      }
      state.countries?.forEach((country: ICountry) => (countries[country.code] = country));
      return countries;
    }
    return {};
  });

export const selectAdminRoleById = (id: number) =>
  createSelector(selectAdminRoles, (roles) => {
    if (roles) {
      return roles.find((role) => `${role.id}` === `${id}`);
    }
    return roles;
  });

export const selectPermissionGroupById = (id: number) =>
  createSelector(selectPermissionGroups, (groups) => {
    if (groups) {
      return groups.find((group) => `${group.id}` === `${id}`);
    }
    return groups;
  });

export const selectAvailablePlansOptions = createSelector(selectPlans, (plans) => {
  const all = plans.map((plan) => ({
    value: plan.rebillyPlanIds[0],
    label: `${plan.productName} ${plan.name || ''}`,
  }));

  const anyOption = { value: 'all', label: 'Any' };

  return [anyOption, ...all];
});

export const selectPlansOptionsValueID = createSelector(selectPlans, (plans) => {
  return plans.map((plan) => ({
    value: plan.id,
    label: `${plan.productName} ${plan.name || ''}`,
  }));
});

export const selectAvailablePlansOptionsById = (id: string) =>
  createSelector(selectPlans, (plans) => {
    return plans.map((plan) => ({
      value: plan[id as keyof typeof plan],
      label: `${plan.productName} ${plan.name || ''}`,
    }));
  });

export const selectServicesOptions = createSelector(selectServices, (serv: IService[]) => {
  return serv.map((s) => ({
    value: s.id,
    label: `${s.name} (exigoId: ${s.exigoId})`,
  }));
});

export const selectUserForEditing = createSelector(
  selectEntities,
  (entities) => entities.userForEditing
);

export const selectProductForEditing = createSelector(
  selectEntities,
  (entities) => entities.productForEditing
);

export const selectComboForEditing = createSelector(
  selectEntities,
  (entities) => entities.comboForEditing
);

export const selectCouponForEditing = createSelector(
  selectEntities,
  (entities) => entities.couponForEditing
);

export const selectComboPlanForEditing = createSelector(
  selectEntities,
  (entities) => entities.comboPlanForEditing
);

export const selectPlanForEditing = createSelector(
  [selectEntities, selectPlans],
  (entities, plans) => {
    const plansData = entities.planForEditing;

    if (plansData && plansData.editPlanId) {
      const editablePlan = plansData?.plans?.find((plan) => plan.id === plansData?.editPlanId);
      const normalizedPlanData = normalizePlan(editablePlan as IPlanResponse);

      return {
        formData: normalizedPlanData,
        showCombo: editablePlan && !!editablePlan.comboName,
        id: editablePlan?.id,
      };
    }

    return null;
  }
);

export const selectInstanceForEditing = createSelector(
  selectEntities,
  (entities) => entities.instanceForEditing
);

export const selectPaymentMethodList = createSelector(
  selectEntities,
  (entities) => entities.paymentMethods
);

export const selectPaymentMethodOptions = createSelector(selectPaymentMethodList, (list) => {
  if (list) {
    return list.map((item) => ({ label: item.name, value: item.id }));
  }
  return [];
});

export const selectGatewayForEditing = createSelector(
  selectEntities,
  (entities) => entities.gatewayForEditing
);

export const selectSettingForEditing = createSelector(
  selectEntities,
  (entities) => entities.settingForEditing
);

export const selectPaymentMethodForEditing = createSelector(
  selectEntities,
  (entities) => entities.paymentMethodForEditing
);

export const selectPayoutRequestList = createSelector(
  selectEntities,
  (entities) => entities.payoutRequestDetails
);

export const selectPayoutDetails = createSelector(
  selectEntities,
  (entities) => entities.payoutDetails
);

export const selectLegalDocumentForEditing = createSelector(
  selectEntities,
  (entities) => entities.legalDocumentForEditing
);

export const selectPurchaseRules = createSelector(
  selectEntities,
  (entities) => entities.purchaseRules
);

export const selectCouponUsageRules = createSelector(
  selectEntities,
  (entities) => entities.couponUsageRules
);

export const selectUserDetails = createSelector(selectEntities, (entities) => entities.userDetails);
