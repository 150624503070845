import { setSuccessNotification } from '../../../utils/notifications';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IProductListItem } from './getProductList';

export interface IProductRequestData {
  name: string;
  rebillyProductId: string;
  description: string;
  color: string;
  bgColor: string;
  priority: number;
  file?: any;
  imageUrl?: string | null;
  id?: string | number;
}

const request = (data: IProductRequestData) => {
  return apiClient.post<IProductListItem>(ApiUrls.createProduct, data).then((response) => {
    setSuccessNotification();
    return response;
  });
};

export default request;
