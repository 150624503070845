import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateNewPassword from './pages/CreateNewPassword';
import ForgotPassword from './pages/ForgotPassword';
import ForgotPasswordSuccess from './pages/ForgotPasswordSuccess';
import HomePage from './pages/Home';
import Login from './pages/Login';
import { isUserLogged } from './utils/helpers';
import pageLinks from './utils/pageLinks';
import WithRoot from './WithRoot';

interface IAppProps {
  history: History;
}

const App = ({ history, ...rest }: IAppProps) => {
  return (
    <WithRoot>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path={pageLinks.login} component={Login} />
          <Route exact path={pageLinks.forgotPassword} component={ForgotPassword} />
          <Route exact path={pageLinks.createNewPassword} component={CreateNewPassword} />
          <Route exact path={pageLinks.forgotPasswordSuccess} component={ForgotPasswordSuccess} />
          <PrivateRoute path={pageLinks.home} component={HomePage} />
          <Route path={pageLinks.home} component={HomePage} />
          <Route path="/:page" component={HomePage} />
        </Switch>
      </ConnectedRouter>
      <ToastContainer />
    </WithRoot>
  );
};

const PrivateRoute = ({
  component: Component,
  path,
  ...rest
}: {
  component: React.FC<any>;
  path: string;
}) => {
  const pathname = window.location.pathname;

  function checkToken() {
    const isLogged = isUserLogged();
    if (!isLogged && pathname !== '/login') {
      localStorage.setItem('urlBeforeLogout', window.location.pathname + window.location.search);
    }
    return isLogged;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        checkToken() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
};

export default App;
