import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

const request = (email: string) => {
  return apiClient
    .get<IResponseAxios>(ApiUrls.emailVerify + `?email=${email}`)
    .then((response) => response);
};

export default request;
