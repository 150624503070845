import { Box, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import createRequiredPlan from '../../services/api/requests/createRequiredPlan';
import { INormalizedPurchaseRule } from '../../services/api/requests/getPurchaseRules';
import { selectPlansOptionsValueID } from '../../store/entities/selectors';
import { SELECT_OPTION, SUBSCRIPTION_STATUS } from '../../utils/commonTypes';
import { filterAlreadyAddedRequiredPlans } from '../../utils/helpers';
import EditStatusButton from '../EditStatusButton';
import { useStandardFormStyles } from '../FormBase';
import InputSelect from '../InputSelect';
import { shouldHaveStatusOptions } from '../PurchaseRules';

const PurchaseRulesControl: FC<{
  purchaseRuleId: number;
  purchaseRules: Array<INormalizedPurchaseRule>;
  fetchPurchaseRules: () => void;
}> = ({ purchaseRuleId, purchaseRules, fetchPurchaseRules }) => {
  const plans = useSelector(selectPlansOptionsValueID);
  const classes = useStandardFormStyles();

  const [showCreateRulePanel, togglePanel] = useState<boolean>(false);
  const [shouldHaveStatus, setShouldHaveStatus] = useState<SUBSCRIPTION_STATUS | null>(null);

  const fk: FormikProps<{ label: string; value: number }> = useFormik({
    initialValues: { label: '', value: 0 },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const data = { planId: values.value, shouldHaveStatus };
        await createRequiredPlan({ id: purchaseRuleId, data });
        fetchPurchaseRules();
        togglePanel(false);
      } catch (e) {
        console.warn('create required plan of purchase rules error', e);
      }
    },
  });

  return (
    <Box className={classes.purchaseRuleControlContainer}>
      {showCreateRulePanel && (
        <Box className={classes.purchaseRuleControlsWrapper}>
          <InputSelect
            id="requiredPlanId"
            label={'Required Plan'}
            value={fk.values.value}
            required
            onChange={(e) => fk.setFieldValue('value', e.target.value)}
            options={filterAlreadyAddedRequiredPlans(plans, purchaseRules)}
            onBlur={fk.handleBlur}
            style={{ height: '36px', marginRight: '10px' }}
          />
          <EditStatusButton
            status={shouldHaveStatus || SELECT_OPTION.ANY}
            options={shouldHaveStatusOptions as SUBSCRIPTION_STATUS & SELECT_OPTION}
            onChange={(status: SUBSCRIPTION_STATUS & SELECT_OPTION) => {
              setShouldHaveStatus(status === SELECT_OPTION.ANY ? null : status);
            }}
          />
        </Box>
      )}
      <Box className={classes.flexEnd}>
        <Button
          type="button"
          disabled={showCreateRulePanel && fk.values.value === 0}
          variant={showCreateRulePanel ? 'contained' : 'outlined'}
          color="primary"
          startIcon={showCreateRulePanel ? null : <AddCircleIcon />}
          onClick={(e) =>
            // @ts-ignore
            showCreateRulePanel ? fk.handleSubmit(fk.values) : togglePanel(!showCreateRulePanel)
          }
        >
          {showCreateRulePanel ? 'save' : 'add plan'}
        </Button>
      </Box>
    </Box>
  );
};

export default PurchaseRulesControl;
