import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IResponseAxios } from '../types';

export interface IProductListItem {
  createdAt: string | Date;
  description: string | null;
  id: string;
  imageUrl: string;
  fullImageUrl: string;
  file?: any;
  name: string;
  rebillyProductId: string;
  updatedAt: string | Date;
  priority: number;
  color: string;
  bgColor: string;
  urlParam: string;
  status: string;
}

export interface IParams {
  page: number;
  limit: number;
  sortDirection: 'ASC' | 'DESC';
  searchTerm: string;
  sortBy: string;
}

export interface IAdminProductResponse {
  data: {
    items: IProductListItem[];
    meta: IMeta;
  };
}

const request = (params: IParams) => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get<IResponseAxios<IAdminProductResponse>>(ApiUrls.productList + `?${query}`)
    .then((response) => response);
};

export default request;
