import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IRefreshResponse {
  accessToken: string;
  refreshToken: string;
}

const request = () =>
  apiClient.post<IRefreshResponse>(ApiUrls.refreshToken).then((response) => response);

export default request;
