import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IPermission } from './permissions';

export interface IAdminRoles {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
  permissions: IPermission[];
}

export interface IAdminRolesResponse {
  data: IAdminRoles[];
}

const request = () =>
  apiClient.get<IAdminRolesResponse>(ApiUrls.adminRoles).then((response) => response);

export default request;
