import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import InputSearch from '../../components/InputSearch';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import getSignedDocuments, {
  ISignedDocument,
} from '../../services/api/requests/getSignedDocuments';
import { IMeta } from '../../services/api/types';

const headCells = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
    disableSort: true,
  },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name', disableSort: true },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    disableSort: true,
  },
  { id: 'url', numeric: false, disablePadding: false, label: 'PDF' },
  { id: 'siteUrl', numeric: false, disablePadding: false, label: 'Site', disableSort: true },
  { id: 'exigoUserId', numeric: false, disablePadding: false, label: 'UserID', disableSort: true },
];

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();

  const dateFns = new DateFnsUtils();

  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(15);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [list, setList] = React.useState<ISignedDocument[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchPayoutListAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, searchTerm]);

  const fetchPayoutListAsync = async () => {
    try {
      const response = await getSignedDocuments({
        page,
        limit: rowsPerPage,
        search: searchTerm,
      });

      setLoading(false);
      setList(response.data.items);

      if (response?.data?.meta) {
        setMeta(response?.data?.meta);
      }
    } catch (e) {
      console.warn('SIGNED_DOCS_ERROR', e);
    }
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Signed documents</Title>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <div className={classes.searchRow}>
          <InputSearch onSubmit={(val) => setSearchTerm(val)} />
        </div>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <CustomTableHead classes={classes} headCells={headCells} />

            <TableBody>
              {loading && <TableSkeleton />}

              {!loading &&
                list?.map((item) => {
                  const { id, createdAt, data, url, userId } = item;
                  const { firstName, lastName, siteUrl } = data || {};

                  const create =
                    createdAt && dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');

                  return (
                    <TableRow key={id}>
                      <TableCell align="left">{firstName || '------'}</TableCell>
                      <TableCell align="left">{lastName || '------'}</TableCell>
                      <TableCell>{create || '------'}</TableCell>
                      <TableCell align="left">
                        <a href={url} target="_blank" rel="noreferrer">
                          a{url || '------'}
                        </a>{' '}
                      </TableCell>
                      <TableCell align="left">{siteUrl || '------'}</TableCell>
                      <TableCell align="left">{userId || '------'}</TableCell>
                    </TableRow>
                  );
                })}

              {!loading && !list?.length && (
                <TableRow>
                  <TableCell>
                    There are no signed documents{' '}
                    {searchTerm.length > 0 ? `for current search - ${searchTerm} ` : ''}{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedTable;
