import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IProductListItem } from './getProductList';

export interface IComboProductRequestData {
  name: string;
  description: string;
  file?: any;
  id?: string | number;
  bgColor: string;
  color: string;
  priority: number;
  imageUrl: string | null;
}

const request = (data: IComboProductRequestData) => {
  return apiClient
    .post<IResponseAxios<IProductListItem>>(ApiUrls.comboProduct + 'create', data)
    .then((response) => response);
};

export default request;
