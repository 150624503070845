import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface ICreatePayoutReportRequest {
  method?: 'all' | 'PayPal' | 'ACH' | 'bankWire' | 'cash' | 'pushToCard';
  created_from: string | null;
  created_to: string | null;
  edited_from: string | null;
  edited_to: string | null;
}

const request = (data: ICreatePayoutReportRequest) => {
  return apiClient.post<IResponseAxios>(ApiUrls.payoutBase, data).then((response) => response);
};

export default request;
