import { Box, Button, CssBaseline, Grid, Paper, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import createUpgradeRule, { IUpgradeRule } from '../../services/api/requests/createUpgradeRule';
import editUpgradeRule from '../../services/api/requests/editUpgradeRule';
import { IOption } from '../../utils/commonTypes';
import setNotification from '../../utils/notifications';
import { createInput, useStandardFormStyles } from '../FormBase';
import InputSelect from '../InputSelect';

const requiredMessage = 'This field is required';

const validationSchema = Yup.object().shape({
  upgradeSku: Yup.string()
    .required(requiredMessage)
    .matches(/^[a-zA-Z0-9-_]+$/, 'These characters are not supported'),
  toId: Yup.string().required(requiredMessage),
  fromId: Yup.string().required(requiredMessage),
});

interface IProps {
  options: IOption[];
  ruleForEditing?: any;

  onCancelClick(): void;

  onSuccess(): void;
}

const initialValues: IUpgradeRule = {
  fromId: '',
  toId: '',
  upgradeSku: '',
  deductUpgradeSku: '',
  CV: 0,
  QV: 0,
  SCV: 0,
  SQV: 0,
  FSB: 0,
};

const CreateEditRule: React.FC<IProps> = (props) => {
  const classes = useStandardFormStyles();

  const { options, ruleForEditing, onCancelClick, onSuccess } = props;

  const actionText = !!ruleForEditing ? 'Edit' : 'Create';

  const fk: FormikProps<IUpgradeRule> = useFormik({
    initialValues: ruleForEditing
      ? {
          fromId: ruleForEditing.fromId,
          toId: ruleForEditing.toId,
          upgradeSku: ruleForEditing.upgradeSku,
          deductUpgradeSku: ruleForEditing.deductUpgradeSku,
          CV: ruleForEditing.CV,
          QV: ruleForEditing.QV,
          SCV: ruleForEditing.SCV,
          SQV: ruleForEditing.SQV,
          FSB: ruleForEditing.FSB,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: (values) => {
      if (ruleForEditing) {
        editUpgradeRule(values, ruleForEditing.id).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            onSuccess();
          }
        });
        return;
      }

      createUpgradeRule(values).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          onSuccess();
        }
      });
    },
  });

  const createInputField = createInput<IUpgradeRule>(fk);

  const fromOptions = options.filter((item) => item.value !== fk.values.toId);
  const toOptions = options.filter((item) => item.value !== fk.values.fromId);

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display={'flex'}>
                  <InputSelect
                    id="fromId"
                    label={'From Plan*'}
                    value={fk.values.fromId}
                    required
                    onChange={(e) => fk.setFieldValue('fromId', e.target.value)}
                    options={fromOptions}
                    error={!!(fk.errors.fromId && fk.touched.fromId)}
                    onBlur={fk.handleBlur}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('fromId', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box display={'flex'}>
                  <InputSelect
                    id="toId"
                    label={'To Plan*'}
                    value={fk.values.toId}
                    required
                    onChange={(e) => fk.setFieldValue('toId', e.target.value)}
                    options={toOptions}
                    error={!!(fk.errors.toId && fk.touched.toId)}
                    onBlur={fk.handleBlur}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('toId', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete={'new-password'}
                  name={'upgradeSku'}
                  variant="outlined"
                  required
                  fullWidth
                  id={'upgradeSku'}
                  type={'text'}
                  label={'SKU'}
                  error={!!(fk.errors.upgradeSku && fk.touched.upgradeSku)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.upgradeSku && fk.errors.upgradeSku}
                  value={fk.values.upgradeSku}
                  onChange={fk.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete={'new-password'}
                  name={'deductUpgradeSku'}
                  variant="outlined"
                  required
                  fullWidth
                  id={'deductUpgradeSku'}
                  type={'text'}
                  label={'Deduct Upgrade Sku'}
                  error={!!(fk.errors.deductUpgradeSku && fk.touched.deductUpgradeSku)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.deductUpgradeSku && fk.errors.deductUpgradeSku}
                  value={fk.values.deductUpgradeSku}
                  onChange={fk.handleChange}
                />
              </Grid>

              {createInputField('CV', 'CV', 6, 'number')}
              {createInputField('QV', 'QV', 6, 'number')}
              {createInputField('SCV', 'SCV', 6, 'number')}
              {createInputField('SQV', 'SQV', 6, 'number')}
              {createInputField('FSB', 'FSB', 6, 'number')}
            </Grid>

            <Box display={'flex'} justifyContent={'space-between'} className={classes.submit}>
              <Button
                type="button"
                disabled={!fk.isValid}
                variant="contained"
                color="primary"
                // @ts-ignore
                onClick={() => fk.handleSubmit(fk.values)}
              >
                {actionText}
              </Button>

              <Button type="button" onClick={onCancelClick} variant="outlined" color="primary">
                Cancel
              </Button>
            </Box>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditRule;
