import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Close, GetApp } from '@material-ui/icons';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import { USER_MIGRATIONS_DATE_FORMAT } from '../../constants';
import generateUserMigrationsReport from '../../services/api/requests/userMigrationsReport';

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();

  const [date, setDate] = useState<Date>(new Date());

  const handleRangeClear = (e: React.SyntheticEvent<{}>) => {
    e.stopPropagation();
    setDate(new Date());
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Reports</Title>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Box display="flex" alignItems="center">
                    <Box marginRight="20px">User Migrations</Box>
                    <Modal
                      button={
                        <TextField
                          autoComplete="new-password"
                          variant="outlined"
                          type="text"
                          autoFocus
                          size="small"
                          value={dateFns.format(new Date(date), USER_MIGRATIONS_DATE_FORMAT)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleRangeClear} edge="end">
                                  <Close />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      }
                      renderContent={(onClose: any) => (
                        <Calendar
                          onChange={(item: any) => {
                            setDate(new Date(item));
                            onClose();
                          }}
                          maxDate={new Date()}
                          date={date}
                        />
                      )}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Box>
                    <Can perform={Permissions.generateUserMigrationsReport}>
                      <IconButton
                        aria-label="Download"
                        title="Download"
                        onClick={() => generateUserMigrationsReport(date)}
                      >
                        <GetApp />
                      </IconButton>
                    </Can>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default EnhancedTable;
