import { Box, Button, Grid, Paper } from '@material-ui/core';
import 'date-fns';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import setCountriesPhoneVerification from '../../services/api/requests/setCountriesPhoneVerification';
import { fetchCountries } from '../../store/entities/actions';
import { selectCountries } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import Can, { Permissions } from '../Can';
import CountrySelect from '../CountrySelect';
import { useStandardFormStyles } from '../FormBase';
import Title from '../Title';

const CreateEditTwilioCountrySettings: React.FC = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();

  const countries = useSelector(selectCountries);

  const disabledList = countries.filter((it) => !it.phoneVerificationEnabled);
  const [countryOptions, setCountryOption] = React.useState<boolean>(Boolean(disabledList.length));
  const [countriesToSave, setCountriesToSave] = React.useState<string[]>(
    disabledList.map((it) => it.code)
  );

  const onAddCountry = (_i: string, list: string[]) => setCountriesToSave(list);
  const openSection = () => setCountryOption(true);
  const closeSection = () => setCountryOption(false);
  const onSubmit = () => {
    setCountriesPhoneVerification(countriesToSave).then((response) => {
      if (response) {
        setNotification('success', {
          message: 'Success',
        });
        dispatch(fetchCountries());
        if (!countriesToSave.length) {
          closeSection();
        }
      }
    });
  };

  return (
    <Can perform={Permissions.enablePhoneVerification}>
      {countryOptions ? (
        <Paper className={classes.paper}>
          <Title>Disable Twilio verification for countries:</Title>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CountrySelect
                id={'countries'}
                value={countriesToSave}
                onChange={onAddCountry}
                withInstances
                required={!countriesToSave?.length}
              />
            </Grid>
          </Grid>

          <Box display={'flex'} justifyContent={'space-between'} className={classes.submit}>
            <Button
              type="button"
              disabled={countriesToSave.length === disabledList.length}
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              Save
            </Button>

            {Boolean(!disabledList.length) && (
              <Button type="button" onClick={closeSection} variant="outlined" color="primary">
                Cancel
              </Button>
            )}
          </Box>
        </Paper>
      ) : (
        <Grid item xs={12} md={6}>
          <Button
            type="submit"
            fullWidth
            onClick={openSection}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Local restrictions
          </Button>
        </Grid>
      )}
    </Can>
  );
};

export default CreateEditTwilioCountrySettings;
