import { isBefore } from 'date-fns';
import { APIs } from '../apiUrls';
import apiClient from '../client';
import { IMeta } from '../types';

export interface ILog {
  action: string;
  browser: string;
  createdAt: Date;
  device: string;
  id: number;
  ip: string;
  level: string;
  os: string;
  tag: string;
  type: string;
  updatedAt: Date;
  url: string;
  data: {
    customerId?: number;
    username?: string;
    instance?: string;
    ip?: string;
    browser?: string;
    os?: string;
    url?: string;
  };
}

export interface ICustomersLogsResponse {
  payload: {
    rows: ILog[];
    count: number;
    meta?: IMeta;
  };
}

export type SortBy = 'createdAt';

interface IProps {
  data: any;
  page: number;
  action: string;
  limit: number;
  from: string;
  to: string;
}

const request = async (params: IProps): Promise<ICustomersLogsResponse> => {
  const lastTimeGetToken = localStorage.getItem('LAT_TIME_CREATE');
  if (
    !lastTimeGetToken ||
    !isBefore(new Date(), new Date(new Date(lastTimeGetToken).getTime() + 60 * 60 * 1000))
  ) {
    const token = await apiClient.get(`${APIs.LOG_SERVER_URL}auth`).then((res) => res.data.token);
    localStorage.setItem('LAT', token);
    localStorage.setItem('LAT_TIME_CREATE', new Date().toISOString());
  }
  const url = new URL(`${APIs.LOG_SERVER_URL}logs`);
  Object.entries(params)
    .filter(([key, value]) => !!value && value !== 'all')
    .forEach(([key, value]) => {
      if (typeof value === 'object') {
        Object.entries(value)
          .filter(([key, value]) => !!value)
          .forEach(([key, value]) => url.searchParams.append(`data.${key}`, value as string));
      } else {
        url.searchParams.append(key, value);
      }
    });
  return apiClient.get(url.toString()).then((res) => res.data);
};

export default request;
