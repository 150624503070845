import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface ICreateAdmin {
  email: string;
  password: string;
  roleId: number | string;
  firstName: string;
  lastName: string;
  isActive?: boolean;
}

const request = (data: ICreateAdmin) =>
  apiClient.post<any>(ApiUrls.createAdmin, data).then((response) => response);

export default request;
