import { Button, CssBaseline, Grid, Paper, TextField } from '@material-ui/core';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../components/BackButton';
import BulletDescriptions from '../../components/BulletDescriptions';
import CreateEditTwilioCountrySettings from '../../components/CreateEditTwilioCountrySettings';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Title from '../../components/Title';
import createSetting, { ICreateSetting } from '../../services/api/requests/createSetting';
import editSetting, { IEditSetting } from '../../services/api/requests/editSetting';
import { editSettingData } from '../../store/entities/actions';
import { selectSettingForEditing } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const TWILIO_SETTING_CODE = 'GLOBAL_PHONE_VERIFICATION_ENABLED';
const initialValues: ICreateSetting = {
  options: [],
  code: '',
  name: '',
};

const CreateEditSetting: FC = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const selectedSetting = useSelector(selectSettingForEditing);

  const action = selectedSetting ? 'Edit' : 'Create';

  useEffect((): any => {
    return () => {
      dispatch(editSettingData(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fk: FormikProps<ICreateSetting> = useFormik({
    initialValues: selectedSetting
      ? {
          code: selectedSetting.code,
          name: selectedSetting.name,
          options: selectedSetting.options,
        }
      : initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const createData: ICreateSetting = {
        name: values.name,
        code: values.code,
        options: values.options,
      };

      if (selectedSetting) {
        const editData: IEditSetting = {
          ...createData,
          id: selectedSetting.id,
        };

        try {
          const response = await editSetting(editData);
          if (response) {
            setNotification('success', {
              message: 'Success',
            });
            dispatch(push(pageLinks.settings));
          }
        } catch (e) {
          console.error('Error: ', e);
        }
        return;
      }

      try {
        const response = await createSetting({ ...createData, value: values.value });
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.settings));
        }
      } catch (e) {
        console.error('Error: ', e);
      }
    },
  });

  const createInputField = createInput(fk);

  return (
    <Grid item xs={12}>
      <BackButton name="Back" link={pageLinks.settings} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{action} Setting</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('name', 'Name')}
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete="Code"
                  name="code"
                  variant="outlined"
                  required
                  fullWidth
                  disabled={Boolean(selectedSetting)}
                  id="code"
                  type="text"
                  label="Code"
                  error={!!(fk.errors.code && fk.touched.code)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.code && fk.errors.code}
                  value={fk.values.code}
                  onChange={fk.handleChange}
                />
              </Grid>
              <Grid item xs={12} direction={'row'} alignItems={'center'}>
                <BulletDescriptions
                  title="Options"
                  onChange={(list: string[]) => fk.setFieldValue('options', list)}
                  bullets={fk.values.options}
                />
              </Grid>
              {Boolean(!selectedSetting && fk.values.options.length) && (
                <Grid item xs={12} sm={6}>
                  <InputSelect
                    label={'Initial value'}
                    value={fk.values.value}
                    onChange={(e): void => fk.setFieldValue('value', e.target.value)}
                    options={fk.values.options.map((it) => ({ value: it, label: it }))}
                  />
                </Grid>
              )}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {action}
            </Button>
          </form>
          {selectedSetting?.code === TWILIO_SETTING_CODE && <CreateEditTwilioCountrySettings />}
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditSetting;
