import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IDocumentBody {
  url: string;
  type: string;
  countryCodes: string[];
  instanceCodes: string[];
}

const request = (params: IDocumentBody) => {
  return apiClient.post<IResponseAxios>(ApiUrls.legalDocument, params);
};

export default request;
