import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import InputSearch from '../../components/InputSearch';
import InputSelect from '../../components/InputSelect';
import Modal from '../../components/Modal';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import getOrders, { IOrder } from '../../services/api/requests/getOrders';
import { IMeta } from '../../services/api/types';
import { toIsoString } from '../../utils/helpers';

const headCells = [
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date Created' },
  { id: 'username', numeric: false, disablePadding: false, label: 'Username/ExigoId' },
  { id: 'rebillyTransactionId', numeric: false, disablePadding: false, label: 'Order Id' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'product', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Price' },
  { id: 'paymentMethod', numeric: false, disablePadding: false, label: 'Gateway Name' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

const methodOptions = [
  { label: 'All', value: 'all' },
  { label: 'Stripe', value: 'Stripe' },
  { label: 'PayPal', value: 'PayPal' },
  { label: 'TestProcessor', value: 'TestProcessor' },
  { label: 'EBANX', value: 'EBANX' },
  { label: 'NGenius', value: 'NGenius' },
  { label: 'BitPay', value: 'BitPay' },
  { label: 'CoinPayments', value: 'CoinPayments' },
  { label: 'PayULatam', value: 'PayULatam' },
  { label: 'AstroPayCard', value: 'AstroPayCard' },
  { label: 'Plaid', value: 'Plaid' },
  { label: 'e-wallet', value: 'e-wallet' },
  { label: 'MyFatoorah', value: 'MyFatoorah' },
  { label: 'Unknown', value: 'Unknown' },
];

const typeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Multiple', value: 'Multiple' },
  { label: 'Renewal', value: 'Renewal' },
  { label: 'Subscription', value: 'Subscription' },
  { label: 'Upgrade', value: 'Upgrade' },
  { label: 'One-time', value: 'One-time' },
];

const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Paid', value: 'Paid' },
  { label: 'On hold', value: 'On hold' },
  { label: 'Processing', value: 'Processing' },
  { label: 'Canceled', value: 'Canceled' },
  { label: 'Refunded', value: 'Refunded' },
  { label: 'Blockchain Pending', value: 'Blockchain Pending' },
  { label: 'Error', value: 'Error' },
  { label: 'Expired', value: 'Expired' },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar: React.FC<{
  onFilterClick(e: React.SyntheticEvent): void;
}> = (props) => {
  const classes = useToolbarStyles();
  const { onFilterClick } = props;
  return (
    <Toolbar className={classes.root}>
      <div className={classes.title} />
      <Tooltip title="Filter list" onClick={onFilterClick}>
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  status: {
    '& .status': {
      padding: '4px 5px',
      borderRadius: '6px',
      border: '1px solid',
      color: '#fff',
      textAlign: 'center',
      '&.green': {
        backgroundColor: '#8ba929',
      },
      '&.blue': {
        backgroundColor: '#1460a5',
      },
      '&.red': {
        backgroundColor: '#f44c46',
      },
      '&.orange': {
        backgroundColor: '#f89a06',
      },
    },
  },
}));

export default function EnhancedTable() {
  const classes = { ...useStandardTableStyles(), ...useStyles() };
  const dateFns = new DateFnsUtils();

  const [typeFilter, seTypeFilter] = React.useState<any>('all');
  const [statusFilter, setStatusFilter] = React.useState<any>('all');
  const [filterPanel, togglePanel] = React.useState<boolean>(false);
  const [sortDirection, setDirection] = React.useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = React.useState<string>('createdAt');
  const [paymentMethodFilter, setPaymentMethodFilter] = React.useState<any>('all');
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(20);
  const [orderList, setOrderList] = React.useState<IOrder[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [meta, setMeta] = React.useState<IMeta>({ limit: 10, page: 1, total: 0, totalPages: 0 });

  const [dateState, setDateState] = React.useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
    key: 'selection';
  }>({ startDate: undefined, endDate: undefined, key: 'selection' });

  const dateRangeValue =
    dateState.startDate &&
    dateFns.isValid(dateState.startDate) &&
    dateState.endDate &&
    dateFns.isValid(dateState.endDate)
      ? `${dateFns.format(new Date(dateState.startDate), 'MMM d, yyyy')} - ${dateFns.format(
          new Date(dateState.endDate),
          'MMM d, yyyy'
        )}`
      : 'All - All';

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, sortDirection, rowsPerPage, searchTerm]);

  useEffect(() => {
    if (!dateState.endDate && !dateState.startDate) {
      fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateState.endDate, dateState.startDate]);

  const fetchList = () => {
    getOrders({
      page,
      limit: rowsPerPage,
      adminSortKey: sortBy,
      sortDirection: sortDirection.toUpperCase() as 'ASC' | 'DESC',
      typeFilter,
      statusFilter,
      paymentMethodFilter,
      searchByUser: searchTerm,
      fromDate: dateState.startDate ? toIsoString(dateState.startDate) : 'all',
      toDate: dateState.endDate ? toIsoString(dateState.endDate) : 'all',
    }).then((response) => {
      if (response) {
        setLoading(false);
        setOrderList(response.data.data.items);
        setMeta(response.data.data.meta);
      }
    });
  };

  const handleRequestSort = (event: any, property: 'asc' | 'desc') => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleRangeApply = () => {
    fetchList();
  };

  const handleRangeClear = (e: React.SyntheticEvent<{}>) => {
    e.stopPropagation();
    setDateState({ startDate: undefined, endDate: undefined, key: 'selection' });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const toggleFilterPanel = () => {
    togglePanel(!filterPanel);
  };

  const handleFilterApply = () => {
    if (page !== 1) {
      setPage(1);
      return;
    }
    fetchList();
  };

  return (
    <Grid className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Orders</Title>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <EnhancedTableToolbar onFilterClick={toggleFilterPanel} />
        {filterPanel && (
          <Grid container spacing={2} alignItems={'center'} className={classes.filterRow}>
            <Grid item xs={12} sm={6} lg={3}>
              <InputSelect
                label={'Type'}
                value={typeFilter}
                onChange={(e): void => seTypeFilter(e.target.value)}
                options={typeOptions}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <InputSelect
                label={'Status'}
                value={statusFilter}
                onChange={(e): void => setStatusFilter(e.target.value)}
                options={statusOptions}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <InputSelect
                label={'Method'}
                value={paymentMethodFilter}
                onChange={(e: React.ChangeEvent<any>): void =>
                  setPaymentMethodFilter(e.target.value)
                }
                options={methodOptions}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Button variant="contained" color="primary" fullWidth onClick={handleFilterApply}>
                Apply
              </Button>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2} className={classes.searchRow}>
          <Grid item xs={12} sm={6}>
            <InputSearch
              onSubmit={(val) => {
                setPage(1);
                setSearchTerm(val);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Modal
              onCloseCb={handleRangeApply}
              button={
                <TextField
                  autoComplete="new-password"
                  variant="outlined"
                  fullWidth
                  type={'text'}
                  label={'Date Range'}
                  autoFocus
                  value={dateRangeValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={(e) => handleRangeClear(e)} edge="end">
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
              renderContent={(onClose: any) => (
                <DateRangePicker
                  onChange={(item: any) => {
                    setDateState(item.selection);
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={true}
                  months={2}
                  ranges={[dateState]}
                  direction="horizontal"
                />
              )}
            />
          </Grid>
        </Grid>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead
              classes={classes}
              order={sortDirection}
              orderBy={sortBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {loading && <TableSkeleton />}

              {!loading &&
                orderList?.map((order) => {
                  const {
                    createdAt,
                    items,
                    rebillyTransactionID,
                    type,
                    product,
                    amount,
                    paymentMethod,
                    membership,
                    status,
                    user: { username, id: userId },
                    id,
                  } = order;

                  const itemWithId = items.find((item) => !!Number(item.exigoOrderId));
                  const exigoOrderId = itemWithId ? `${itemWithId.exigoOrderId} / ` : '';
                  const createDate = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');

                  return (
                    <TableRow key={id}>
                      <TableCell align="left">{createDate}</TableCell>
                      <TableCell align="left">
                        {username}/{userId}
                      </TableCell>
                      <TableCell align="left">
                        {exigoOrderId}
                        {rebillyTransactionID}
                      </TableCell>
                      <TableCell align="left">{type}</TableCell>
                      <TableCell align="left">
                        {product}/{membership}
                      </TableCell>
                      <TableCell>{amount}</TableCell>
                      <TableCell>{paymentMethod}</TableCell>
                      <TableCell>
                        <div className={classes.status}> {renderStatus(status)}</div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {!loading && !orderList?.length && (
                <TableRow>
                  <TableCell>
                    There are no orders{' '}
                    {searchTerm.length > 0 ? `for current search - ${searchTerm} ` : ''}{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  );
}

const renderStatus = (status: string) => {
  if (status === 'Complited' || status === 'Paid') {
    return <div className="status green">{status}</div>;
  }

  if (status === 'On hold' || status === 'Refunded') {
    return <div className="status blue">{status}</div>;
  }

  if (status === 'Canceled' || status === 'Error') {
    return <div className="status red">{status}</div>;
  }

  if (status === 'Blockchain Pending') {
    return (
      <div className="status orange blockchain">
        <div>Blockchain</div>
        <div>Pending</div>
      </div>
    );
  }

  return <div className="status orange">{status}</div>;
};
