import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import urlDataSaga from './dataByUrlSaga';
import entitiesSaga from './entitiesSaga';

export default function* rootSaga() {
  // REMOVED FOCUS FROM INPUT NUMBER TO PREVENT VALUE CHANGES
  if (document) {
    document.addEventListener('wheel', function (event) {
      if ((document.activeElement as HTMLInputElement)?.type === 'number') {
        (document.activeElement as HTMLElement)?.blur();
      }
    });
  }

  yield all([authSaga(), entitiesSaga(), urlDataSaga()]);
}
