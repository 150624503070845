import { ActionTypes, LOGIN_USER_SUCCESS, SET_LOADER, SystemState } from './types';

const initialState: SystemState = {
  loading: false,
  activeTheme: 'theme1',
  userData: null,
};

export function systemReducer(state = initialState, action: ActionTypes): SystemState {
  switch (action.type) {
    case SET_LOADER:
      return { ...state, loading: action.payload };

    case LOGIN_USER_SUCCESS:
      return { ...state, userData: action.payload };

    default:
      return state;
  }
}
