import { Box, Button, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import 'date-fns';
import * as React from 'react';
import { useState } from 'react';
import deleteNestedRule from '../../services/api/requests/deleteNestedRule';
import { INestedRuleEdit } from '../../services/api/requests/editNestedRules';
import getNestedRules, { INestedRule } from '../../services/api/requests/getNestedRules';
import Can, { Permissions } from '../Can';
import DeleteModalButton from '../DeleteModalButton';
import { useStandardFormStyles } from '../FormBase';
import CreateEditNestedRules from './table';

const NestedRules: React.FC = () => {
  const classes = useStandardFormStyles();
  const [nestedRules, setNestedRules] = React.useState<INestedRule[] | null>(null);
  const [selectedRule, editRule] = useState<INestedRuleEdit | null>(null);
  const [showCreateRulePanel, togglePanel] = React.useState<boolean>(false);

  React.useEffect(() => {
    fetchRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRules = async () => {
    try {
      const response = await getNestedRules();
      setNestedRules(response.data.data.items);
    } catch (e) {
      console.warn('e', e);
    }
  };

  const handleEditRule = (rule: INestedRule) => {
    const data = {
      nestedPlanId: rule.nestedPlan.id,
      overlappedPlanId: rule.overlappedPlan.id,
      id: rule.id,
    };

    editRule(data);
    togglePanel(true);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteNestedRule(id);
      fetchRules();
    } catch (e) {
      console.warn('delete nested error', e);
    }
  };

  return (
    <>
      <Grid container xs={12}>
        <Box display={'flex'} width={'100%'} flexWrap={'wrap'} justifyContent="space-between">
          {nestedRules &&
            (nestedRules as Array<INestedRule>).map((rule) => {
              const nestedPlan = rule.nestedPlan;
              const overlappedPlan = rule.overlappedPlan;
              const name = `${overlappedPlan.name} - overlaps - ${nestedPlan.name}(${nestedPlan.sku})`;

              return (
                <Button
                  className={clsx(classes.boxButton, classes.textSizeSmall)}
                  fullWidth
                  color="primary"
                  variant={'outlined'}
                >
                  <Box
                    display={'flex'}
                    justifyContent="space-between"
                    width={'100%'}
                    alignItems={'center'}
                  >
                    {name}
                    <div>
                      <Can perform={Permissions.updatePlanUpgradeRules}>
                        <IconButton
                          aria-label="edit"
                          title={'Edit'}
                          size={'small'}
                          onClick={() => handleEditRule(rule)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Can>

                      <Can perform={Permissions.deletePlanUpgradeRules}>
                        <DeleteModalButton
                          name={name}
                          entity={'Rule'}
                          onDelete={() => handleDelete(rule.id)}
                        />
                      </Can>
                    </div>
                  </Box>
                </Button>
              );
            })}

          {showCreateRulePanel ? (
            <CreateEditNestedRules
              onCancelClick={() => {
                togglePanel(!showCreateRulePanel);
                editRule(null);
              }}
              ruleForEditing={selectedRule}
              onSuccess={() => {
                togglePanel(false);
                fetchRules();
              }}
            />
          ) : (
            <Can perform={Permissions.createPlanUpgradeRules}>
              <Box
                width={'100%'}
                justifyContent="space-between"
                alignItems={'center'}
                padding={'20px 0 0 0'}
                onClick={() => togglePanel(!showCreateRulePanel)}
              >
                <Button fullWidth variant="outlined" color="primary">
                  + Add Rule
                </Button>
              </Box>
            </Can>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default NestedRules;
