import pickBy from 'lodash/pickBy';
import { ICreatePlan } from '../services/api/requests/createProductPlan';
import { IPlanResponse } from '../services/api/requests/editProductPlan';

const fieldsForTable = {
  productId: '',
  planId: '',
  name: '',
  currency: '',
  color: '#000',
  bgColor: '#fff',
  description: '',
  fullDescription: '',
  itemDescription: '',
  additionalInfo: '',
  rebillyPlanId: '',
  intervalUnit: 'day',
  intervalSize: '',
  sku: '',
  recurringSKU: '',
  retailExigoItem: '',
  discountExigoItem: '',
  questDollarsReward: '',
  priority: '',
  setupPrice: '',
  retailPrice: '',
  recurringPrice: '',
  recurringRetailPrice: '',
  retailCV: '',
  retailFSB: '',
  retailQV: '',
  retailRecurringCV: '',
  retailRecurringFSB: '',
  retailRecurringQV: '',
  retailRecurringSCV: '',
  retailRecurringSQV: '',
  retailSCV: '',
  retailSQV: '',
  CV: '',
  QV: '',
  SCV: '',
  SQV: '',
  FSB: '',
  recurringCV: '',
  recurringQV: '',
  recurringSCV: '',
  recurringSQV: '',
  recurringFSB: '',
  oneDayRecurringSKU: '',
  noExigo: false,
  autopayable: false,
  fsbEnabled: false,
  isAddon: false,
  bullets: [],
  countryCodes: [],
  regionalDatasets: [],
  serviceIDs: [],
  isRecurring: false,
  hasRetail: false,
  retailOnly: false,
  icon: '',
  imageUrl: '',
  gatewayMethodIds: [],
  status: 'active',
  primaryServiceId: '',
  requireAgreement: false,
  requireVerification: true,
  splitValues: '',
  splitFSB: '',
  splitSKU: '',
  splitBaseValue: '',
  enrollerCashback: 0,
  customerCashback: 0,
};

export const normalizePlan = (plan: IPlanResponse): ICreatePlan => {
  const normalized: ICreatePlan = {} as ICreatePlan;

  //@ts-ignore
  const gatewayMethodIds = (plan?.gatewayMethods || []).map((item) => item.id);

  //@ts-ignore
  const recurringSKU = plan.recurringSku || plan.recurringSKU;

  Object.keys(fieldsForTable).forEach((fieldKey) => {
    if (plan[fieldKey as keyof IPlanResponse] !== undefined) {
      // @ts-ignore
      normalized[fieldKey] = plan[fieldKey as keyof IPlanResponse];
    }
  });

  return { ...(normalized || {}), gatewayMethodIds, recurringSKU };
};

export const clearEmptyFields = (plan: ICreatePlan): any => {
  return pickBy(plan, (val) => val !== null && val !== '');
};
