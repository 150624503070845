import { Avatar, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import * as React from 'react';
import BackButton from '../../components/BackButton';
import FormLayout from '../../components/FormLayout';
import pageLinks from '../../utils/pageLinks';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  center: {
    textAlign: 'center',
    marginTop: 20,
  },
}));

const ForgotPasswordSuccess: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <FormLayout>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <BackButton name={'back to login'} link={pageLinks.login} />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <EmailIcon />
          </Avatar>
          <Typography className={classes.center} component="h1" variant="h5">
            Your password reset email has been sent!
          </Typography>
          <span className={classes.center}>
            We have sent a password reset email to your email address. Please check your inbox to
            continue.
          </span>
        </div>
      </Grid>
    </FormLayout>
  );
};

export default ForgotPasswordSuccess;
