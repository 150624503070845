import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { ICreateAdmin } from './createAdmin';

export interface IEditAdmin extends ICreateAdmin {
  id: number;
}

interface ICreateAdminResponse {}

const request = (data: Partial<IEditAdmin>) =>
  apiClient
    .put<ICreateAdminResponse>(ApiUrls.editAdmin + data.id, data)
    .then((response) => response);

export default request;
