import { Box, Button, CssBaseline, Grid, Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import InputSelect from '../../components/InputSelect';
import addNestedRule, { INestedRuleBody } from '../../services/api/requests/addNestedRules';
import editNestedRule, { INestedRuleEdit } from '../../services/api/requests/editNestedRules';
import { selectPlansOptionsValueID } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import { useStandardFormStyles } from '../FormBase';

const requiredMessage = 'This field is required';

const validationSchema = Yup.object().shape({
  nestedPlanId: Yup.string().required(requiredMessage),
  overlappedPlanId: Yup.string().required(requiredMessage),
});

interface IProps {
  ruleForEditing: INestedRuleEdit | null;

  onCancelClick(): void;

  onSuccess(): void;
}

const initialValues: INestedRuleBody = {
  nestedPlanId: '',
  overlappedPlanId: '',
};

const CreateEditNestedRules: React.FC<IProps> = (props) => {
  const classes = useStandardFormStyles();

  const options = useSelector(selectPlansOptionsValueID);

  const { ruleForEditing, onCancelClick, onSuccess } = props;

  const actionText = !!ruleForEditing ? 'Edit' : 'Create';

  const fk: FormikProps<INestedRuleBody> = useFormik({
    initialValues: ruleForEditing
      ? {
          nestedPlanId: ruleForEditing.nestedPlanId,
          overlappedPlanId: ruleForEditing.overlappedPlanId,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: async (values) => {
      try {
        if (ruleForEditing) {
          await editNestedRule({ ...values, id: ruleForEditing.id });
        } else {
          await addNestedRule(values);
        }

        setNotification('success', {
          message: 'Success',
        });

        onSuccess();
      } catch (e) {
        console.warn('eeee', e);
      }
    },
  });

  const nestedOptions = options.filter((item) => item.value !== fk.values.overlappedPlanId);
  const overlappedOptions = options.filter((item) => item.value !== fk.values.nestedPlanId);

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display={'flex'}>
                  <InputSelect
                    id="nestedPlanId"
                    label={'Nested'}
                    value={fk.values.nestedPlanId}
                    required
                    onChange={(e) => fk.setFieldValue('nestedPlanId', e.target.value)}
                    options={nestedOptions}
                    error={!!(fk.errors.nestedPlanId && fk.touched.nestedPlanId)}
                    onBlur={fk.handleBlur}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('nestedPlanId', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box display={'flex'}>
                  <InputSelect
                    id="overlappedPlanId"
                    label={'Overlapped Plan*'}
                    value={fk.values.overlappedPlanId}
                    required
                    onChange={(e) => fk.setFieldValue('overlappedPlanId', e.target.value)}
                    options={overlappedOptions}
                    error={!!(fk.errors.overlappedPlanId && fk.touched.overlappedPlanId)}
                    onBlur={fk.handleBlur}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('overlappedPlanId', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>

            <Box display={'flex'} justifyContent={'space-between'} className={classes.submit}>
              <Button
                type="button"
                disabled={!fk.isValid}
                variant="contained"
                color="primary"
                // @ts-ignore
                onClick={() => fk.handleSubmit(fk.values)}
              >
                {actionText}
              </Button>

              <Button type="button" onClick={onCancelClick} variant="outlined" color="primary">
                Cancel
              </Button>
            </Box>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditNestedRules;
