import { Button, CssBaseline, Grid, Paper } from '@material-ui/core';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import addIpToWhiteList from '../../services/api/requests/createWhitelistIp';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const requiredString = (field: string) => `${field} is required field`;

const validationSchema = Yup.object().shape({
  ipAddress: Yup.string()
    .required(requiredString('IP'))
    .trim()
    .matches(/^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/, 'Incorrect IP'),
  description: Yup.string().required(requiredString('Description')),
});

interface IWhiteLIstIp {
  ipAddress: string;
  description: string;
}

const CreateWhiteLIstIp: FC<any> = () => {
  const dispatch = useDispatch();
  const classes = useStandardFormStyles();
  const fk: FormikProps<IWhiteLIstIp> = useFormik({
    initialValues: { ipAddress: '', description: '' },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = {
        ipAddress: values.ipAddress.trim(),
        description: values.description.trim(),
      };

      try {
        await addIpToWhiteList(data);
        dispatch(push(pageLinks.whiteList));
        setNotification('success', { message: 'Success' });
      } catch (e) {
        console.warn(e.message);
      }
    },
  });

  const createInputField = createInput<IWhiteLIstIp>(fk);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.whiteList} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`Add IP to whitelist`}</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('ipAddress', 'IP', 6)}
              {createInputField('description', 'Description', 6)}
            </Grid>
            <Button type="submit" variant="contained" color="primary" className={classes.submit}>
              Add
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};
export default CreateWhiteLIstIp;
