import { ITokenData, ITokenRequest } from '../../services/api/requests/getToken';
import {
  ChangeThemeAction,
  CHANGE_THEME,
  LogInAction,
  LogInSuccessAction,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT,
  LogOutAction,
  SetLoaderAction,
  SET_LOADER,
} from './types';

export function changeTheme(payload: string): ChangeThemeAction {
  return {
    type: CHANGE_THEME,
    payload,
  };
}

export function logOutUser(): LogOutAction {
  return {
    type: LOGOUT,
  };
}

export function logInUser(payload: ITokenRequest): LogInAction {
  return {
    type: LOGIN_USER,
    payload,
  };
}

export function saveUserData(payload: ITokenData): LogInSuccessAction {
  return {
    type: LOGIN_USER_SUCCESS,
    payload,
  };
}

export function setLoader(payload: boolean): SetLoaderAction {
  return {
    type: SET_LOADER,
    payload,
  };
}
