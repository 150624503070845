export const APIs = {
  MAIN_URL: process.env.REACT_APP_API_URL,
  UPLOAD_SERVICE_URL: process.env.REACT_APP_API_UPLOAD_URL,
  LOG_SERVER_URL: process.env.REACT_APP_LOG_API_URL,
};

console.warn('process.env.REACT_APP_API_URL', process.env.REACT_APP_API_URL);

const urls = {
  //? customers (ANOTHER API)
  customerPayoutList: '/admin/customer/',
  payoutBase: APIs.UPLOAD_SERVICE_URL + '/admin/customer/export/payout',

  //? Signed docs
  signedDocuments: '/admin/customer/docs/signed',

  //? Auth
  signIn: '/admin-auth/sign-in',
  refreshToken: '/admin-auth/refresh-tokens',
  forgotPassword: '/admin-auth/forgot-password',
  resetPassword: '/admin-auth/reset-password',
  emailVerify: '/user/email-verify',

  //? Other
  products: '/shop/products?pure=true',
  services: 'shop/services',
  primaryService: 'shop/available-primary-services',
  instances: '/region/list',
  countries: '/region/country/list',
  orderList: '/admin/order/list',

  syncServices: '/admin/third-party/synchronize',

  //? Admins
  adminList: '/admin/operator/list',
  createAdmin: '/admin/operator/create',
  editAdmin: '/admin/operator/',
  deleteAdmin: '/admin/operator/',
  deactivateAdmin: '/admin/operator/',
  massPayouts: '/admin/mass-payout',

  //? Legal Documents
  legalDocuments: '/admin/legal-documents',
  legalDocument: '/admin/legal-document',

  //? Products
  productList: '/admin/product/list',
  createProduct: '/admin/product/create',
  editProduct: '/admin/product/',
  deleteProduct: '/admin/product/',
  baseProduct: '/admin/product/',
  createUpgradeRule: '/admin/plan-upgrade-rules/create',
  upgradeRulesBase: '/admin/plan-upgrade-rules/',
  comboBase: '/admin/combo/',
  comboProduct: '/admin/combo/product/',
  plan: '/admin/plan/',
  nestedRules: '/admin/nested-rules/',
  productCategoriesList: '/admin/product-category',
  editProductCategoriesList: '/admin/product-category/',
  deleteProductCategories: '/admin/product-category/',

  createPurchaseRule: '/admin/plan/{id}/purchase-rule',
  deletePurchaseRule: '/admin/plan/purchase-rule/{id}',
  createRequiredPlan: '/admin/plan/purchase-rule/{id}/required-plan',
  updateRequiredPlan: '/admin/plan/purchase-rule/required-plan/{id}',
  deleteRequiredPlan: '/admin/plan/purchase-rule/required-plan/{id}',
  readPurchaseRules: '/admin/plan/{id}/purchase-rules',

  readUpsellingRules: '/admin/upselling-rules',
  editUpsellingRules: '/admin/upselling-rules/',
  deleteUpsellingRules: '/admin/upselling-rules/',
  createUpsellingRules: '/admin/upselling-rules',

  readSwitchingRules: '/admin/switching-rules',
  editSwitchingRules: '/admin/switching-rules/',
  deleteSwitchingRules: '/admin/switching-rules/',
  createSwitchingRules: '/admin/switching-rules',

  changePlanStatus: '/admin/plan/{id}/status',
  changeProductStatus: '/admin/product/{id}/status',
  changeComboPlanStatus: '/admin/combo-info/{id}/status',
  changeComboProductStatus: '/admin/combo-product/{id}/status',

  //? Instances / Countries
  instanceList: '/admin/instance/list',
  createInstance: '/admin/instance/create',
  editInstance: '/admin/instance/',
  deleteInstance: '/admin/instance/',
  countryList: 'admin/country/list',
  updateCountry: '/admin/country/',
  setCountriesPhoneVerification: '/admin/country/phone-verification/disable',

  instGroups: 'admin/country/group/list',

  //? Payments
  gatewayList: '/admin/gateway/list',
  createGateway: '/admin/gateway/',
  editGateway: '/admin/gateway/',
  deleteGateway: '/admin/gateway/',
  createPaymentMethod: '/admin/payment-method/',
  paymentMethodList: '/admin/payment-method/list',
  editPaymentMethod: '/admin/payment-method/',

  //? Settings
  createSetting: '/admin/setting',
  editSetting: '/admin/setting/',
  updateSettingValue: '/admin/setting/{id}/value',
  deleteSetting: '/admin/setting/',
  settingsList: '/admin/settings',

  //? Services
  getServiceList: '/admin/service/list',
  createService: '/admin/service',
  getService: '/admin/service/{id}',
  updateServiceValue: '/admin/service/{id}',
  deleteService: '/admin/service/{id}',

  //? Coupons
  couponList: '/admin/coupon/list',
  createCoupon: '/admin/coupon/create',
  deleteCoupon: '/admin/coupon/',
  editCoupon: '/admin/coupon/',
  getCoupon: '/admin/coupon/',
  createCouponUsageRule: '/admin/coupon/{id}/usage-rule',
  deleteCouponUsageRule: '/admin/coupon/usage-rule/{id}',
  addRequiredPlanToCouponUsageRule: '/admin/coupon/usage-rule/{id}/required-plan',
  updateRequiredPlanOfCouponUsageRule: '/admin/coupon/usage-rule/required-plan/{id}',
  deleteRequiredPlanOfCouponUsageRule: '/admin/coupon/usage-rule/required-plan/{id}',
  readCouponUsageRules: '/admin/coupon/{id}/usage-rules',

  //? Roles and Permissions
  adminRoles: '/admin/role/list',
  adminRole: '/admin/role/create',
  adminRoleDelete: '/admin/role/',
  adminRoleEdit: '/admin/role/',
  permissions: '/admin/permissions',
  permissionGroupCreate: '/admin/permission/group',
  permissionGroupUpdate: '/admin/permission/group/{id}',
  permissionGroupDelete: '/admin/permission/group/{id}',

  //? White List
  readWhiteList: '/admin/ip-whitelist',
  expandWhiteList: '/admin/ip-whitelist',
  deleteIpFromWhiteList: '/admin/ip-whitelist/',

  //? Customers Logs
  readCustomersLogs: '/admins/customers-logs',

  //? Countries Groups
  countriesGroupsCreate: '/admin/country/group/',
  countriesGroupsEdit: '/admin/country/group/',
  countriesGroupsDelete: '/admin/country/group/',

  //? Other
  uploadUrl: '/admin/s3-upload-url',
  userMigrations: '/admin/report/user-migrations',
  uploadAttachmentsUrl: '/admin/attachment',
  uploadImageAttachmentsUrl: '/admin/attachment/images',
  uploadVideoAttachmentsUrl: '/admin/attachment/videos',
  deleteAttachment: '/admin/attachment/',

  //? KYC
  kycSettingsList: '/kyc/settings',
  editKycSettingsLevel: 'admin/kyc/settings/',
  kycSettingsLevel: 'admin/kyc/settings',

  listLevel1: '/admin/jumio-verification/level1',
  editKycItem: '/admin/jumio-verification',

  listLevel2: '/admin/jumio-verification/level2',
  editKycItemLevel2: '/admin/jumio-verification/level2/',

  userDetails: '/admin/user/',

  productsActivations: '/admin/products-activations',
  verifyOrder: '/admin/order',
  conversionTemplatesBase: '/admin/conversion/templates/',
};

export default urls;
