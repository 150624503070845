import axios from 'axios';

const request = (
  data: { signedUploadUrl: string; imageUrl: string },
  file?: File
): Promise<string> =>
  axios
    .put(data?.signedUploadUrl, file, {
      headers: { 'Content-Type': file?.type },
    })
    .then((response) => data.imageUrl)
    .catch((error) => {
      throw error;
    });

export default request;
