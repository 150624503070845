import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IComboPlan {
  discount: number;
  exigoItem: string;
  id: number;
  reduceCV: number;
  reduceFSB: number;
  reduceQV: number;
  reduceRecurringCV: number;
  reduceRecurringFSB: number;
  reduceRecurringQV: number;
  reduceRecurringSCV: number;
  reduceRecurringSQV: number;
  reduceSCV: number;
  reduceSQV: number;
  retailDiscount: number;
}

export interface ICreateCombo {
  additionalInfo: string | null;
  bgColor: string;
  bullets: string[] | null;
  color: string;
  priority: number;
  file: any;
  description: string;
  icon: string;
  fullDescription: string;
  itemDescription: string;
  level: string;
  name: string;
  plans: IComboPlan[];
  imageUrl: string | null;
  status: string;
}

const request = (data: ICreateCombo) => {
  return apiClient.post<IResponseAxios>(ApiUrls.comboBase, data).then((response) => response);
};
export default request;
