import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { ICreateGateway } from './createGateway';

export interface IEditGateway extends ICreateGateway {
  id: number;
}

const request = (data: IEditGateway) => {
  return apiClient
    .put<IResponseAxios>(ApiUrls.editGateway + data.id, data)
    .then((response) => response);
};

export default request;
