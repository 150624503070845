import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

const request = (countryCodes: string[]) => {
  return apiClient
    .put<IResponseAxios>(ApiUrls.setCountriesPhoneVerification, { countryCodes })
    .then((response) => response);
};

export default request;
