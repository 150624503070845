import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IPlan } from './getProductPlans';

interface IParams {
  planId: number;
  productId: number;
}

const request = ({ productId, planId }: IParams) =>
  apiClient
    .get<IResponseAxios<IPlan>>(ApiUrls.baseProduct + `${productId}/plan/${planId}`)
    .then((response) => response);

export default request;
