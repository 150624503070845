import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import InputSearch from '../../components/InputSearch';
import InputSelect from '../../components/InputSelect';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import getCustomerPayoutList, {
  INormalizedPayout,
} from '../../services/api/requests/getCustomerPayoutList';
import { IMeta } from '../../services/api/types';
import { payoutDetails } from '../../store/entities/actions';
import { normalizePayoutListResponse } from '../../utils/helpers';
import pageLinks from '../../utils/pageLinks';

const methodOptions = [
  { label: 'All', value: 'all' },
  { label: 'PayPal', value: 'PayPal' },
  { label: 'ACH', value: 'ACH' },
  { label: 'bankWire', value: 'bankWire' },
  { label: 'cash', value: 'cash' },
  { label: 'pushToCard', value: 'pushToCard' },
];

const headCells = [
  {
    id: 'customerId',
    numeric: false,
    disablePadding: false,
    label: 'Customer ID',
    disableSort: true,
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
    disableSort: true,
  },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name', disableSort: true },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email', disableSort: true },
  { id: 'method', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'taxId', numeric: false, disablePadding: false, label: 'TaxId', disableSort: true },
  {
    id: 'taxIdType',
    numeric: false,
    disablePadding: false,
    label: 'TaxId Type',
    disableSort: true,
  },
  {
    id: 'countryCode',
    numeric: false,
    disablePadding: false,
    label: 'Country',
    disableSort: true,
  },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date Created' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Date Updated' },
];

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();
  const [sortDirection, setDirection] = React.useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = React.useState<'createdAt' | 'updatedAt' | 'method'>('createdAt');
  const [payoutMethodFilter, setPayoutMethodFilter] = React.useState<any>('all');
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(15);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [payoutList, setList] = React.useState<INormalizedPayout[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchPayoutList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, sortDirection, rowsPerPage, searchTerm]);

  const fetchPayoutList = () => {
    getCustomerPayoutList({
      page,
      sortBy,
      sortDirection,
      limit: rowsPerPage,
      search: searchTerm,
      method: payoutMethodFilter,
    }).then((response) => {
      if (response) {
        setLoading(false);
        setList(normalizePayoutListResponse(response.data.items));
        setMeta(response.data.meta);
      }
    });
  };

  const handleRequestSort = (event: any, property: 'createdAt' | 'updatedAt' | 'method') => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleFilterApply = () => {
    if (page !== 1) {
      setPage(1);
      return;
    }
    fetchPayoutList();
  };

  const handleDetails = (data: INormalizedPayout) => {
    dispatch(push(pageLinks.payoutDetails));
    dispatch(payoutDetails(data));
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Customer Payouts</Title>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <div className={classes.searchRow}>
          <InputSearch onSubmit={(val) => setSearchTerm(val)} />
          <Grid container spacing={2} alignItems="center" className={classes.filterRow}>
            <Grid item xs={12} sm={6} lg={3}>
              <InputSelect
                label="Payment Method Type"
                value={payoutMethodFilter}
                onChange={(e: React.ChangeEvent<any>): void =>
                  setPayoutMethodFilter(e.target.value)
                }
                options={methodOptions}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Button variant="contained" color="primary" fullWidth onClick={handleFilterApply}>
                Apply
              </Button>
            </Grid>
          </Grid>
        </div>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <CustomTableHead
              classes={classes}
              order={sortDirection}
              orderBy={sortBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {loading && <TableSkeleton />}

              {!loading &&
                payoutList?.map((customerData: INormalizedPayout) => {
                  const {
                    id,
                    customerId,
                    firstName,
                    lastName,
                    email,
                    method,
                    taxId,
                    taxIdType,
                    countryCode,
                    createdAt,
                    updatedAt,
                  }: INormalizedPayout = customerData;
                  const create =
                    createdAt && dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
                  const update =
                    updatedAt && dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');

                  return (
                    <TableRow key={id} onClick={() => handleDetails(customerData)}>
                      <TableCell align="left">{customerId || '------'}</TableCell>
                      <TableCell align="left">{firstName || '------'}</TableCell>
                      <TableCell align="left">{lastName || '------'}</TableCell>
                      <TableCell align="left">{email || '------'}</TableCell>
                      <TableCell align="left">{method || '------'}</TableCell>
                      <TableCell align="left">{taxId || '------'}</TableCell>
                      <TableCell align="left">{taxIdType || '------'}</TableCell>
                      <TableCell align="left">{countryCode || '------'}</TableCell>
                      <TableCell>{create || '------'}</TableCell>
                      <TableCell>{update || '------'}</TableCell>
                    </TableRow>
                  );
                })}

              {!loading && !payoutList?.length && (
                <TableRow>
                  <TableCell>
                    There are no payouts{' '}
                    {searchTerm.length > 0 ? `for current search - ${searchTerm} ` : ''}{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedTable;
