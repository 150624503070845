import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { ICreateKycSettingsRequest } from './createKycSettingsLevel';

interface IEditKycSettingsRequest extends ICreateKycSettingsRequest {}

const request = (data: IEditKycSettingsRequest, level: string) => {
  return apiClient
    .put<IEditKycSettingsRequest>(ApiUrls.editKycSettingsLevel + level, data)
    .then((response) => response.data);
};

export default request;
