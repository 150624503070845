import DateFnsUtils from '@date-io/date-fns';
import fileDownload from 'js-file-download';
import {
  USER_MIGRATIONS_DATE_FORMAT,
  USER_MIGRATIONS_FILE_NAME_DATE_FORMAT,
} from '../../../constants';
import ApiUrls from '../apiUrls';
import apiClient from '../client';

const request = (date: Date) => {
  const dateFns = new DateFnsUtils();
  const fileName = `user_migrations_${dateFns.format(
    new Date(date),
    USER_MIGRATIONS_FILE_NAME_DATE_FORMAT
  )}.csv`;
  const formattedDate = dateFns.format(new Date(date), USER_MIGRATIONS_DATE_FORMAT);

  return apiClient
    .get<string>(`${ApiUrls.userMigrations}?date=${formattedDate}`, {
      headers: {
        'Content-Disposition': 'attachment;filename=report.csv',
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
    .then((response) => {
      if (response?.data) {
        fileDownload(response.data, fileName);
      }
    });
};

export default request;
