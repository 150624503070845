import { createFormData, createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IResponseAxios } from '../types';

export interface IPayoutAdminRequestListItem {
  amount: number;
  circlePayoutId: null | number;
  createdAt: Date;
  email: string;
  errorReason: string;
  id: number;
  sheet: string;
  status: string;
  updatedAt: Date;
}

export interface IPayoutAdminRequest {
  id: number;
  createdAt: Date;
  fileName: string;
  updatedAt: Date;
  users: IPayoutAdminRequestListItem[];
  admin: {
    email: string;
    firstName: string;
    id: number;
    lastName: string;
  };
}

export interface IAdminPayoutRequest {
  data: {
    items: IPayoutAdminRequest[];
    meta: IMeta;
  };
}

export type ProcessorType = 'dlocal' | 'circle';

interface IParams {
  page: number;
  limit: number;
  search: string;
  sortDirection: 'ASC' | 'DESC';
  sortBy: 'email' | 'firstName' | 'lastName';
  type: ProcessorType;
}

//? ADD FILE
export const addFile = (data: { file: any }, type: ProcessorType) => {
  const { formData, headers } = createFormData({ file: data.file });
  const path = type === 'dlocal' ? '/dLocal' : '';
  return apiClient
    .post<IResponseAxios>(ApiUrls.massPayouts + path, formData, {
      headers,
    })
    .then((response) => response);
};

//? GET LIST
export const getAdminPayoutRequests = (params: any) => {
  const query = createQueryParams<IParams>(params);

  return apiClient
    .get<IAdminPayoutRequest>(ApiUrls.massPayouts + `?${query}`)
    .then((response) => response);
};
