import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';

export interface INormalizedPayout extends IPayout {
  customerId: number;
}
export interface IPayout {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  method: string;
  state: string;
  postalCode: string;
  kyckUserId: string;
  address1: string;
  address2: string;
  city: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  taxIdType: string;
  taxId: string;
  paypalEmail: string;
  currency: string;
  bankName: string;
  accountType: string;
  routingNumber: string;
  accountNumber: string;
  accountHolderName: string;
  bankAddress: string;
  swiftCode: string;
  countryOfBirth: string;
  dateOfBirth: string;
  countryCode: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ICustomerData {
  id: number;
  payout: IPayout;
}

export interface IParams extends IPaginationParams {
  search: string;
  sortDirection: 'asc' | 'desc';
  sortBy: 'createdAt' | 'updatedAt' | 'method';
  method: 'PayPal' | 'ACH' | 'bankWire' | 'cash' | 'pushToCard';
}

export interface ICustomerPayoutListResponse {
  items: ICustomerData[];
  meta: IMeta;
}

const request = (params: IParams) => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get<ICustomerPayoutListResponse>(ApiUrls.customerPayoutList + `?${query}`)
    .then((response) => response);
};

export default request;
