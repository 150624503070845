export const USER_MIGRATIONS_DATE_FORMAT = 'MM.dd.yyyy';

export const USER_MIGRATIONS_FILE_NAME_DATE_FORMAT = 'MM/dd/yyyy';

export const FILE_SIZE = 5 * 1024;

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export enum IMAGE_UPLOAD_ENTITY {
  PLAN = 'plan',
  PRODUCT = 'product',
  COMBO_INFO = 'comboInfo',
  COMBO_PRODUCT = 'comboProduct',
  PRODUCT_FULL_IMAGE = 'productFullImage',
  COMBO_PRODUCT_FULL_IMAGE = 'comboProductFullImage',
}

export const AVAILABLE_REGIONAL_DATASET_FIELDS: { [key: string]: string[] } = {
  defaultFields: ['setupPrice', 'CV', 'QV', 'SCV', 'SQV', 'FSB'],
  retail: [
    'retailExigoItem',
    'retailPrice',
    'retailCV',
    'retailQV',
    'retailSCV',
    'retailSQV',
    'retailFSB',
  ],
  recurring: [
    'recurringSKU',
    'recurringPrice',
    'recurringCV',
    'recurringQV',
    'recurringSCV',
    'recurringSQV',
    'recurringFSB',
    'intervalSize',
  ],
  retailRecurring: [
    'recurringRetailPrice',
    'retailRecurringCV',
    'retailRecurringQV',
    'retailRecurringSCV',
    'retailRecurringSQV',
    'retailRecurringFSB',
  ],
};

export enum LEGAL_DOCUMENT_TYPE {
  PRIVACY_POLICY = 'privacy_policy',
  GDPR_POLICY = 'gdpr_policy',
  TERMS_OF_USE = 'terms_of_use',
  REFUND_POLICY = 'refund_policy',
  CANCELLATION_POLICY = 'cancellation_policy',
}
