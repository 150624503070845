import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { FormikProps, useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import InputSearch from '../../components/InputSearch';
import InputSelect from '../../components/InputSelect';
import EditableRow, { STATUSES_LEVEL_1, STATUSES_LEVEL_2 } from '../../components/KycTableRow';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import createKycSettingsLevel, {
  EKycLevels,
  ICreateKycSettingsRequest,
} from '../../services/api/requests/createKycSettingsLevel';
import editKycSettingsLevel from '../../services/api/requests/editKycSettingsLevel';
import getKycSettingsList, {
  IKycSettingsData,
} from '../../services/api/requests/getKycSettingsList';
import KYC, { IKycItem } from '../../services/api/requests/kyc';
import { IMeta } from '../../services/api/types';
import { selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';

const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: theme.spacing(2),
  },
}));

const headCells_1 = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Exigo ID', disableSort: true },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'First name',
    disableSort: true,
  },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last name', disableSort: true },
  {
    id: 'deadline',
    numeric: false,
    disablePadding: false,
    label: 'Deadline',
    disableSort: true,
  },
  {
    id: 'idScanStatus',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    disableSort: true,
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    disableSort: true,
  },
];

const headCells_2 = headCells_1.filter((item) => item.id !== 'deadline');

enum EKycLevelsText {
  level1 = 'Level 1',
  level2 = 'Level 2',
}

const kycGlobalSetingsOptions = [
  {
    value: EKycLevels.LVL1,
    label: EKycLevelsText.level1,
  },
  {
    value: EKycLevels.LVL2,
    label: EKycLevelsText.level2,
  },
];

const attemptsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => ({
  value: v,
  label: `${v}`,
}));

interface IValues extends Omit<ICreateKycSettingsRequest, 'enabled'> {
  enabled: boolean;
}

const initialValues = {
  level: EKycLevels.LVL1,
  attempts: 1,
  enabled: false,
};

const KycList: FC = () => {
  const localClasses = useStyles();
  const classes = useStandardTableStyles();
  const permissions = useSelector(selectUserPermissions);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [list, setList] = React.useState<IKycItem[] | []>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [sortDirection, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = React.useState<string>('name');
  const [kycSetings, setKycSetings] = React.useState<IKycSettingsData[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [level, setLevel] = React.useState<number>(1);
  const [verificationStatusFilter, selectVerificationStatusFilter] = React.useState<string>('all');

  const fetchKycSettingsList = () => {
    getKycSettingsList().then((response) => {
      if (response) {
        setLoading(false);
        setKycSetings(response.data.data);
      }
    });
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    if (permissions?.includes(Permissions.jumioVerificationListLevel1)) {
      // fetchList(); // TODO: uncomment later
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, level, searchTerm, verificationStatusFilter]);

  const edit = (values: IValues) => {
    editKycSettingsLevel({ ...values }, fkKycSettings.values.level).then((editRes) => {
      if (editRes) {
        setNotification('success', {
          message: 'Successfully changed',
        });
      }
    });
  };

  const fkKycSettings: FormikProps<IValues> = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: (values) => {
      const createData = {
        level: values.level,
        attempts: values.attempts,
        ...(!!values?.enabled ? { enabled: values.enabled } : {}),
      };
      const isLevelExist = kycSetings?.some((settings) => settings.level === values.level);

      isLevelExist
        ? edit(values)
        : createKycSettingsLevel(createData)
            .then((res) => {
              //@ts-ignore
              if (res?.data?.isExist) {
                edit(values);
                fetchKycSettingsList();
                return;
              }

              if (res) {
                fetchKycSettingsList();
                setNotification('success', {
                  message: 'Successfully created',
                });
              }
            })
            .catch((e) => console.log('debug-e createKycSettingsLevel', e));
    },
  });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    fetchKycSettingsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedLevel = kycSetings?.find((kyc) => kyc.level === fkKycSettings.values.level);
    fkKycSettings.setFieldValue('attempts', selectedLevel?.attempts || initialValues.attempts);
    fkKycSettings.setFieldValue('enabled', selectedLevel?.enabled || initialValues.enabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycSetings, fkKycSettings.values.level]);

  const fetchList = async () => {
    if (level === 1) {
      try {
        const response = await KYC.getList({
          page,
          limit: rowsPerPage,
          search: searchTerm,
          sortBy,
          idScanStatus: verificationStatusFilter,
          sortDirection: sortDirection.toUpperCase() as 'ASC' | 'DESC',
        });

        setList(response.data.data.items);
        setMeta(response.data.data.meta);
      } catch (e) {
        console.error('fetch KYC List error', e);
      } finally {
        setLoading(false);
      }
      return;
    }
    if (level === 2) {
      try {
        const response = await KYC.getListLevel2({
          page,
          limit: rowsPerPage,
          search: searchTerm,
          sortBy,
          verificationStatus: verificationStatusFilter,
          sortDirection: sortDirection.toUpperCase() as 'ASC' | 'DESC',
        });

        setLoading(false);
        setList(response.data.data.items);
        setMeta(response.data.data.meta);
      } catch (e) {
        console.error('fetch KYC List error', e);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
    selectVerificationStatusFilter('all');
    setPage(1);
    setLevel(newValue);
    setLoading(true);
    setList([]);
  };

  const updateKycUserInfo = async ({
    deadline,
    verificationStatus,
    idScanStatus,
    verificationId,
  }: IKycItem) => {
    try {
      if (level === 1) {
        await KYC.updateListItem({
          id: verificationId,
          skipDeadline: deadline,
          verificationStatus: verificationStatus as string,
          idScanStatus,
        });
      }

      if (level === 2) {
        await KYC.updateListItemLevel2({
          id: verificationId,
          verificationStatus: idScanStatus,
        });
      }

      setNotification('success', {
        message: 'Success',
      });

      fetchList();
    } catch (e) {
      console.error('UPDATE KYC ITEM ERROR', e);
    }
  };

  const handleRequestSort = (event: any, property: 'asc' | 'desc') => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const renderSecondTab = () => (
    <>
      <Grid container spacing={2} className={classes.searchRow}>
        <Grid item xs={12} sm={6}>
          <InputSearch
            onSubmit={(val) => {
              setPage(1);
              setSearchTerm(val);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputSelect
            id="verification"
            value={verificationStatusFilter}
            onChange={(e): void => {
              setList([]);
              selectVerificationStatusFilter(e.target.value as string);
            }}
            options={[...STATUSES_LEVEL_2, { value: 'all', label: 'All' }]}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead
            headCells={headCells_2}
            order={sortDirection}
            orderBy={sortBy}
            onRequestSort={handleRequestSort}
            classes={classes}
          />
          <TableBody>
            {loading && <TableSkeleton />}

            {!loading &&
              (list as IKycItem[]).map((item) => (
                <EditableRow onEditRow={updateKycUserInfo} kycData={item} level={2} />
              ))}

            {!loading && !list?.length && (
              <TableRow>
                <TableCell>There are no kyc users</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  // @ts-ignore
  const renderFirstTab = () => (
    <>
      <Grid container spacing={2} className={classes.searchRow}>
        <Grid item xs={12} sm={6}>
          <InputSearch
            onSubmit={(val) => {
              setPage(1);
              setSearchTerm(val);
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <InputSelect
            id="verification"
            value={verificationStatusFilter}
            onChange={(e): void => {
              setList([]);
              selectVerificationStatusFilter(e.target.value as string);
            }}
            options={[...STATUSES_LEVEL_1, { value: 'all', label: 'All' }]}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead
            headCells={headCells_1}
            order={sortDirection}
            orderBy={sortBy}
            onRequestSort={handleRequestSort}
            classes={classes}
          />
          <TableBody>
            {loading && <TableSkeleton />}

            {!loading &&
              (list as IKycItem[]).map((item) => (
                <EditableRow onEditRow={updateKycUserInfo} kycData={item} level={1} />
              ))}

            {!loading && !list?.length && (
              <TableRow>
                <TableCell>There are no kyc users</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const renderKycSettings = () => (
    <>
      <FormControlLabel
        labelPlacement="start"
        label={<Typography className={localClasses.label}>KYC level:</Typography>}
        control={
          <InputSelect
            id="level"
            options={kycGlobalSetingsOptions}
            value={fkKycSettings.values.level}
            onChange={(e): void => {
              fkKycSettings.setFieldValue('level', e.target.value);
            }}
          />
        }
      />
      <FormControlLabel
        labelPlacement="start"
        label={<Typography className={localClasses.label}>Attempts:</Typography>}
        control={
          <InputSelect
            id="attempts"
            options={attemptsOptions}
            value={fkKycSettings.values.attempts}
            onChange={(e): void => {
              fkKycSettings.setFieldValue('attempts', e.target.value);
            }}
          />
        }
      />
      <Grid item xs={2}>
        <FormControlLabel
          labelPlacement="start"
          label="Enabled: "
          control={
            <Switch
              id={'enabled'}
              checked={fkKycSettings.values.enabled}
              onChange={fkKycSettings.handleChange}
              name="enabled"
              color="primary"
            />
          }
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => fkKycSettings.handleSubmit()}
        >
          Save
        </Button>
      </Grid>
    </>
  );

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>KYC</Title>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>KYC Level Settings: </Title>
            {renderKycSettings()}
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <Tabs
          value={level}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabClick}
          aria-label="disabled tabs example"
        >
          <Tab label="Level 1" value={1} disabled={loading} />
          <Tab label="Level 2" value={2} disabled={loading} />
        </Tabs>

        {level === 1 && renderFirstTab()}
        {level === 2 && renderSecondTab()}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default KycList;
