import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch } from 'react-redux';

interface IProps {
  link: string;
  name: string;
  margin?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: (props: IProps) => (props.margin ? props.margin : theme.spacing(3, 2, 2)),
    transition: 'transform 0.2s ease-out',
    cursor: 'pointer',
    '&:hover': {
      '& svg': {
        transform: 'rotate( 180deg) translateX(10px)',
      },
      color: theme.palette.primary.main,
    },
  },
  arrow: {
    transition: 'transform 0.2s ease-out',
    transform: 'rotate( 180deg )',
    margin: theme.spacing(0, 1, 0),
  },
}));
const BackButton: React.FC<IProps> = (props) => {
  const { link, name } = props;

  const classes = useStyles(props);
  const dispatch = useDispatch();
  const handleClick = () => dispatch(push(link));

  return (
    <div className={classes.container} onClick={handleClick}>
      <ArrowRightAltIcon className={classes.arrow} />
      {name}
    </div>
  );
};

export default BackButton;
