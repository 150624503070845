import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IUpdateSettingValue {
  id: number;
  value: string;
}

const request = (data: IUpdateSettingValue) => {
  const { id, value } = data;
  const url = ApiUrls.updateSettingValue.replace('{id}', `${id}`);

  return apiClient.put<IResponseAxios>(url, { value }).then((response) => response);
};

export default request;
