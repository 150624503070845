import DateFnsUtils from '@date-io/date-fns';
import { Button, CssBaseline, Grid, Paper, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { DateRangePicker } from 'react-date-range';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import createPayoutRequest from '../../services/api/requests/createPayoutRequest';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({});

interface IValues {
  method: 'all' | 'PayPal' | 'ACH' | 'bankWire' | 'cash' | 'pushToCard';
  created_from: string;
  created_to: string;
  edited_from: string;
  edited_to: string;
}

const initialValues: IValues = {
  method: 'all',
  created_from: '',
  created_to: '',
  edited_from: '',
  edited_to: '',
};

const CreateEditInstance: React.FC = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();
  const actionText = 'Create';

  const fk: FormikProps<IValues> = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { created_from, created_to, edited_from, edited_to, method } = values;

      const methodParam = method !== 'all' ? { method } : {};

      const createData = {
        ...methodParam,
        created_from: created_from ? dateFns.format(new Date(created_from), 'yyyy-MM-dd') : null,
        created_to: created_to ? dateFns.format(new Date(created_to), 'yyyy-MM-dd') : null,
        edited_from: edited_from ? dateFns.format(new Date(edited_from), 'yyyy-MM-dd') : null,
        edited_to: edited_to ? dateFns.format(new Date(edited_to), 'yyyy-MM-dd') : null,
      };

      createPayoutRequest(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.csvReport));
        }
      });
    },
  });

  const { created_from, created_to, edited_from, edited_to } = fk.values;

  const createInputValue = (fromDate: string, toDate: string) =>
    fromDate && toDate && dateFns.isValid(fromDate) && dateFns.isValid(toDate)
      ? `${dateFns.format(new Date(fromDate), 'MMM d, yyyy')} - ${dateFns.format(
          new Date(toDate),
          'MMM d, yyyy'
        )}`
      : '';

  const isString = (value: any) => typeof value === 'string';
  const disabledCreateDates = !isString(edited_from) && !isString(edited_from);
  const disabledUpdateDates = !isString(created_from) && !isString(created_to);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.csvReport} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{`${actionText} Report`}</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputSelect
                  id="method"
                  label={'Payment Method'}
                  value={fk.values.method}
                  onChange={(e): void => fk.setFieldValue('method', e.target.value)}
                  options={[
                    { value: 'all', label: 'All' },
                    { value: 'PayPal', label: 'PayPal' },
                    { value: 'ACH', label: 'ACH' },
                    { value: 'bankWire', label: 'bankWire' },
                    { value: 'cash', label: 'cash' },
                    { value: 'pushToCard', label: 'pushToCard' },
                  ]}
                />
              </Grid>

              <Grid item xs={12}>
                <Modal
                  button={
                    <TextField
                      autoComplete="new-password"
                      variant="outlined"
                      fullWidth
                      required={!disabledCreateDates}
                      type={'text'}
                      onClick={(e) => (disabledCreateDates ? e.stopPropagation() : null)}
                      label={'Date Created interval'}
                      disabled={disabledCreateDates}
                      value={createInputValue(created_from, created_to)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                fk.setValues(
                                  {
                                    ...fk.values,
                                    created_from: '',
                                    created_to: '',
                                  },
                                  false
                                );
                              }}
                              edge="end"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  renderContent={(onClose) => (
                    <>
                      <DateRangePicker
                        onChange={({ selection }: any) => {
                          fk.setValues(
                            {
                              ...fk.values,
                              created_from: selection.startDate || created_from,
                              created_to: selection.endDate || created_to,
                            },
                            false
                          );
                        }}
                        twoStepChange={true}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={true}
                        months={2}
                        ranges={[
                          {
                            startDate: created_from ? new Date(created_from) : undefined,
                            endDate: created_to ? new Date(created_to) : undefined,
                            key: 'selection',
                          },
                        ]}
                        direction="horizontal"
                      />

                      <IconButton onClick={onClose} edge="end">
                        <CloseIcon />
                      </IconButton>
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Modal
                  button={
                    <TextField
                      autoComplete="new-password"
                      variant="outlined"
                      fullWidth
                      type={'text'}
                      required={!disabledUpdateDates}
                      label={'Date Updated interval'}
                      disabled={disabledUpdateDates}
                      onClick={(e) => (disabledUpdateDates ? e.stopPropagation() : null)}
                      value={createInputValue(edited_from, edited_to)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                fk.setValues(
                                  {
                                    ...fk.values,
                                    edited_from: '',
                                    edited_to: '',
                                  },
                                  false
                                );
                              }}
                              edge="end"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  renderContent={(onClose) => (
                    <>
                      <DateRangePicker
                        onChange={({ selection }: any) => {
                          fk.setValues(
                            {
                              ...fk.values,
                              edited_from: selection.startDate || edited_from,
                              edited_to: selection.endDate || edited_to,
                            },
                            false
                          );
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={true}
                        months={2}
                        ranges={[
                          {
                            startDate: edited_from ? new Date(edited_from) : undefined,
                            endDate: edited_to ? new Date(edited_to) : undefined,
                            key: 'selection',
                          },
                        ]}
                        direction="horizontal"
                      />
                      <IconButton onClick={onClose} edge="end">
                        <CloseIcon />
                      </IconButton>
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {actionText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditInstance;
