import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { INSTANCE_TYPES } from './getInstances';

export interface ICreateInstance {
  code: string;
  name: string;
  countryCodes: string[];
  baseCountryCode: string;
  defaultBaseUrl: string;
  companyName: string;
  websiteId: string;
  priority: string | number;
  type: INSTANCE_TYPES;
}

const request = (data: ICreateInstance) =>
  apiClient.post<any>(ApiUrls.createInstance, data).then((response) => response);

export default request;
