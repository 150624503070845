import { useSelector } from 'react-redux';
import { ICountryGroup } from '../services/api/requests/getCountries';
import { ICountry, IGetInstance } from '../services/api/requests/getInstances';
import {
  selectCountryGroups,
  selectCountryList,
  selectInstances,
} from '../store/entities/selectors';
import { onlyUnique } from '../utils/helpers';

// helpers to create list for request
export function createCountryCodeList(
  selectedValues: string[],
  instances: IGetInstance[],
  groups?: ICountryGroup[]
) {
  let countries: string[] = [];

  if (selectedValues.includes('all')) {
    instances.forEach((instance) => {
      if (instance.countries.length) {
        instance.countries.forEach((country: ICountry) => countries.push(country.code));
      }
    });
    return { countries };
  }

  selectedValues.forEach((value: string) => {
    const instance = instances.find((inst) => inst.code === value);

    const group = groups?.find((gr) => gr.id.toString() === value.toString());

    if (instance) {
      countries.push(...instance.countries.map((country) => country.code));
    } else if (group) {
      countries.push(...group.countries.map((country) => country.code));
    } else {
      countries.push(value);
    }
  });
  const uniqueCountries = countries.filter(onlyUnique);
  return { countries: uniqueCountries };
}

// helpers to create list countries with instances from server country codes list
export default function useCountryOption(
  countriesValue: string[] | string,
  withInstances: boolean,
  availableOptions: any,
  withGroups: boolean
) {
  const instances = useSelector(selectInstances);
  const allCountries = useSelector(selectCountryList(withInstances, withGroups));
  const countryGroups = useSelector(selectCountryGroups);

  const countryOptions = (Object.values(allCountries) as ICountry[]).map((country) => ({
    label: country.name,
    value: country.code,
  }));

  if (!Array.isArray(countriesValue)) {
    return {
      selected: countryOptions.find((i) => i.value === countriesValue),
      options: countryOptions,
    };
  }

  let notFound: string[] = [...countriesValue];
  let selected: any = [];

  if (withInstances) {
    instances.forEach((instance) => {
      if (instance.countries.length > 0 && notFound.length >= instance.countries.length) {
        const foundInInstance: string[] = [];
        for (let i = 0; i <= instance.countries.length; i++) {
          const code = instance.countries[i]?.code;
          if (notFound.includes(code)) {
            foundInInstance.push(code);
          }
          if (i === instance.countries.length) {
            // if found
            if (foundInInstance.length === instance.countries.length) {
              selected.push({ label: instance.name, value: instance.code });
              notFound = notFound.filter((item) => !foundInInstance.includes(item));
            }
          }
        }
      }
    });
  }

  if (withGroups && countryGroups?.length) {
    countryGroups.forEach((group) => {
      if (group.countries.length > 0) {
        const foundInGroup: string[] = [];
        for (let i = 0; i <= group.countries.length; i++) {
          const code = group.countries[i]?.code;
          if (notFound.includes(code)) {
            foundInGroup.push(code);
          }
          if (i === group.countries.length) {
            // if found
            if (foundInGroup.length === group.countries.length) {
              selected.push({ label: group.name, value: group.id });
              notFound = notFound.filter((item) => !foundInGroup.includes(item));
            }
          }
        }
      }
    });
  }

  notFound.forEach((code) => {
    const data = allCountries && allCountries[code];
    if (data) {
      selected.push({ value: data.code, label: data.name });
    }
  });

  console.warn('111', availableOptions);

  let options = availableOptions
    ? countryOptions.filter((item) => availableOptions.includes(item.value))
    : countryOptions.filter((item) => !notFound.includes(item.value));

  return { selected, options };
}
