import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta } from '../types';
import { ICoupon } from './createCoupon';

export interface IParams {
  page: number;
  limit: number;
  sortDirection: 'ASC' | 'DESC';
  couponTypeFilter: string;
  discountTypeFilter: string;
  publishedFilter: string;
  activeFilter: string;
  autogenerated: string;
}

export interface IAdminCouponResponse {
  data: {
    items: ICoupon[];
    meta: IMeta;
  };
}

const request = (params: IParams) => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get<IAdminCouponResponse>(ApiUrls.couponList + `?${query}`)
    .then((response) => response);
};

export default request;
