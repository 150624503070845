import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useStandardTableStyles } from '../../components/DefaultTable';
import AdminPermissionGroups from '../AdminPermissionGroups';
import AdminPermissionRoles from '../AdminPermissionRoles';

export default function EnhancedTable() {
  const classes = useStandardTableStyles();
  const [tabIdx, setTabIdx] = React.useState<number>(0);

  return (
    <div className={classes.root}>
      <Tabs
        value={tabIdx}
        indicatorColor="primary"
        className={classes.marginBottom}
        textColor="primary"
        onChange={(e: any, newInx: number) => {
          setTabIdx(newInx);
        }}
        aria-label="disabled tabs example"
      >
        <Tab label="Roles" />
        <Tab label="Groups" />
      </Tabs>

      {tabIdx === 0 ? <AdminPermissionRoles /> : <AdminPermissionGroups />}
    </div>
  );
}
