import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';

const TableSkeleton: React.FC = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
        <TableRow key={item}>
          <TableCell align="left">
            <Skeleton width={'50px'} />
          </TableCell>
          <TableCell>
            <Skeleton width={'70px'} />
          </TableCell>
          <TableCell>
            <Skeleton width={'70px'} />
          </TableCell>
          <TableCell>
            <Skeleton width={'400px'} />
          </TableCell>
          <TableCell>
            <Skeleton width={'30px'} />
          </TableCell>
          <TableCell>
            <Skeleton width={'30px'} />
          </TableCell>
          <TableCell>
            <Skeleton width={'30px'} />
          </TableCell>
          <TableCell>
            <Skeleton width={'30px'} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TableSkeleton;
