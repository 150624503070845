import { SUBSCRIPTION_STATUS } from '../../../utils/commonTypes';
import { normalizePurchaseRulesData } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IPlan } from './getProductPlans';

export interface IPurchaseRule {
  id: number;
  shouldHaveStatus: SUBSCRIPTION_STATUS;
  createdAt: Date;
  updatedAt: Date;
  requiredPlan: IPlan;
}

export interface IPurchaseRuleSet {
  id: number;
  purchaseRules: IPurchaseRule[];
}

export interface INormalizedPurchaseRule {
  id: number;
  shouldHaveStatus: SUBSCRIPTION_STATUS;
  createdAt: Date;
  updatedAt: Date;
  requiredPlan: IPlan;
  requiredPlanName: string;
}

export interface INormalizedPurchaseRuleSet {
  id: number;
  purchaseRules: INormalizedPurchaseRule[];
}

const request = (id: number) => {
  const url = ApiUrls.readPurchaseRules.replace('{id}', `${id}`);
  return apiClient
    .get<IResponseAxios>(url)
    .then((response) => normalizePurchaseRulesData(response.data.data));
};

export default request;
