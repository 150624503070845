import { ITokenData, ITokenRequest } from '../../services/api/requests/getToken';

export const CHANGE_THEME = 'CHANGE_THEME';
export const SET_LOADER = 'SET_LOADER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT = 'LOGOUT';

export interface ChangeThemeAction {
  type: typeof CHANGE_THEME;
  payload: string;
}

export interface SetLoaderAction {
  type: typeof SET_LOADER;
  payload: boolean;
}

export interface LogInAction {
  type: typeof LOGIN_USER;
  payload: ITokenRequest;
}
export interface LogInSuccessAction {
  type: typeof LOGIN_USER_SUCCESS;
  payload: ITokenData;
}

export interface LogOutAction {
  type: typeof LOGOUT;
}

export interface SystemState {
  readonly loading: boolean;
  readonly activeTheme: string;
  readonly userData: ITokenData | null;
}

export type ActionTypes =
  | ChangeThemeAction
  | SetLoaderAction
  | LogOutAction
  | LogInAction
  | LogInSuccessAction;
