import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { ICreateSetting } from './createSetting';

export interface IEditSetting extends ICreateSetting {
  id: number;
}

const request = (data: IEditSetting) => {
  return apiClient
    .put<IResponseAxios>(ApiUrls.editSetting + data.id, data)
    .then((response) => response);
};

export default request;
