import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import deleteCountriesGroup from '../../services/api/requests/deleteCountriesGroup';
import {
  getCountriesGroupWithPagination,
  ICountryGroup,
} from '../../services/api/requests/getCountries';
import { ICountry } from '../../services/api/requests/getInstances';
import { IMeta } from '../../services/api/types';
import { saveCountryGroups } from '../../store/entities/actions';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const headCells = [
  {
    id: 'group',
    numeric: false,
    disablePadding: false,
    label: 'Group',
    disableSort: true,
  },
  {
    id: 'countries',
    numeric: false,
    disablePadding: false,
    label: 'Countries',
    disableSort: true,
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Updated at',
    disableSort: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created at',
    disableSort: true,
  },
  { id: 'edit', numeric: false, disablePadding: false, label: '', disableSort: true },
  { id: 'delete', numeric: false, disablePadding: false, label: '', disableSort: true },
];

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [countriesGroupList, setList] = React.useState<ICountryGroup[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const fetchCountriesGroupsList = async () => {
    try {
      const response = await getCountriesGroupWithPagination({
        page,
        limit: rowsPerPage,
      });
      setLoading(false);
      setList(response.data.groups);
      dispatch(saveCountryGroups(response.data.groups));
      // setMeta(response.data.meta);
    } catch (e) {
      console.warn(e.message);
    }
  };
  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchCountriesGroupsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleDelete = async (id: number) => {
    try {
      await deleteCountriesGroup(id);
      setNotification('success', {
        message: 'Success',
      });
      await fetchCountriesGroupsList();
    } catch (e) {
      console.warn(e.message);
    }
  };

  const handleEdit = (item: ICountryGroup) =>
    dispatch(push(`${pageLinks.editCountriesGroup}/${item.id}`));

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Countries Groups</Title>
            <Can perform={Permissions.createCountriesGroup}>
              <Button
                onClick={() => dispatch(push(pageLinks.createCountriesGroup))}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add country group
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead headCells={headCells} classes={classes} />
          <TableBody>
            {loading && <TableSkeleton />}
            {!loading && !countriesGroupList?.length ? (
              <TableRow>
                <TableCell>There are no countries group</TableCell>
              </TableRow>
            ) : (
              countriesGroupList.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell align={'left'}>{item.name}</TableCell>
                    <TableCell align={'left'}>
                      {
                        <Box display="flex" flexWrap="wrap">
                          {item.countries.map((country: ICountry, idx: number, arr: ICountry[]) => (
                            <Tooltip title={country.name} key={country.code}>
                              <Box m={0.5} display="flex" flexWrap="wrap">{`${country.code}${
                                arr.length - 1 === idx ? '' : ', '
                              }`}</Box>
                            </Tooltip>
                          ))}
                        </Box>
                      }
                    </TableCell>
                    <TableCell>
                      {dateFns.format(new Date(item.updatedAt), 'dd/MM/yyyy hh:mm')}
                    </TableCell>
                    <TableCell>
                      {dateFns.format(new Date(item.createdAt), 'dd/MM/yyyy hh:mm')}
                    </TableCell>
                    <TableCell align="center">
                      <Can perform={Permissions.editCountriesGroup}>
                        <IconButton
                          aria-label="edit"
                          title={'Edit'}
                          onClick={() => handleEdit(item)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Can>
                    </TableCell>
                    <TableCell align="center">
                      <Can perform={Permissions.deleteCountriesGroup}>
                        <DeleteModalButton
                          name={item.name}
                          entity={'countries group'}
                          onDelete={() => handleDelete(item.id)}
                        />
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default EnhancedTable;
