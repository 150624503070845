import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { ICreateKycSettingsRequest } from './createKycSettingsLevel';

export interface IKycSettingsData extends ICreateKycSettingsRequest {}

export interface IKycSettingsDataResponse {
  data: IKycSettingsData[];
}

const request = () => {
  return apiClient
    .get<IKycSettingsDataResponse>(ApiUrls.kycSettingsList)
    .then((response) => response);
};

export default request;
