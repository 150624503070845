import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { all, call, put, select, spawn, takeLatest, takeLeading } from 'redux-saga/effects';
import { Permissions } from '../components/Can';
import getAdminRoles, { IAdminRolesResponse } from '../services/api/requests/getAdminRoles';
import {
  getCountries,
  getCountryGroup,
  ICountry,
  ICountryGroupResponse,
} from '../services/api/requests/getCountries';
import getInstances, { IGetInstance } from '../services/api/requests/getInstances';
import getPaymentMethodList, {
  IPaymentMethodResponse,
} from '../services/api/requests/getPaymentMethodList';
import getProducts, { IProductsResponse } from '../services/api/requests/getProducts';
import getServices, { IServiceResponse } from '../services/api/requests/getServices';
import makeUserDetailsRequest from '../services/api/requests/getUserDetails';
import { getPermissions, IPermissionsResponse } from '../services/api/requests/permissions';
import syncServices from '../services/api/requests/syncServices';
import * as EntityActions from '../store/entities/actions';
import {
  saveCountries,
  saveCountryGroups,
  saveInstances,
  savePaymentMethods,
  saveServices,
  setAdminRoles,
  setPermissionGroups,
  setPermissions,
  setProducts,
  setUserDetails,
} from '../store/entities/actions';
import { setLoader } from '../store/system/actions';
import { selectUserPermissions } from '../store/system/selectors';
import onAxiosError from '../utils/errorHandler';
import { isUserLogged } from '../utils/helpers';

const requestsWithPermissions = {
  [Permissions.readProductList]: [fetchProductsWorker],
  [Permissions.readAdminRoleList]: [fetchPermissionsWorker, fetchAdminRolesWorker],
  [Permissions.readInstanceList]: [
    fetchCountriesWorker,
    fetchInstanceListWorker,
    fetchCountryGroupsWorker,
  ],
  [Permissions.readPayments]: [fetchPaymentMethodsWorker],
};

function* fetchStartDataWorker() {
  if (isUserLogged()) {
    const permissions = yield select(selectUserPermissions);
    const data = Object.entries(requestsWithPermissions).reduce((acc: any, [key, value]) => {
      if (permissions?.includes(key)) {
        acc = [...acc, ...value];
      }
      return acc;
    }, []);
    yield all([spawn(fetchServicesWorker), ...data.map((item: any) => spawn(item))]);
  } else {
  }
}

function* fetchProductsWorker() {
  try {
    const productResponse: AxiosResponse<IProductsResponse> = yield getProducts();
    yield put(setProducts(productResponse?.data?.data));
  } catch (e) {}
}

function* fetchPermissionsWorker() {
  try {
    const permissionsResponse: AxiosResponse<IPermissionsResponse> = yield call(getPermissions);
    yield put(setPermissions(permissionsResponse.data.permissions));
    yield put(setPermissionGroups(permissionsResponse.data.groups));
  } catch (e) {}
}

function* fetchCountriesWorker() {
  try {
    const countries: AxiosResponse<ICountry[]> = yield call(getCountries);
    yield put(saveCountries(countries.data));
  } catch (e) {}
}

function* fetchCountryGroupsWorker() {
  try {
    const response: AxiosResponse<ICountryGroupResponse> = yield call(getCountryGroup);
    yield put(saveCountryGroups(response.data.data.groups));
  } catch (e) {}
}

function* fetchServicesWorker() {
  try {
    const services: AxiosResponse<IServiceResponse> = yield call(getServices);
    yield put(saveServices(services.data.data));
  } catch (e) {}
}

function* synchronizeWorker() {
  try {
    yield call(syncServices);
  } catch (e) {}
}

function* fetchPaymentMethodsWorker() {
  try {
    const paymentMethods: AxiosResponse<IPaymentMethodResponse> = yield call(getPaymentMethodList, {
      page: 1,
      limit: 100,
    });

    yield put(savePaymentMethods(paymentMethods.data.data.items));
  } catch (e) {}
}

function* fetchInstanceListWorker() {
  try {
    const response: AxiosResponse<IGetInstance[]> = yield call(getInstances);
    yield put(saveInstances(response.data));
  } catch (e) {}
}

function* fetchAdminRolesWorker() {
  try {
    const response: AxiosResponse<IAdminRolesResponse> = yield call(getAdminRoles);
    yield put(setAdminRoles(response.data.data));
  } catch (e) {}
}

function* getUserDetails({ payload }: PayloadAction<number>) {
  yield put(setLoader(true));
  try {
    let user = null;
    if (payload) {
      const response: AxiosResponse = yield call(makeUserDetailsRequest, payload);
      user = response.data.data;
    }
    yield put(setUserDetails(user));
    yield put(setLoader(false));
  } catch (e) {
    onAxiosError(e);
    yield put(setLoader(false));
  }
}

function* entitiesSaga() {
  yield spawn(fetchStartDataWorker);
  yield all([
    takeLatest(EntityActions.syncServices.type, synchronizeWorker),
    takeLatest(EntityActions.fetchCountries.type, fetchCountriesWorker),
    takeLatest(EntityActions.fetchStartData.type, fetchStartDataWorker),
    takeLatest(EntityActions.fetchAdminRoles.type, fetchAdminRolesWorker),
    takeLatest(EntityActions.fetchPermissions.type, fetchPermissionsWorker),
    takeLatest(EntityActions.updateInstanceList.type, fetchInstanceListWorker),
    takeLeading(EntityActions.fetchUserDetails.type, getUserDetails),
  ]);
}

export default entitiesSaga;
