import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import { IDocumentBody } from '../../services/api/requests/addLegalDocument';
import deleteLegalDocument from '../../services/api/requests/deleteLegalDocument';
import getLegalDocuments, { IDocument } from '../../services/api/requests/getLegalDocuments';
import { IMeta } from '../../services/api/types';
import { editLegalDocument } from '../../store/entities/actions';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID', disableSort: true },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type', disableSort: false },

  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    disableSort: false,
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Date updated',
    disableSort: true,
  },

  {
    id: 'instances',
    numeric: false,
    disablePadding: false,
    label: 'Instances',
    disableSort: false,
  },
  { id: 'country', numeric: false, disablePadding: false, label: 'Countries', disableSort: false },
  { id: 'url', numeric: false, disablePadding: false, label: 'Url', disableSort: false },
];

const LegalDocuments = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [list, setList] = React.useState<IDocument[] | null>(null);

  const [sortDirection, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = React.useState<string>('name');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchListAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sortBy, sortDirection]);

  const fetchListAsync = async () => {
    try {
      const response = await getLegalDocuments({
        page,
        limit: rowsPerPage,
        sortDirection: sortDirection.toUpperCase() as 'ASC' | 'DESC',
        sortBy,
      });

      setList(response.data.data.items);
      setMeta(response.data.data.meta);

      setLoading(false);
    } catch (e) {
      console.warn('e', e);
    }
  };

  const createLegalDocument = () => {
    dispatch(editLegalDocument(null));
    dispatch(push(pageLinks.createEditLegalDocument));
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDeleteAsync = async (id: number) => {
    try {
      await deleteLegalDocument(id);
      setNotification('success', {
        message: 'Success',
      });
      fetchListAsync();
    } catch (err) {
      console.warn('err', err);
    }
  };

  const handleRequestSort = (event: any, property: 'asc' | 'desc') => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleEdit = ({ url, countries, instances, type, id }: IDocument) => {
    const countryCodes = countries.map((country) => country.code);
    const instanceCodes = instances.map((country) => country.code);

    const data: IDocumentBody = {
      url,
      countryCodes,
      instanceCodes,
      type,
    };

    dispatch(editLegalDocument(data));
    dispatch(push(pageLinks.createEditLegalDocument + `?id=${id}`));
  };

  const renderInstancesTable = () => (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={'medium'}
        aria-label="enhanced table"
      >
        <CustomTableHead
          headCells={headCells}
          classes={classes}
          onRequestSort={handleRequestSort}
          order={sortDirection}
          orderBy={sortBy}
        />
        <TableBody>
          {loading && <TableSkeleton />}

          {!loading &&
            list?.map((item: IDocument) => {
              const { id, countries, instances, createdAt, updatedAt, type, url } = item;

              const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
              const update = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');

              return (
                <TableRow key={id}>
                  <TableCell align="left">{id}</TableCell>
                  <TableCell align="left">{type}</TableCell>
                  <TableCell align="left">{create}</TableCell>
                  <TableCell align="left">{update}</TableCell>
                  <TableCell align="left">{instances?.map((i) => i.code).join(', ')}</TableCell>
                  <TableCell align="left">
                    <Box display="flex" flexWrap="wrap">
                      {countries.map((country: any, idx: number, arr: any) => (
                        <Tooltip title={country.name} key={country.code}>
                          <Box m={0.5} display="flex" flexWrap="wrap">{`${country.code}${
                            arr.length - 1 === idx ? '' : ', '
                          }`}</Box>
                        </Tooltip>
                      ))}
                    </Box>
                  </TableCell>

                  <TableCell align="left">{url}</TableCell>

                  <TableCell align="left">
                    <Can perform={Permissions.updateInstance}>
                      <IconButton aria-label="edit" title={'Edit'} onClick={() => handleEdit(item)}>
                        <EditIcon />
                      </IconButton>
                    </Can>
                  </TableCell>
                  <TableCell align="left">
                    <Can perform={Permissions.deleteInstance}>
                      <DeleteModalButton
                        name={type}
                        entity={'Document'}
                        onDelete={() => handleDeleteAsync(id)}
                      />
                    </Can>
                  </TableCell>
                </TableRow>
              );
            })}

          {!loading && !list?.length && (
            <TableRow>
              <TableCell>There are no items</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Legal Documents</Title>
            <Can perform={Permissions.createInstance}>
              <Button
                onClick={createLegalDocument}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add Item
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        {renderInstancesTable()}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default LegalDocuments;
