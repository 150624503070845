import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';
import { IGetInstance } from './getInstances';

export interface ICountry {
  id: number;
  code: string;
  name: string;
  customBaseUrl: string;
  customCompanyName: string;
  phoneVerificationEnabled: boolean;
}

export interface IInstanceResponse {
  data: {
    items: IGetInstance[];
    meta: IMeta;
  };
}

const request = ({ page, limit }: IPaginationParams) => {
  const query = `?page=${page}&limit=${limit}`;
  return apiClient
    .get<IInstanceResponse>(ApiUrls.instanceList + query)
    .then((response) => response);
};

export default request;
