import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import icoMoonList from '../../utils/icons';

const useStyles = makeStyles({
  option: {
    display: 'flex',
    '& i': {
      margin: '0 5px',
    },
  },
});

interface IProps {
  value: string;
  id: string;
  required?: boolean;
  onChange(a: string, b: string | string[], c?: boolean): void;
  error?: boolean;
  onBlur?(e: React.ChangeEvent<HTMLInputElement>): void;
  helperText?: string;
  availableOptions?: string[];
}

const IconSelect: React.FC<IProps> = ({
  value,
  id,
  availableOptions = null,
  onChange,
  ...rest
}) => {
  const classes = useStyles();

  const onSelectChange = (e: any, value: string | null): void => {
    onChange(id, value || '');
  };

  const selectedValue = value || (icoMoonList as string[])[0];

  return (
    <Autocomplete
      id={id}
      options={icoMoonList as string[]}
      value={selectedValue}
      onChange={onSelectChange}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      multiple={false}
      fullWidth
      getOptionLabel={(option) => option}
      renderOption={(option) => (
        <div className={classes.option}>
          <i className={`icon-${option}`} />
          {option}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Label Icon"
          variant="outlined"
          fullWidth
          required={rest.required}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default IconSelect;
