import { AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import getComboProductPlans from '../services/api/requests/getComboProductPlans';
import getCouponById from '../services/api/requests/getCouponById';
import getPlanById from '../services/api/requests/getPlanById';
import { IPlan } from '../services/api/requests/getProductPlans';
import * as EntityActions from '../store/entities/actions';
import { editCombo, editCouponData, editPlan } from '../store/entities/actions';
import {
  selectComboForEditing,
  selectCouponForEditing,
  selectPlanForEditing,
} from '../store/entities/selectors';
import { getSearchParam } from '../utils/helpers';
import pageLinks from '../utils/pageLinks';

function* editPlanWorker() {
  const planForEditing = yield select(selectPlanForEditing);
  if (planForEditing) {
    return;
  }

  const productId = getSearchParam('productId');
  const planId = getSearchParam('planId');

  const fullPlanData: AxiosResponse = yield call(getPlanById, {
    productId: Number(productId),
    planId: Number(planId),
  });

  const data: { plans: IPlan[]; editPlanId: number | null } = {
    plans: [fullPlanData.data.data],
    editPlanId: Number(planId),
  };

  yield put(editPlan(data));
}

function* editComboWorker() {
  const comboForEditing = yield select(selectComboForEditing);
  if (comboForEditing) {
    return;
  }

  const productId = getSearchParam('productId');

  if (productId) {
    const response: AxiosResponse = yield call(getComboProductPlans, productId);
    yield put(editCombo(response.data.data));
  }
}

function* editCouponWorker() {
  const couponForEditing = yield select(selectCouponForEditing);
  if (couponForEditing) {
    return;
  }

  const couponId = getSearchParam('couponId');

  if (couponId) {
    const response: AxiosResponse = yield call(getCouponById, couponId);
    yield put(editCouponData(response.data.data));
  }
}

function* urlDataWorker() {
  const pathname = window.location.pathname;

  switch (true) {
    case pathname.includes(pageLinks.createEditPlan): {
      yield call(editPlanWorker);
      return;
    }
    case pathname.includes(pageLinks.createEditComboProduct): {
      yield call(editComboWorker);
      return;
    }
    case pathname.includes(pageLinks.createEditCoupon): {
      yield call(editCouponWorker);
      return;
    }
    default: {
      return;
    }
  }
}

export default function* urlDataSaga() {
  yield takeLatest(EntityActions.fetchStartDataByUrl.type, urlDataWorker);
}
