import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface ICreateSetting {
  options: string[];
  name: string;
  value?: string;
  code: string;
}

const request = (data: ICreateSetting) =>
  apiClient.post<IResponseAxios>(ApiUrls.createSetting, data).then((response) => response);

export default request;
