import DateFnsUtils from '@date-io/date-fns';
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import BackButton from '../../components/BackButton';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import Title from '../../components/Title';
import { selectPayoutRequestList } from '../../store/entities/selectors';
import pageLinks from '../../utils/pageLinks';

const dateFns = new DateFnsUtils();

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID', disableSort: true },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email', disableSort: true },
  {
    id: 'circlePayoutId',
    numeric: false,
    disablePadding: false,
    label: 'Circle Payout Id',
    disableSort: true,
  },
  { id: 'create', numeric: false, disablePadding: false, label: 'Date Created' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
  { id: 'sheet', numeric: false, disablePadding: false, label: 'Sheet' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'errorReason', numeric: false, disablePadding: false, label: 'Error' },
];

const MassPayoutDetails: FC<{}> = () => {
  const classes = useStandardTableStyles();
  const payouts = useSelector(selectPayoutRequestList);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} direction="column">
        <BackButton name={'Back'} link={pageLinks.massPaymentRequests} margin={'0 0 10px 0'} />

        <Title>Payout Details</Title>

        <Grid item xs={12}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <CustomTableHead
                classes={classes}
                order={'asc'}
                orderBy={'sortBy'}
                headCells={headCells}
                onRequestSort={() => {}}
              />

              <TableBody>
                {payouts?.map((item) => {
                  const {
                    amount,
                    circlePayoutId,
                    createdAt,
                    email,
                    errorReason,
                    id,
                    sheet,
                    status,
                  } = item;

                  const create =
                    createdAt && dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');

                  return (
                    <TableRow key={id}>
                      <TableCell align="left">{id || '------'}</TableCell>
                      <TableCell align="left">{email || '------'}</TableCell>
                      <TableCell align="left">{circlePayoutId || '------'}</TableCell>
                      <TableCell align="left">{create || '------'}</TableCell>
                      <TableCell align="left">{amount || '------'}</TableCell>
                      <TableCell align="left">{sheet || '------'}</TableCell>
                      <TableCell align="left">{status || '------'}</TableCell>
                      <TableCell align="left">{errorReason || '------'}</TableCell>
                      <TableCell align="left">{create || '------'}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MassPayoutDetails;
