import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export enum EKycLevels {
  LVL1 = 'level1',
  LVL2 = 'level2',
}

export interface ICreateKycSettingsRequest {
  level: EKycLevels;
  attempts: number;
  enabled?: boolean;
}

const request = (data: ICreateKycSettingsRequest) => {
  return apiClient
    .post<IResponseAxios>(ApiUrls.kycSettingsLevel, data)
    .then((response) => response);
};

export default request;
