import { Avatar, Box, Button, Grid, Link, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Copyright from '../../components/Copyright';
import FormLayout from '../../components/FormLayout';
import InputPassword from '../../components/InputPassword';
import { logInUser } from '../../store/system/actions';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const MyLoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!email.length || !password.length) {
      setNotification('error', {
        message: 'Please enter email and password',
      });
      return;
    }
    dispatch(logInUser({ email, password }));
  };

  const moveToForgotPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(push(pageLinks.forgotPassword));
  };

  return (
    <FormLayout>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={submit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
            />
            <InputPassword
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete={'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value.trim())}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href={'#'} variant="body2" onClick={moveToForgotPassword}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </FormLayout>
  );
};

export default MyLoginPage;
