import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface IAdmin {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
  resetPasswordToken: string | null;
  resetPasswordExpirationDate: null;
  role: {
    id: 10;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
}

export interface IMeta {
  limit: number;
  page: number;
  total: number;
  totalPages: number;
}

export interface IParams {
  page: number;
  limit: number;
  searchTerm: string;
  sortDirection: 'ASC' | 'DESC';
  sortBy: 'email' | 'first_name' | 'last_name' | 'role_name';
}

export interface IAdminRolesResponse {
  data: {
    items: IAdmin[];
    meta: IMeta;
  };
}

const request = (params: any) => {
  const { page, sortDirection, sortBy, limit, searchTerm }: IParams = params;
  const query = `?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDirection=${sortDirection.toUpperCase()}&searchTerm=${searchTerm}`;
  return apiClient.get<IAdminRolesResponse>(ApiUrls.adminList + query).then((response) => response);
};

export default request;
