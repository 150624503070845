import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { DoneAll } from '@material-ui/icons';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import GetAppIcon from '@material-ui/icons/GetApp';
import CountriesGroupIcon from '@material-ui/icons/Language';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import InstanceIcon from '@material-ui/icons/Public';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SubjectIcon from '@material-ui/icons/Subject';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRouter } from '../../store/system/selectors';
import pageLinks from '../../utils/pageLinks';
import Can, { Permissions } from '../Can';
import useStyles from './styles';

interface IProps {
  open: boolean;
  setOpen(param: boolean): void;
}

interface IMenu {
  name: string;
  route: string;
  getIcon(): React.ReactNode;
  perform?: string;
}

const menuItems: IMenu[] = [
  {
    route: pageLinks.home,
    name: 'Products',
    perform: Permissions.readProductList,
    getIcon: () => <FeaturedPlayListIcon />,
  },

  {
    route: pageLinks.admins,
    perform: Permissions.readAdminList,
    name: 'Admin Users',
    getIcon: () => <PeopleAltIcon />,
  },

  {
    route: pageLinks.legalDocuments,
    perform: Permissions.readLegalDocuments,
    name: 'Legal Documents',
    getIcon: () => <ListAltIcon />,
  },

  {
    route: pageLinks.orders,
    perform: Permissions.readOrderList,
    name: 'Orders',
    getIcon: () => <ShoppingCartIcon />,
  },
  // { route: pageLinks.customers, name: 'Customers', getIcon: () => <PeopleIcon /> },

  {
    route: pageLinks.coupons,
    name: 'Coupons',
    perform: Permissions.readCouponReport,
    getIcon: () => <BookmarksIcon />,
  },

  {
    route: pageLinks.countriesGroups,
    name: 'Countries Groups',
    perform: Permissions.createCountriesGroup,
    getIcon: () => <CountriesGroupIcon />,
  },

  {
    route: pageLinks.customersLogs,
    name: 'Customers logs',
    perform: Permissions.readCustomersLogs,
    getIcon: () => <SubjectIcon />,
  },

  {
    route: pageLinks.permissions,
    name: 'Permission roles',
    perform: Permissions.readAdminRoleList,
    getIcon: () => <ErrorOutlineIcon />,
  },
  {
    route: pageLinks.instances,
    name: 'Instances',
    perform: Permissions.readInstanceList,
    getIcon: () => <InstanceIcon />,
  },
  {
    route: pageLinks.kyc,
    name: 'KYC',
    perform: Permissions.jumioVerificationListLevel1,
    getIcon: () => <VerifiedUserIcon />,
  },
  {
    route: pageLinks.payments,
    name: 'Payments',
    perform: Permissions.readPayments,
    getIcon: () => <PaymentIcon />,
  },
  {
    route: pageLinks.whiteList,
    name: 'Whitelist',
    perform: Permissions.readIpWhitelist,
    getIcon: () => <PlaylistAddCheckIcon />,
  },
  {
    route: pageLinks.productsActivations,
    name: 'Products Activations',
    perform: Permissions.readCustomerList,
    getIcon: () => <DoneAll />,
  },
  {
    route: pageLinks.settings,
    name: 'Settings',
    perform: Permissions.readSettings,
    getIcon: () => <SettingsIcon />,
  },
];

const downMenu: IMenu[] = [
  {
    route: pageLinks.csvReport,
    name: 'Payout Export',
    perform: Permissions.exportPayoutList,
    getIcon: () => <ListAltIcon />,
  },
  {
    route: pageLinks.customerPayouts,
    name: 'Customer Payouts',
    perform: Permissions.readCustomerList,
    getIcon: () => <ReceiptIcon />,
  },
  {
    route: pageLinks.signedDocuments,
    name: 'Signed Documents',
    perform: Permissions.downloadSignedDocs,
    getIcon: () => <ReceiptIcon />,
  },
  {
    route: pageLinks.userMigrationsReport,
    name: 'Reports',
    perform: Permissions.generateUserMigrationsReport,
    getIcon: () => <GetAppIcon />,
  },
  {
    route: pageLinks.massPaymentRequests,
    name: 'Mass Payout',
    perform: Permissions.massPayoutList,
    getIcon: () => <FormatListNumberedIcon />,
  },
];

const MenuSkeleton = () => (
  <ListItem>
    <ListItemIcon>
      <Skeleton animation="wave" variant="circle" width={24} height={24} />
    </ListItemIcon>
    <Skeleton height={32} width={'100%'} variant={'text'} />
  </ListItem>
);

const Menu: React.FC<IProps> = ({ setOpen, open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    location: { pathname },
  } = useSelector(selectRouter);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const moveToPage = (route: string): void => {
    dispatch(push(route));
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {menuItems.map(({ getIcon, name, route, perform }) => (
          <Can perform={perform} key={name} no={() => <MenuSkeleton />}>
            <ListItem button onClick={() => moveToPage(route)} selected={pathname === route}>
              <ListItemIcon>{getIcon()}</ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          </Can>
        ))}
      </List>
      <Divider />
      <List>
        <ListSubheader inset>Saved reports</ListSubheader>
        {downMenu.map(({ getIcon, name, route, perform }) => (
          <Can perform={perform} key={name} no={() => <MenuSkeleton />}>
            <ListItem button onClick={() => moveToPage(route)} selected={pathname === route}>
              <ListItemIcon>{getIcon()}</ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          </Can>
        ))}
      </List>
    </Drawer>
  );
};

export default Menu;
