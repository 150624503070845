import { isEmpty, isEqual, sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { IPermissionOption } from '../components/PermissionSelect';
import { selectPermissionGroups, selectPermissions } from '../store/entities/selectors';
import { transformPermissionGroupText, transformPermissionText } from '../utils/helpers';

export const syncGroupWithOptions = (
  selectedOptions: IPermissionOption[],
  removedOption: IPermissionOption
) =>
  selectedOptions.filter((option: IPermissionOption) => {
    if (option.isGroup && removedOption.isGroup) {
      return !isEqual(sortBy(option.value), sortBy(removedOption.value));
    } else if (option.isGroup && !removedOption.isGroup) {
      return !option.value.includes(removedOption.value[0]);
    } else if (!option.isGroup && removedOption.isGroup) {
      return !removedOption.value.includes(option.value[0]);
    } else if (!option.isGroup && !removedOption.isGroup) {
      return !isEqual(option.value, removedOption.value);
    }
    return true;
  });

export default function usePermissionOption(selectedPermissions: number[]) {
  const permissions = useSelector(selectPermissions);
  const permissionGroups = useSelector(selectPermissionGroups);
  const flattenSelectedPermissions = selectedPermissions?.flat();

  const permissionGroupOptions = permissionGroups
    .map((permissionGroup) => ({
      value: permissionGroup.permissions?.map((permission) => permission.id),
      label: transformPermissionGroupText(permissionGroup.name),
      groupName: 'permission groups:',
      priority: 1,
      isGroup: true,
    }))
    .filter((group) => !isEmpty(group.value));

  const permissionOptions = permissions.map((item) => {
    const groupName = permissionGroups.find((i) =>
      i.permissions.map((i) => i.id).includes(item.id)
    )?.name;
    return {
      value: [item.id],
      label: transformPermissionText(item.name),
      groupName: groupName ? `${groupName} group:` : 'ungrouped permissions:',
      priority: groupName ? 2 : 3,
      isGroup: false,
    };
  });

  const options = permissionGroupOptions.concat(permissionOptions);

  const selected = options.filter((option) =>
    option.isGroup
      ? option.value.every((i: number) => flattenSelectedPermissions?.includes(i))
      : option.value.some((i: number) => flattenSelectedPermissions?.includes(i))
  );

  const selectedGroupIds = selected
    .filter((i) => i.isGroup)
    .map((i) => i.value)
    .flat();

  return { selected, options, selectedGroupIds };
}
