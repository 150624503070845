import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { INestedRuleBody } from './addNestedRules';

export interface INestedRuleEdit extends INestedRuleBody {
  id: number;
}

const request = ({ id, overlappedPlanId, nestedPlanId }: INestedRuleEdit) => {
  return apiClient
    .put<IResponseAxios>(ApiUrls.nestedRules + `?id=${id}`, {
      overlappedPlanId,
      nestedPlanId,
    })
    .then((response) => response.data);
};

export default request;
