import { Avatar, Button, Grid, Paper, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import { useStandardFormStyles } from '../../components/FormBase';
import FormLayout from '../../components/FormLayout';
import InputPassword from '../../components/InputPassword';
import passwordReset from '../../services/api/requests/passwordReset';
import {
  getSearchParam,
  hasConsecutiveSymbols,
  hasRequiredSymbols,
  hasSpecialCharacters,
} from '../../utils/helpers';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  newPassword: Yup.mixed()
    .test(
      'has-consecutive',
      'should not have 3 consecutive letters or numbers',
      hasConsecutiveSymbols
    )
    .test('has-special-characters', 'should have special characters', hasSpecialCharacters)
    .test(
      'has-min-required-characters',
      'should have 1 letter / 1 number / 1 uppercase / 1 special characters',
      hasRequiredSymbols
    )
    .test('is-long-enough', 'minimum 10 characters long', (value) => value?.length >= 10)
    .required('this field is required'),

  confirmPassword: Yup.string()
    .required('this field is required')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords do not match'),
});

interface IValues {
  newPassword: string;
  confirmPassword: string;
}

const initialValues: IValues = {
  newPassword: '',
  confirmPassword: '',
};

const CreateNewPassword: React.FC = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();

  const fk: FormikProps<IValues> = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const token = getSearchParam('token', window.location.href);
      const email = getSearchParam('email', window.location.href);

      passwordReset({ token, email, password: values.newPassword }).then((res) => {
        if (res) {
          setNotification('success', {
            message: 'Password successfully changed.',
          });
          dispatch(push(pageLinks.login));
        }
      });
    },
  });

  return (
    <FormLayout>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <BackButton name={'back'} link={pageLinks.forgotPassword} />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create new password
          </Typography>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <InputPassword
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="Password"
              type="password"
              id="newPassword"
              autoComplete="current-password"
              error={!!(fk.errors.newPassword && fk.touched.newPassword)}
              onBlur={fk.handleBlur}
              helperText={fk.touched.newPassword && fk.errors.newPassword}
              value={fk.values.newPassword}
              onChange={fk.handleChange}
            />

            <InputPassword
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm password"
              type="password"
              id="confirmPassword"
              autoComplete="confirmPassword"
              onBlur={fk.handleBlur}
              error={!!(fk.errors.confirmPassword && fk.touched.confirmPassword)}
              helperText={fk.touched.confirmPassword && fk.errors.confirmPassword}
              value={fk.values.confirmPassword}
              onChange={fk.handleChange}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Next
            </Button>
          </form>
        </div>
      </Grid>
    </FormLayout>
  );
};

export default CreateNewPassword;
