import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { ProcessorType } from '../../services/api/requests/massPayouts';

interface IProps {
  onSelectClick(param: ProcessorType): void;
  type: ProcessorType;
}

const PaymentProcessors: React.FC<IProps> = ({ onSelectClick, type }) => {
  const onSelect = (type: ProcessorType) => {
    onSelectClick(type);
  };

  return (
    <Grid item xs={12}>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="position"
          name="isAddon"
          value={type}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onSelect(e.target.value as unknown as any)
          }
        >
          <FormControlLabel value={'dlocal'} control={<Radio color="primary" />} label="DLocal" />
          <FormControlLabel value={'circle'} control={<Radio color="primary" />} label="Circle" />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default PaymentProcessors;
