import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

const request = (id: number) => {
  const url = ApiUrls.deletePurchaseRule.replace('{id}', `${id}`);
  return apiClient.delete<IResponseAxios>(url).then((response) => response);
};

export default request;
