import { Button, CssBaseline, Grid, Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import CountrySelect from '../../components/CountrySelect';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Title from '../../components/Title';
import { LEGAL_DOCUMENT_TYPE } from '../../constants';
import addLegalDocument, { IDocumentBody } from '../../services/api/requests/addLegalDocument';
import editLegalDocument from '../../services/api/requests/editLegalDocument';
import { IGetInstance } from '../../services/api/requests/getInstances';
import { selectInstances, selectLegalDocumentForEditing } from '../../store/entities/selectors';
import { IOption } from '../../utils/commonTypes';
import { createOptions, getSearchParam } from '../../utils/helpers';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  url: Yup.string()
    .required()
    .max(500, "Value can't be more than 500")
    .test(
      'preview',
      'Url should contains "preview" param ',
      (value) => !!value?.includes('preview')
    ),
});

export const documentTypes: IOption[] = [
  { value: LEGAL_DOCUMENT_TYPE.GDPR_POLICY, label: 'GDPR Policy' },
  { value: LEGAL_DOCUMENT_TYPE.PRIVACY_POLICY, label: 'Privacy Policy' },
  { value: LEGAL_DOCUMENT_TYPE.TERMS_OF_USE, label: 'Terms of Use' },
  { value: LEGAL_DOCUMENT_TYPE.REFUND_POLICY, label: 'Refund Policy' },
  { value: LEGAL_DOCUMENT_TYPE.CANCELLATION_POLICY, label: 'Cancellation Policy' },
];

const initialValues: IDocumentBody = {
  url: '',
  type: 'privacy_policy',
  countryCodes: [],
  instanceCodes: [],
};

const CreateEditLegalDocument: React.FC = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();

  const instances: IGetInstance[] = useSelector(selectInstances);
  const instanceOptions = createOptions<IGetInstance>(instances, 'code', 'name');
  const documentForEditing = useSelector(selectLegalDocumentForEditing);
  const documentData = documentForEditing || initialValues;

  const fk: FormikProps<IDocumentBody> = useFormik({
    initialValues: {
      ...documentData,
      instanceCodes: documentData.instanceCodes.length ? documentData.instanceCodes : [],
    },
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values: IDocumentBody) => {
      try {
        const id = getSearchParam('id', window.location.href);

        if (id) {
          await editLegalDocument(values, id);
        } else {
          await addLegalDocument(values);
        }

        setNotification('success', {
          message: 'Success',
        });

        dispatch(push(pageLinks.legalDocuments));
      } catch (err) {
        console.warn('err', err);
      }
    },
  });

  const createEditText = documentForEditing ? 'Edit' : 'Create';
  const createInputField = createInput<IDocumentBody>(fk);

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.legalDocuments} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{createEditText} Document </Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('url', 'Url')}
              <Grid item xs={12} md={6}>
                <InputSelect
                  id="type"
                  label={'Type'}
                  value={fk.values.type}
                  onChange={(e): void => fk.setFieldValue('type', e.target.value)}
                  options={documentTypes}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id={'instanceCodes'}
                  options={instanceOptions}
                  value={instanceOptions.filter(
                    (item) => !!fk.values.instanceCodes.find((i) => item.value === i)
                  )}
                  onChange={(e: any, instancesOptions: IOption[]) =>
                    fk.setFieldValue(
                      'instanceCodes',
                      instancesOptions.map((r) => r.value)
                    )
                  }
                  autoHighlight
                  multiple
                  fullWidth
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose an instance"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CountrySelect
                  id={'countryCodes'}
                  value={fk.values.countryCodes}
                  onChange={fk.setFieldValue}
                  error={!!(fk.errors.countryCodes && fk.touched.countryCodes)}
                  onBlur={fk.handleBlur}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditLegalDocument;
