import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IAddPermissionGroupRequest {
  name: string;
  permissionIds: number[];
}

export interface IPermission {
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
}
export interface IPermissionGroup {
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
  permissions: IPermission[];
}

export interface IPermissionsResponse {
  permissions: IPermission[];
  groups: IPermissionGroup[];
}

export interface IEditPermissionGroupRequest {
  groupId: number;
  name: string;
  permissionIds: number[];
}

export const addPermissionGroup = (data: IAddPermissionGroupRequest) =>
  apiClient.post(ApiUrls.permissionGroupCreate, data).then((response) => response);

export const getPermissions = () =>
  apiClient.get<IPermissionsResponse>(ApiUrls.permissions).then((response) => response.data);

export const editPermissionGroup = (data: IEditPermissionGroupRequest) => {
  const url = ApiUrls.permissionGroupUpdate.replace('{id}', `${data.groupId}`);
  return apiClient.put(url, data).then((response) => response);
};

export const deletePermissionGroup = (id: number) => {
  const url = ApiUrls.permissionGroupDelete.replace('{id}', `${id}`);
  return apiClient.delete<IResponseAxios>(url).then((response) => response);
};
