import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

const request = (id: number) => {
  const url = ApiUrls.createCouponUsageRule.replace('{id}', `${id}`);
  return apiClient.post<IResponseAxios>(url).then((response) => response);
};

export default request;
