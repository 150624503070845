import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
const fileDownload = require('js-file-download');

const request = (name: string) => {
  return apiClient
    .get<IResponseAxios>(ApiUrls.payoutBase + `/file/${name}`, {
      headers: {
        'Content-Disposition': 'attachment;filename=report.xls',
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
    .then((response) => {
      fileDownload(response.data, name);
    });
};

export default request;
