import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { ICreateInstance } from './createInstance';

export interface IEditInstance extends ICreateInstance {
  id: number;
}

interface ICreateInstanceResponse {}

const request = (data: IEditInstance) =>
  apiClient
    .put<ICreateInstanceResponse>(ApiUrls.editInstance + data.id, data)
    .then((response) => response);

export default request;
