import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IBody {
  token: string | null;
  password: string;
  email: string | null;
}

const request = (data: IBody) => {
  return apiClient.post<IResponseAxios>(ApiUrls.resetPassword, data).then((response) => response);
};

export default request;
