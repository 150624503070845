import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { ICreateCombo } from './createComboPlan';

export interface IComboPlanData extends ICreateCombo {
  id: string;
}

export interface IComboProductWithPlans {
  createdAt: string;
  description: string;
  id: number;
  imageUrl: string;
  name: string;
  comboInfo: IComboPlanData[];
  rebillyProductId: string;
  updatedAt: string;
}

const request = (productId: string) =>
  apiClient
    .get<IResponseAxios<IComboProductWithPlans>>(ApiUrls.comboBase + 'product/' + productId)
    .then((response) => response);

export default request;
