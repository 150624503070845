import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IDocumentBody } from './addLegalDocument';

const request = (body: IDocumentBody, id: string) => {
  return apiClient.put<IResponseAxios>(ApiUrls.legalDocument + `/${id}`, body);
};

export default request;
