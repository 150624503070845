export interface IOption {
  value: any;
  label: string;
}

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  EXPIRED = 'Expired',
  PENDING = 'Pending',
  CANCELLED = 'Cancelled',
}

export enum SELECT_OPTION {
  ANY = 'Any',
  ALL = 'All',
}
