import { createReducer } from '@reduxjs/toolkit';
import * as EntityActions from './actions';
import { EntitiesState } from './types';

const initialState: EntitiesState = {
  products: [],
  permissions: [],
  permissionGroups: [],
  roles: null,
  userForEditing: null,
  couponForEditing: null,
  instanceForEditing: null,
  gatewayForEditing: null,
  settingForEditing: null,
  conversionTemplateForEditing: null,
  paymentMethodForEditing: null,
  planForEditing: null,
  instances: [],
  countries: [],
  productForEditing: null,
  comboForEditing: null,
  payoutDetails: null,
  comboPlanForEditing: null,
  services: [],
  paymentMethods: null,
  payoutRequestDetails: null,
  legalDocumentForEditing: null,
  purchaseRules: [],
  couponUsageRules: [],
  countryGroups: null,
  userDetails: null,
};

export const entitiesReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(EntityActions.saveServices, (state, action) => {
      return { ...state, services: action.payload };
    })
    .addCase(EntityActions.savePaymentMethods, (state, action) => {
      return { ...state, paymentMethods: action.payload };
    })

    .addCase(EntityActions.setProducts, (state, action) => {
      return { ...state, products: action.payload };
    })

    .addCase(EntityActions.setPermissions, (state, action) => {
      return { ...state, permissions: action.payload };
    })

    .addCase(EntityActions.setPermissionGroups, (state, action) => {
      return { ...state, permissionGroups: action.payload };
    })

    .addCase(EntityActions.editAdminUser, (state, action) => {
      return { ...state, userForEditing: action.payload };
    })
    .addCase(EntityActions.setAdminRoles, (state, action) => {
      return { ...state, roles: action.payload };
    })
    .addCase(EntityActions.editProduct, (state, action) => {
      return { ...state, productForEditing: action.payload };
    })

    .addCase(EntityActions.editCombo, (state, action) => {
      return { ...state, comboForEditing: action.payload };
    })

    .addCase(EntityActions.editCouponData, (state, action) => {
      return { ...state, couponForEditing: action.payload };
    })

    .addCase(EntityActions.editInstanceData, (state, action) => {
      return { ...state, instanceForEditing: action.payload };
    })

    .addCase(EntityActions.editPlan, (state, action) => {
      return { ...state, planForEditing: action.payload };
    })

    .addCase(EntityActions.editGatewayData, (state, action) => {
      return { ...state, gatewayForEditing: action.payload };
    })

    .addCase(EntityActions.editSettingData, (state, action) => {
      return { ...state, settingForEditing: action.payload };
    })

    .addCase(EntityActions.editConversionTemplate, (state, action) => {
      return { ...state, conversionTemplateForEditing: action.payload };
    })

    .addCase(EntityActions.editPaymentMethodData, (state, action) => {
      return { ...state, paymentMethodForEditing: action.payload };
    })

    .addCase(EntityActions.editComboPlan, (state, action) => {
      return { ...state, comboPlanForEditing: action.payload };
    })

    .addCase(EntityActions.payoutDetails, (state, action) => {
      return { ...state, payoutDetails: action.payload };
    })

    .addCase(EntityActions.saveCountries, (state, action) => {
      return { ...state, countries: action.payload };
    })

    .addCase(EntityActions.saveInstances, (state, action) => {
      return { ...state, instances: action.payload };
    })

    .addCase(EntityActions.editLegalDocument, (state, action) => {
      return { ...state, legalDocumentForEditing: action.payload };
    })

    .addCase(EntityActions.showPayoutRequestInfo, (state, action) => {
      return { ...state, payoutRequestDetails: action.payload };
    })

    .addCase(EntityActions.setPurchaseRules, (state, action) => {
      return { ...state, purchaseRules: action.payload };
    })

    .addCase(EntityActions.setCouponUsageRules, (state, action) => {
      return { ...state, couponUsageRules: action.payload };
    })

    .addCase(EntityActions.saveCountryGroups, (state, action) => {
      return { ...state, countryGroups: action.payload };
    })

    .addCase(EntityActions.setUserDetails, (state, action) => {
      return { ...state, userDetails: action.payload };
    })
);
