import { Box, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import Modal from '../Modal';

interface IProps {
  onDeactivateAdmin: () => void;
  status: boolean;
  disabled: boolean;
  name: string;
}

const useStyles = makeStyles(() => ({
  boxButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
}));

const AdminDeactivateModal = ({ onDeactivateAdmin, status, disabled, name }: IProps) => {
  const classes = useStyles();
  return (
    <Modal
      button={<Switch color="primary" checked={status} disabled={disabled} />}
      disabled={disabled}
      renderContent={(onClose: () => void) => (
        <div>
          <h2>{status ? 'Deactivate' : 'Activate'} user</h2>
          <div>
            Do you really want to {status ? 'deactivate' : 'activate'} an user{' '}
            <strong>{name} ?</strong>
          </div>

          <Box className={classes.boxButton}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => {
                onDeactivateAdmin();
                onClose();
              }}
            >
              {status ? 'Deactivate' : 'Activate'}
            </Button>
            <Button variant="contained" color="default" onClick={() => onClose()}>
              Cancel
            </Button>
          </Box>
        </div>
      )}
    />
  );
};

export default AdminDeactivateModal;
