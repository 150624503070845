import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { CountriesGroupRequest } from './createCountriesGroup';

const request = (id: number, data: CountriesGroupRequest) => {
  return apiClient
    .put<CountriesGroupRequest>(ApiUrls.countriesGroupsEdit + id, data)
    .then((response) => response.data);
};

export default request;
