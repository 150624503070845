import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IPaginationParams } from '../types';

export interface ICountry {
  id: number;
  code: string;
  name: string;
  customBaseUrl: string;
  customCompanyName: string;
  phoneVerificationEnabled: boolean;
}

export interface ICountryGroup {
  countries: ICountry[];
  createdAt: Date;
  updatedAt: Date;
  id: number;
  name: string;
}

export interface ICountryGroupResponse {
  data: {
    groups: ICountryGroup[];
  };
}

export interface ICountriesGroupsListResponse {
  data: {
    countries: ICountry[];
    groups: ICountryGroup[];
  };
}

export const getCountries = () =>
  apiClient.get<ICountry[]>(ApiUrls.countries).then((response) => response);

export const getCountryGroup = () =>
  apiClient.get<ICountryGroupResponse>(ApiUrls.instGroups).then((response) => response);

export const getCountriesGroupWithPagination = ({
  page,
  limit,
}: IPaginationParams): Promise<ICountriesGroupsListResponse> => {
  const query = `?page=${page}&limit=${limit}`;
  return apiClient
    .get<ICountriesGroupsListResponse>(ApiUrls.instGroups + query)
    .then((response) => response.data);
};
