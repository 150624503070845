import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import EditCountryForm, { COUNTRY_EDIT_FIELD } from '../../components/EditCountryForm';
import InputSearch from '../../components/InputSearch';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import deleteInstance from '../../services/api/requests/deleteInstance';
import getCountryList from '../../services/api/requests/getCountryList';
import getInstanceList, { ICountry } from '../../services/api/requests/getInstanceList';
import { IGetInstance } from '../../services/api/requests/getInstances';
import { IMeta } from '../../services/api/types';
import { editInstanceData, updateInstanceList } from '../../store/entities/actions';
import { selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const headCountryCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID', disableSort: true },
  { id: 'code', numeric: false, disablePadding: false, label: 'Code', disableSort: true },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', disableSort: false },
  { id: 'customBaseUrl', numeric: false, disablePadding: false, label: 'Url', disableSort: true },
  {
    id: 'customCompanyName',
    numeric: false,
    disablePadding: false,
    label: 'Company name',
    disableSort: true,
  },
];

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', disableSort: true },
  { id: 'code', numeric: false, disablePadding: false, label: 'Code', disableSort: true },
  { id: 'priority', numeric: false, disablePadding: false, label: 'Priority', disableSort: true },
  {
    id: 'defaultBaseUrl',
    numeric: false,
    disablePadding: false,
    label: 'Base url',
    disableSort: true,
  },
  {
    id: 'countries',
    numeric: false,
    disablePadding: false,
    label: 'Countries',
    disableSort: true,
  },
  {
    id: 'baseCountry',
    numeric: false,
    disablePadding: false,
    label: 'Base countries',
    disableSort: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    disableSort: true,
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Updated',
    disableSort: true,
  },
];

const Instances: FC<any> = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();
  const permissions = useSelector(selectUserPermissions);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [instanceList, setInstanceList] = React.useState<IGetInstance[]>([]);
  const [countryList, setCountryList] = React.useState<ICountry[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [sortDirection, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] = React.useState<string>('name');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [isCountryList, setCountryListFlag] = React.useState<number>(0);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (permissions?.includes(Permissions.readInstanceList)) {
      fetchList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, isCountryList, searchTerm, sortBy, sortDirection]);

  const fetchList = () => {
    if (isCountryList) {
      getCountryList({ page, limit: rowsPerPage, searchTerm, sortDirection }).then((response) => {
        if (response) {
          setLoading(false);
          setCountryList(response.data.data.items);
          setMeta(response.data.data.meta);
        }
      });
      return;
    }

    getInstanceList({ page, limit: rowsPerPage }).then((response) => {
      if (response) {
        setLoading(false);
        setInstanceList(response.data.data.items);
        setMeta(response.data.data.meta);
      }
    });
  };

  const createInstance = () => {
    dispatch(push(pageLinks.createEditInstance));
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPage(1);
    setCountryListFlag(newValue);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteInstance(id);
      setNotification('success', {
        message: 'Success',
      });
      fetchList();
      dispatch(updateInstanceList());
    } catch (e) {
      console.error('DELETE INSTANCE ERROR', e);
    }
  };

  const handleRequestSort = (event: any, property: 'asc' | 'desc') => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleEdit = (instance: IGetInstance) => {
    dispatch(editInstanceData(instance));
    dispatch(push(pageLinks.createEditInstance));
  };

  const renderInstancesTable = () => (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={'medium'}
        aria-label="enhanced table"
      >
        <CustomTableHead headCells={headCells} classes={classes} />
        <TableBody>
          {loading && <TableSkeleton />}

          {!loading &&
            instanceList?.map((instance) => {
              const {
                id,
                code,
                defaultBaseUrl,
                baseCountry,
                countries,
                name,
                createdAt,
                updatedAt,
                priority,
              } = instance;
              const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
              const update = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');

              return (
                <TableRow key={id}>
                  <TableCell align="left">{name}</TableCell>
                  <TableCell align="left">{code}</TableCell>
                  <TableCell align="left">{priority}</TableCell>
                  <TableCell align="left">{defaultBaseUrl}</TableCell>
                  <TableCell align="left">
                    <Box display="flex" flexWrap="wrap">
                      {countries.map((country: any, idx: number, arr: any) => (
                        <Tooltip title={country.name} key={country.code}>
                          <Box m={0.5} display="flex" flexWrap="wrap">{`${country.code}${
                            arr.length - 1 === idx ? '' : ', '
                          }`}</Box>
                        </Tooltip>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell>{baseCountry?.code}</TableCell>
                  <TableCell>{create}</TableCell>
                  <TableCell>{update}</TableCell>
                  <TableCell align="left">
                    <Can perform={Permissions.updateInstance}>
                      <IconButton
                        aria-label="edit"
                        title={'Edit'}
                        onClick={() => handleEdit(instance)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Can>
                  </TableCell>
                  <TableCell align="left">
                    <Can perform={Permissions.deleteInstance}>
                      <DeleteModalButton
                        name={name}
                        entity={'Instance'}
                        onDelete={() => handleDelete(id)}
                      />
                    </Can>
                  </TableCell>
                </TableRow>
              );
            })}

          {!loading && !instanceList?.length && (
            <TableRow>
              <TableCell>There are no instances</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderCountriesTable = () => (
    <>
      <Grid container spacing={2} className={classes.searchRow}>
        <Grid item xs={12} sm={6}>
          <InputSearch
            onSubmit={(val) => {
              setPage(1);
              setSearchTerm(val);
            }}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead
            headCells={headCountryCells}
            order={sortDirection}
            orderBy={sortBy}
            onRequestSort={handleRequestSort}
            classes={classes}
          />
          <TableBody>
            {loading && <TableSkeleton />}

            {!loading &&
              countryList?.map((item) => {
                const { id, code, name, customBaseUrl, customCompanyName } = item;

                return (
                  <TableRow key={id}>
                    <TableCell align="left">{id}</TableCell>
                    <TableCell align="left">{code}</TableCell>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">
                      <EditCountryForm
                        fetchCountryList={fetchList}
                        value={customBaseUrl}
                        field={COUNTRY_EDIT_FIELD.URL}
                        id={id}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <EditCountryForm
                        fetchCountryList={fetchList}
                        value={customCompanyName}
                        field={COUNTRY_EDIT_FIELD.COMPANY_NAME}
                        id={id}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}

            {!loading && !instanceList?.length && (
              <TableRow>
                <TableCell>There are no instances</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Instances</Title>
            <Can perform={Permissions.createInstance}>
              <Button
                onClick={createInstance}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add Instance
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <Tabs
          value={isCountryList}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabClick}
          aria-label="disabled tabs example"
        >
          <Tab label="Instances" disabled={loading} />
          <Tab label="Countries" disabled={loading} />
        </Tabs>

        {isCountryList ? renderCountriesTable() : renderInstancesTable()}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default Instances;
