import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import TableSkeleton from '../../components/TableSkeleton';
import downloadCsv from '../../services/api/requests/downloadCsv';
import { IPayoutItem } from '../../services/api/requests/getPayoutList';
import { PayoutTablesData, PayoutTabs } from '../PayoutExport';

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: '#', disableSort: true },
  { id: 'admin', numeric: false, disablePadding: false, label: 'Admin Name', disableSort: true },
  {
    id: 'imageUrl',
    numeric: false,
    disablePadding: false,
    label: 'Export date',
    disableSort: true,
  },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Filter', disableSort: true },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Actions', disableSort: true },
];

interface IProps extends PayoutTablesData {
  list: IPayoutItem[] | null;
}

const PayoutReport = ({
  loading,
  list,
  handleChangePage,
  handleChangeRowsPerPage,
  meta,
}: IProps) => {
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();
  const createFormat = (val: string | Date) => dateFns.format(new Date(val), 'dd/MM/yyyy');
  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead classes={classes} headCells={headCells} />

          <TableBody>
            {loading && <TableSkeleton />}
            {!loading &&
              list?.map((item: IPayoutItem) => {
                const {
                  createdAt,
                  id,
                  admin,
                  createdFrom,
                  createdTo,
                  editedFrom,
                  method,
                  editedTo,
                  files,
                } = item;

                const create = createdAt && createFormat(createdAt as Date);
                const options = files.map((item) => item.name);
                const chunkedFile = options.length > 1;

                const createInterval =
                  createdFrom &&
                  createdTo &&
                  `${createFormat(createdFrom)} - ${createFormat(createdTo)}`;
                const editInterval =
                  editedFrom &&
                  editedTo &&
                  `${createFormat(editedFrom)} - ${createFormat(editedTo)}`;

                const interval = createInterval || editInterval;
                const name = `${admin.firstName} ${admin.lastName}`;

                return (
                  <TableRow key={id}>
                    <TableCell align="left">{id}</TableCell>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell>{create}</TableCell>
                    <TableCell>
                      {method} {interval || '-'}
                    </TableCell>
                    <TableCell align="left">
                      <Box display={'flex'}>
                        <Can perform={Permissions.downloadingCsvFiles}>
                          {chunkedFile ? (
                            <LongMenu handleMenuClick={downloadCsv} options={options} />
                          ) : (
                            <IconButton
                              aria-label="delete"
                              title={'Download'}
                              onClick={() => {
                                downloadCsv(files[0].name);
                              }}
                            >
                              <GetAppIcon />
                            </IconButton>
                          )}
                        </Can>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}

            {!loading && !list?.length && (
              <TableRow>
                <TableCell>There are no notes</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={meta.total}
        rowsPerPage={meta.limit}
        page={meta.page - 1}
        onChangePage={(e: unknown, newPage: number) =>
          handleChangePage(e, newPage, PayoutTabs.PAYOUT)
        }
        onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeRowsPerPage(event, PayoutTabs.PAYOUT)
        }
      />
    </>
  );
};

export default PayoutReport;

const ITEM_HEIGHT = 48;

const LongMenu: React.FC<{ handleMenuClick(a: string): void; options: Array<string> }> = ({
  handleMenuClick,
  options,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <GetAppIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'auto',
          },
        }}
      >
        {options.map((option: string) => (
          <MenuItem
            key={option}
            onClick={() => {
              handleMenuClick(option);
              handleClose();
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
