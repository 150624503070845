import { Button, Grid, Paper, Tab, TablePagination, Tabs } from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import React, { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { useStandardTableStyles } from '../../components/DefaultTable';
import GatewayList from '../../components/GatewayList';
import PaymentMethodList from '../../components/PaymentMethodList';
import Title from '../../components/Title';
import { IMeta } from '../../services/api/types';
import pageLinks from '../../utils/pageLinks';

const EnhancedTable: FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [meta, setMeta] = useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [tabIdx, setTabIdx] = React.useState<number>(0);

  const handleTabClick = (event: React.ChangeEvent<{}>, newInx: number) => {
    setPage(1);
    setTabIdx(newInx);
  };

  const createGateway = () => {
    dispatch(push(pageLinks.createEditGateway));
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Payments</Title>
            <Can perform={Permissions.createPaymentGateway}>
              <Button
                onClick={createGateway}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add Gateway
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <Tabs
          value={tabIdx}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabClick}
          aria-label="disabled tabs example"
        >
          <Tab label="Gateways" disabled={loading} />
          <Tab label="Payment Methods" disabled={loading} />
        </Tabs>

        {tabIdx === 0 ? (
          <GatewayList
            loading={loading}
            setLoading={setLoading}
            setMeta={setMeta}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        ) : (
          <PaymentMethodList
            loading={loading}
            setLoading={setLoading}
            setMeta={setMeta}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        )}

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedTable;
