import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

const request = (id: number) => {
  return apiClient
    .delete<IResponseAxios>(ApiUrls.nestedRules + `?id=${id}`)
    .then((response) => response.data);
};

export default request;
