import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserPermissions } from '../../store/system/selectors';

export const Permissions = {
  createUsers: 'create_users',
  createAdminRole: 'create_admin_role',
  updateAdmin: 'update_admin',
  updateAdminRole: 'update_admin_role',
  deleteAdminRole: 'delete_admin_role',
  deleteCoupon: 'delete_coupon',
  createCoupon: 'create_coupon',
  updateCoupon: 'update_coupon',
  createAdmin: 'create_admin',
  deleteAdmin: 'delete_admin',
  deleteSubscriptionPlan: 'delete_subscription_plan',
  readProductList: 'read_product_list',
  createProduct: 'create_product',
  updateProduct: 'update_product',
  readSubscriptionPlanList: 'read_subscription_plan_list',
  updateAdmins: 'update_admins',
  deleteAdmins: 'delete_admins',
  readAdminList: 'read_admin_list',
  createSubscriptionPlan: 'create_subscription_plan',
  readCouponReport: 'read_coupon_report',
  updateSubscriptionPlan: 'update_subscription_plan',
  readAdminRoleList: 'read_admin_role_list',
  readPermissionList: 'read_permission_list',
  readUserList: 'read_user_list',
  updateServices: 'update_services',
  readPlansList: 'read_a_list_of_subscription_plans',
  getRoleList: 'get_admin_role_list',
  getPermissionList: 'get_permission_list',
  readUsersList: 'read_a_list_of_users',
  readInstanceList: 'read_instance_list',
  createInstance: 'create_instance',
  updateInstance: 'update_instance',
  deleteInstance: 'delete_instance',
  createPaymentGateway: 'create_payment_gateway',
  readPayments: 'read_payment_gateway_and_payment_method_lists',
  readSettings: 'read_settings',
  updateSettings: 'update_setting',
  updateSettingValue: 'update_setting_value',
  createSettings: 'create_setting',
  deleteSettings: 'delete_setting',
  enablePhoneVerification: 'enable_phone_verification',
  updatePaymentGateway: 'update_payment_gateway',
  updatePaymentMethod: 'update_payment_method',
  deletePaymentGateway: 'delete_payment_gateway',
  deleteExportsHistoryItem: 'delete_exports_history_item',
  downloadingCsvFiles: 'downloading_csv_files',
  generateUsersPayouts: 'generate_users_payouts',
  exportPayoutCsv: 'export_payout_csv',
  exportPayoutList: 'export_payout_list',
  createPlanUpgradeRules: 'create_plan_upgrade_rules',
  updatePlanUpgradeRules: 'update_plan_upgrade_rules',
  deletePlanUpgradeRules: 'delete_plan_upgrade_rules',
  viewComboList: 'view_combo_list',
  updateCombo: 'update_combo',
  createCombo: 'create_combo',
  deleteCombo: 'delete_combo',
  viewProduct: 'view_product',
  deleteProduct: 'delete_product',
  readCustomerList: 'read_customer_list',
  generateUserMigrationsReport: 'generate_migrated_users_report',

  createPurchaseRules: 'create_purchase_rules',
  createRequiredPlanOfPurchaseRules: 'create_required_plan_of_purchase_rules',
  readPurchaseRules: 'read_purchase_rules',
  updateRequiredPlanOfPurchaseRules: 'update_required_plan_of_purchase_rules',
  deleteRequiredPlanOfPurchaseRules: 'delete_required_plan_of_purchase_rules',
  deletePurchaseRules: 'delete_purchase_rules',

  createLegalDocuments: 'create_legal_documents',
  readLegalDocuments: 'read_legal_documents',
  updateLegalDocuments: 'update_legal_documents',
  deleteLegalDocuments: 'delete_legal_documents',

  readOrderList: 'read_order_list',

  createCouponUsageRules: 'create_coupon_usage_rules',
  createRequiredPlanOfCouponUsageRules: 'create_required_plan_of_coupon_usage_rules',
  readCouponUsageRules: 'read_coupon_usage_rules',
  updateRequiredPlanOfCouponUsageRules: 'update_required_plan_of_coupon_usage_rules',
  deleteRequiredPlanOfCouponUsageRules: 'delete_required_plan_of_coupon_usage_rules',
  deleteCouponUsageRules: 'delete_coupon_usage_rules',

  expandIpWhitelist: 'expand_ip_whitelist',
  readIpWhitelist: 'read_ip_whitelist',
  deleteIpFromWhitelist: 'delete_ip_from_whitelist',

  massPayoutList: 'read_mass_payout_list',
  downloadSignedDocs: 'download_signed_docs',
  jumioVerificationListLevel1: 'jumio_verification_list_level_1',

  createCountriesGroup: 'create_country_group',
  editCountriesGroup: 'update_country_group',
  deleteCountriesGroup: 'delete_country_group',

  readCustomersLogs: 'read_logs',

  verifyOrderItems: 'verify_order_items',

  readConversionTemplateList: 'read_conversion_template_list',
  createConversionTemplate: 'create_conversion_template',
  readConversionTemplate: 'read_conversion_template',
  updateConversionTemplate: 'update_conversion_template',
  deleteConversionTemplate: 'delete_conversion_template',
  readSegment: 'read_segment',
};

interface IProps {
  children: React.ReactNode;
  perform?: string;
  no?: (a: void) => any;
  otherwise?: React.ReactNode;
}

const Can: React.FC<IProps> = (props) => {
  const { perform, no, children, otherwise = '' } = props;
  const availablePermissions = useSelector(selectUserPermissions);

  if (!perform) {
    return children;
  }

  if (!availablePermissions) {
    return no ? no() : <div />;
  }

  // @ts-ignore
  const can = availablePermissions?.includes(perform);

  return can ? children : otherwise;
};

export default Can;
