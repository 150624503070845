import { Box, IconButton, InputLabel, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormikProps } from 'formik';
import Image from 'material-ui-image';
import * as React from 'react';
import { IMAGE_UPLOAD_ENTITY } from '../../constants';
import { onAddImage } from '../../utils/helpers';

interface IProps {
  imageUrl?: string | null;
  label: string;
  fk: FormikProps<any>;
  entity: IMAGE_UPLOAD_ENTITY;
  id: string;
  imageUrlField?: string;
}

const FileInput: React.FC<IProps> = (props: IProps) => {
  const { imageUrl, label, fk, entity, id, imageUrlField } = props;

  const [image, setImage] = React.useState<string | null>(imageUrl || null);

  const fieldId = id || 'file';
  const deleteImage = () => {
    fk.setFieldValue(fieldId, null);
    setImage(null);
  };

  return (
    <div>
      <InputLabel>{label}</InputLabel>
      {image || imageUrl ? (
        <Box display={'flex'} alignItems={'center'}>
          <Image
            src={image || imageUrl || ''}
            aspectRatio={25 / 9}
            style={{ width: 200, height: 100 }}
            disableSpinner
          />

          <Box width={30} height={30}>
            <IconButton aria-label="delete" title={'Delete'} onClick={deleteImage}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <TextField
          variant="outlined"
          fullWidth
          type={'file'}
          id={fieldId}
          name={fieldId}
          autoComplete="new-password"
          error={!!(fk.errors[fieldId] && fk.touched[fieldId])}
          onBlur={fk.handleBlur}
          helperText={fk.touched[fieldId] && fk.errors[fieldId]}
          value={fk.values[fieldId]?.fileName}
          onChange={(e) => onAddImage({ e, entity, fk, setImage, imageUrlField })}
          inputProps={{ accept: 'image/*' }}
        />
      )}
    </div>
  );
};

export default FileInput;
