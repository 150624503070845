import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IPlan } from './getProductPlans';

export interface IDataSet {
  countryCodes: string[];
  setupPrice?: number;
  CV?: number;
  QV?: number;
  SCV?: number;
  SQV?: number;
  FSB?: number;

  recurringPrice?: number;
  recurringCV?: number;
  recurringQV?: number;
  recurringSCV?: number;
  recurringSQV?: number;
  recurringFSB?: number;

  retailPrice?: number;
  retailCV?: number;
  retailQV?: number;
  retailSCV?: number;
  retailSQV?: number;
  retailFSB?: number;

  recurringRetailPrice?: number;
  retailRecurringCV?: number;
  retailRecurringQV?: number;
  retailRecurringSCV?: number;
  retailRecurringSQV?: number;
  retailRecurringFSB?: number;
}

export interface ICreatePlan {
  icon: string;
  name: string;
  recurringSKU: string;
  sku: string;
  isAddon: boolean;
  additionalInfo: string;
  bullets: string[]; //описание плюсов плана
  color: string;
  bgColor: string;
  comboData?: null;
  countryCodes: string[]; //
  currency: 'USD' | 'EUR';
  description: string;
  discountExigoItem: string;
  fullDescription: string;
  file: any;
  intervalSize: number;
  intervalUnit: 'day';
  itemDescription: string;
  priority: number; // приоритет важности плана
  fsbEnabled: boolean;
  autopayable: boolean;
  rebillyPlanId: string;
  questDollarsReward: number; //награда за покупку люксов
  recurringCV: number;
  recurringFSB: number;
  recurringPrice: number;
  oneDayRecurringSKU: string;
  recurringQV: number;
  recurringRetailPrice: number;
  recurringSCV: number;
  recurringSQV: number;
  retailPrice: number;
  setupPrice: number;
  isRecurring: boolean;
  noExigo: boolean;
  hasRetail: boolean;
  retailOnly: boolean;
  serviceIDs: string[];
  CV: number;
  FSB: number;
  QV: number;
  SCV: number;
  SQV: number;
  splitValues: number;
  splitFSB: number;
  splitSKU: string;
  splitBaseValue: number;
  regionalDatasets: IDataSet[] | []; // отличия по регионам
  retailExigoItem: string | null;
  retailRecurringCV: number;
  retailRecurringQV: number;
  retailRecurringSCV: number;
  retailRecurringSQV: number;
  retailRecurringFSB: number;
  retailCV: number;
  retailQV: number;
  retailSCV: number;
  retailSQV: number;
  retailFSB: number;
  imageUrl?: string | null;
  gatewayMethodIds: number[];
  status: string;
  primaryServiceId: string | number;
  requireAgreement: boolean;
  requireVerification: boolean;
  enrollerCashback: number;
  customerCashback: number;
  isCashbackActive?: boolean;
}

const request = (data: ICreatePlan, productId: string) => {
  return apiClient
    .post<IResponseAxios<IPlan[]>>(ApiUrls.baseProduct + productId + '/plan', data)
    .then((response) => response);
};

export default request;
