import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import InputSelect from '../../components/InputSelect';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import { ICoupon } from '../../services/api/requests/createCoupon';
import deleteCoupon from '../../services/api/requests/deleteCoupon';
import getCouponList from '../../services/api/requests/getCouponList';
import { IMeta } from '../../services/api/types';
import { editCouponData } from '../../store/entities/actions';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import { actions } from '../CreateEditCoupon';

const headCells = [
  { id: 'couponCode', numeric: false, disablePadding: false, label: 'Code' },
  { id: 'startAt', numeric: false, disablePadding: false, label: 'Start Date', disableSort: true },
  {
    id: 'expiredAt',
    numeric: false,
    disablePadding: false,
    label: 'Expiration date',
    disableSort: true,
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    disableSort: true,
  },
  {
    id: 'usageRulesDescription',
    numeric: false,
    disablePadding: false,
    label: 'Usage Rules Description',
    disableSort: true,
  },
  { id: 'forAction', numeric: false, disablePadding: false, label: 'Type', disableSort: true },
  { id: 'currency', numeric: false, disablePadding: false, label: 'Discount', disableSort: true },
  {
    id: 'perUserLimit',
    numeric: false,
    disablePadding: false,
    label: 'Usage Limit',
    disableSort: true,
  },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar: React.FC<{
  onFilterClick(e: React.SyntheticEvent): void;
}> = (props) => {
  const classes = useToolbarStyles();
  const { onFilterClick } = props;
  return (
    <Toolbar className={classes.root}>
      <div className={classes.title} />
      <Tooltip title="Filter list" onClick={onFilterClick}>
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const useStyles = makeStyles(() => ({
  description: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap' /* Don't forget this one */,
    textOverflow: 'ellipsis',
    maxWidth: 300,
  },
}));

export default function EnhancedTable() {
  const classes = { ...useStandardTableStyles(), ...useStyles() };
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();

  const [couponTypeFilter, setCouponTypeFilter] = React.useState<string>('all');
  const [discountTypeFilter, setDiscountTypeFilter] = React.useState<string>('all');
  const [publishedFilter, setPublishedFilter] = React.useState<any>('all');
  const [activeFilter, setActiveFilter] = React.useState<any>('all');
  const [filterPanel, togglePanel] = React.useState<boolean>(false);
  const [sortDirection, setDirection] = React.useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = React.useState<string>('startAt');
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [autogenerated, setAutogenerated] = React.useState<string>('0');
  const [couponList, setCouponList] = React.useState<ICoupon[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 10, page: 1, total: 0, totalPages: 0 });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchCouponList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, sortDirection, rowsPerPage, autogenerated]);

  const fetchCouponList = () => {
    getCouponList({
      page,
      sortDirection: sortDirection.toUpperCase() as 'ASC' | 'DESC',
      limit: rowsPerPage,
      autogenerated,
      couponTypeFilter,
      discountTypeFilter,
      publishedFilter,
      activeFilter,
    }).then((response) => {
      if (response) {
        setLoading(false);
        setCouponList(response.data.data.items);
        setMeta(response.data.data.meta);
      }
    });
  };

  const handleRequestSort = (event: any, property: 'asc' | 'desc') => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDelete = (id: number | string | undefined) => {
    if (id) {
      deleteCoupon(id as string).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          fetchCouponList();
        }
      });
    }
  };

  const handleEdit = (data: ICoupon) => {
    dispatch(editCouponData(data));
    dispatch(push(`${pageLinks.createEditCoupon}?couponId=${data.id}`));
  };

  const toggleFilterPanel = () => {
    togglePanel(!filterPanel);
  };

  const createCoupon = () => {
    dispatch(editCouponData(null));
    dispatch(push(pageLinks.createEditCoupon));
  };

  const handleFilterApply = () => {
    if (page !== 1) {
      setPage(1);
      return;
    }
    fetchCouponList();
  };

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPage(1);
    setAutogenerated(String(newValue));
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Coupons</Title>
            <Button
              onClick={createCoupon}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<AddCircleIcon />}
            >
              Create Coupon
            </Button>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <Tabs
          value={Number(autogenerated)}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabClick}
          aria-label="disabled tabs example"
        >
          <Tab label="Other" disabled={loading} />
          <Tab label="Free Quest coupons" disabled={loading} />
        </Tabs>

        <EnhancedTableToolbar onFilterClick={toggleFilterPanel} />

        {filterPanel && (
          <Grid container spacing={2} alignItems={'center'} className={classes.filterRow}>
            <Grid item xs={12} sm={6} lg={2}>
              <InputSelect
                label={'Published'}
                value={publishedFilter}
                onChange={(e): void => setPublishedFilter(e.target.value)}
                options={[
                  { value: 1, label: 'Published' },
                  { value: '0', label: 'Not Published' },
                  { value: 'all', label: 'All' },
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={2}>
              <InputSelect
                label={'Active'}
                value={activeFilter}
                onChange={(e): void => setActiveFilter(e.target.value)}
                options={[
                  { value: 1, label: 'Active' },
                  { value: '0', label: 'InActive' },
                  { value: 'all', label: 'All' },
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={2}>
              <InputSelect
                label={'Coupon type'}
                value={couponTypeFilter}
                onChange={(e: React.ChangeEvent<any>): void => setCouponTypeFilter(e.target.value)}
                options={actions}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={2}>
              <InputSelect
                label={'Discount type'}
                value={discountTypeFilter}
                onChange={(e: React.ChangeEvent<any>): void =>
                  setDiscountTypeFilter(e.target.value)
                }
                options={[
                  { value: 'fixed', label: 'USD' },
                  { value: 'percentage', label: '%' },
                  { value: 'all', label: 'All' },
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={1}>
              <Button variant="contained" color="primary" fullWidth onClick={handleFilterApply}>
                Apply
              </Button>
            </Grid>
          </Grid>
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead
              classes={classes}
              order={sortDirection}
              orderBy={sortBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {loading && <TableSkeleton />}

              {!loading &&
                couponList?.map((coupon) => {
                  const {
                    couponCode,
                    startAt,
                    expiredAt,
                    description,
                    usageRulesDescription,
                    forAction,
                    discount,
                    currency,
                    perUserLimit,
                    id,
                  } = coupon;

                  const startDate = dateFns.format(new Date(startAt), 'dd/MM/yyyy hh:mm');
                  const expireAt = expiredAt
                    ? dateFns.format(new Date(expiredAt), 'dd/MM/yyyy hh:mm')
                    : '';

                  return (
                    <TableRow key={couponCode}>
                      <TableCell align="left">{couponCode}</TableCell>
                      <TableCell align="left">{startDate}</TableCell>
                      <TableCell align="left">{expireAt}</TableCell>
                      <TableCell align="left">
                        <span className={classes.description} title={description}>
                          {description}
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <span className={classes.description} title={usageRulesDescription}>
                          {usageRulesDescription}
                        </span>
                      </TableCell>
                      <TableCell align="left">{forAction}</TableCell>
                      <TableCell>
                        {discount}
                        {currency}
                      </TableCell>
                      <TableCell>{perUserLimit}</TableCell>

                      {!Number(autogenerated) && !coupon?.autogenerated && (
                        <div>
                          <TableCell align="left">
                            <Can perform={Permissions.updateCoupon}>
                              <IconButton
                                aria-label="edit"
                                title={'Edit'}
                                onClick={() => handleEdit(coupon)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Can>
                          </TableCell>
                          <TableCell align="left">
                            <Can perform={Permissions.deleteCoupon}>
                              <DeleteModalButton
                                name={couponCode}
                                entity={'Coupon'}
                                onDelete={() => handleDelete(id)}
                              />
                            </Can>
                          </TableCell>
                        </div>
                      )}
                    </TableRow>
                  );
                })}
              {!loading && !couponList?.length && (
                <TableRow>
                  <TableCell>There are no coupons</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
