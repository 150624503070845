import React from 'react';
import validateEmail from '../services/api/requests/validateEmail';

const useEmailValidator = () => {
  const [email, checkEmail] = React.useState<string | null>(null);
  const [emailError, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const validate = async () => {
      try {
        const response: any = await validateEmail(email as string);
        const { data } = response;
        if (!data?.isSameAddress && data?.isValid) {
          setError(null);
        }
        if (data?.isSameAddress) {
          setError('Email address already taken');
        }
      } catch (e) {
        setError(e.response.data.message);
      }
    };

    email && validate();
  }, [email]);

  return { emailError, validateEmail: (string: string) => checkEmail(string) };
};

export default useEmailValidator;
