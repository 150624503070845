import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FormikProps } from 'formik';
import * as React from 'react';

export const useStandardFormStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  formContainer: {
    maxWidth: 600,
  },
  formControlSelect: {
    width: '100%',
  },
  textWrap: {
    whiteSpace: 'normal',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  checkbox: {
    display: 'flex',
  },
  boxButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },

  purchaseRuleSetsContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '10px',
  },

  purchaseRuleContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '10px 10px 0 10px',
    justifyContent: 'space-between',
  },

  buttonContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },

  purchaseRuleControlContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    margin: '8px',
  },

  purchaseRuleControlsWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '10px',
  },

  purchaseRulePlanContainer: {
    marginTop: '8px',
    padding: '8px 20px',
  },

  bordered: {
    border: '1px solid',
    borderRadius: '7px',
    margin: '10px 0',
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  textSizeSmall: {
    textTransform: 'none',
  },

  rulesDescription: {
    marginTop: '10px',
  },
}));

export const createInput: <T>(
  fk: FormikProps<T>
) => (
  id: keyof T,
  label: string,
  md?: 6 | 12,
  type?: string,
  isRequired?: boolean,
  customChangeFn?: () => void,
  disabled?: boolean
) => React.ReactNode = (fk) => (
  id,
  label,
  md = 6,
  type = 'text',
  isRequired = false,
  customChangeFn,
  disabled
) => (
  <Grid item xs={12} md={md}>
    <TextField
      autoComplete={`${id}`}
      name={`${id}`}
      variant="outlined"
      fullWidth
      required={!!isRequired}
      id={`${id}`}
      type={type}
      label={label}
      disabled={disabled}
      error={!!(fk.errors[id] && fk.touched[id])}
      onBlur={fk.handleBlur}
      helperText={fk.touched[id] && fk.errors[id]}
      value={fk.values[id]}
      onChange={customChangeFn || fk.handleChange}
    />
  </Grid>
);
