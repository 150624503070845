import { AxiosResponse } from 'axios';
import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';

interface IConversionRate {
  currency: string;
  rate: number;
}

interface IRegion {
  id: number;
  name: string;
}

export interface IConversionTemplate {
  id: number;
  name: string;
  conversionFee: number;
  currencyRates: IConversionRate[];
  regions: IRegion[];
  createdAt: Date;
}

export interface IConversionTemplateResponse {
  data: {
    items: IConversionTemplate[];
    meta: IMeta;
  };
}

const request = (
  params: IPaginationParams
): Promise<AxiosResponse<IConversionTemplateResponse>> => {
  const query = createQueryParams<IPaginationParams>(params);
  return apiClient
    .get<IConversionTemplateResponse>(ApiUrls.conversionTemplatesBase + `?${query}`)
    .then((response) => response);
};

export default request;
