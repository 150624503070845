import { Box, Button, CssBaseline, Grid, Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import upsellingRuleRequests, {
  ICreateUpsellingRule,
  IUpsellingRule,
} from '../../services/api/requests/upsellingRules';
import { selectPlanForEditing, selectServicesOptions } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import { useStandardFormStyles } from '../FormBase';
import InputSelect from '../InputSelect';

interface IProps {
  ruleForEditing?: IUpsellingRule;
  existedList: IUpsellingRule[];
  onCancelClick(): void;

  onSuccess(): void;
}

const CreateEditUpsellingRule: React.FC<IProps> = (props) => {
  const classes = useStandardFormStyles();
  const plan = useSelector(selectPlanForEditing);
  const serviceOptions = useSelector(selectServicesOptions);
  const { ruleForEditing, existedList, onCancelClick, onSuccess } = props;
  const filtedServiceOptions = serviceOptions.filter(
    (option) =>
      ruleForEditing?.addon?.id === option.value ||
      !existedList.some((it) => it.addon.id === option.value)
  );

  const actionText = !!ruleForEditing ? 'Edit' : 'Create';

  const initialValues = {
    planId: plan?.id,
  };

  const fk: FormikProps<ICreateUpsellingRule> = useFormik({
    initialValues: ruleForEditing
      ? {
          planId: plan?.id,
          addonId: ruleForEditing.addon.id,
        }
      : initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: async (values: ICreateUpsellingRule) => {
      try {
        if (ruleForEditing) {
          await upsellingRuleRequests.editUpsellingRule(values, ruleForEditing.id);
          setNotification('success', {
            message: 'Success',
          });
          onSuccess();
          return;
        }

        await upsellingRuleRequests.createUpsellingRule(values);
        setNotification('success', {
          message: 'Success',
        });
        onSuccess();
      } catch (error) {
        console.error('Error: ', error);
      }
    },
  });

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display={'flex'}>
                  <InputSelect
                    id="fromId"
                    label={'Service*'}
                    value={fk.values.addonId}
                    required
                    onChange={(e) => fk.setFieldValue('addonId', e.target.value)}
                    options={filtedServiceOptions}
                    error={!!(fk.errors.addonId && fk.touched.addonId)}
                    onBlur={fk.handleBlur}
                  />
                  <IconButton
                    onClick={() => {
                      fk.setFieldValue('addonId', '');
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>

            <Box display={'flex'} justifyContent={'space-between'} className={classes.submit}>
              <Button
                type="button"
                disabled={!fk.isValid}
                variant="contained"
                color="primary"
                // @ts-ignore
                onClick={() => fk.handleSubmit(fk.values)}
              >
                {actionText}
              </Button>

              <Button type="button" onClick={onCancelClick} variant="outlined" color="primary">
                Cancel
              </Button>
            </Box>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditUpsellingRule;
