import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta } from '../types';

interface IOrderItem {
  id: number;
  action: 'UPGRADE' | 'RENEW' | 'INITIAL';
  exigoOrderId: number;
  amount: number;
  product: string;
  level: string;
}

export interface IOrder {
  amount: number;
  amountFee: number;
  amountVat: number;
  createdAt: string;
  id: number;
  items: IOrderItem[];
  membership: string;
  paymentMethod: string;
  product: string;
  rebillyTransactionID: string;
  status: string;
  verified: boolean;
  type: string;
  user: { id: number; username: string };
}

export interface IParams {
  page: number;
  limit: number;
  sortDirection: 'ASC' | 'DESC';
  adminSortKey: string;
  typeFilter: string;
  statusFilter: string;
  paymentMethodFilter: string;
  toDate: string | Date;
  fromDate: string | Date;
  searchByUser: string;
}

export interface IOrderListResponse {
  data: {
    items: IOrder[];
    meta: IMeta;
  };
}

const request = (params: IParams) => {
  const query = createQueryParams<IParams>(params);
  return apiClient
    .get<IOrderListResponse>(ApiUrls.orderList + `?${query}`)
    .then((response) => response);
};

export default request;
