import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';
import { IUpgradeRule } from './createUpgradeRule';
import { IProduct } from './getProducts';

export interface IUpgradeRuleItem extends IUpgradeRule {
  id: string;
}

export interface IPlan {
  CV: number;
  FSB: number;
  QV: number;
  SCV: number;
  SQV: number;
  additionalInfo: null | string;
  adjustPrice: number;
  adjustRecurringPrice: number;
  autopayable: boolean;
  bgColor: null | string;
  bullets: null | Array<any>;
  color: null | string;
  comboData: null | string;
  countries: string[];
  currency: 'USD' | 'EUR';
  discountExigoItem: null | string;
  fsbEnabled: boolean;
  fullDescription: null | string;
  intervalSize: null | number;
  intervalUnit: null | string;
  isAddon: boolean;
  itemDescription: null | string;
  priority: number;
  product?: IProduct;
  createdAt?: Date;
  description: null | string;
  id: number;
  imageUrl: string;
  name: string;
  questDollarsReward: number;
  rebillyPlanId: string;
  recurringCV: number;
  recurringFSB: number;
  recurringPrice: number;
  recurringQV: number;
  recurringRetailPrice: number;
  recurringSCV: number;
  recurringSKU: string;
  recurringSQV: number;
  regionalDatasets: Array<any>;
  retailExigoItem: null | string;
  retailPrice: number;
  setupPrice: number;
  sku: string;
  updatedAt?: Date;
  upgradeRuleFrom: [];
  upgradeRuleTo: [];
  oneDayRecurringSKU: string;
  comboName: string;
  comboExigoItem: string;
  comboDiscountCV: number;
  comboDiscountQV: number;
  comboDiscountSCV: number;
  comboDiscountSQV: number;
  comboDiscountFSB: number;
  comboDiscount: number;
  comboRetailDiscount: number;
  isRecurring: boolean;
  hasRetail: boolean;
  noExigo: boolean;
  status: string;
  requireAgreement: boolean;
  enrollerCashback: number;
  customerCashback: number;
}

export interface IProductWithPlans {
  createdAt: string;
  description: string;
  id: number;
  imageUrl: string;
  name: string;
  plans: IPlan[];
  updatedAt: string;
  upgradeRules: IUpgradeRuleItem[];
  bgColor: string;
  color: string;
  fullImageUrl: string;
  priority: number;
  rebillyProductId: string;
  urlParam: string;
  status: string;
}

const request = (productId: string) =>
  apiClient
    .get<IResponseAxios<IProductWithPlans>>(ApiUrls.baseProduct + productId)
    .then((response) => response);

export default request;
