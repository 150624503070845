import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface ICountry {
  id: number;
  code: string;
  name: string;
  customBaseUrl: string;
  customCompanyName: string;
  phoneVerificationEnabled: boolean;
}

export enum INSTANCE_TYPES {
  MHA_LOGIN = 'mha_login',
  TRAVEL_LOGIN = 'travel_login',
  SHOP = 'shop',
  IBI_SHOP = 'ibi_shop',
  KEY_REPORT = 'key_report',
  BE_PAY = 'be_pay',
  CUSTOMER_PORTAL = 'customer_portal',
}

export interface IGetInstance {
  code: string;
  countries: ICountry[];
  id: number;
  priority: number;
  name: string;
  defaultBaseUrl: string;
  companyName: string;
  websiteId: string;
  createdAt: Date;
  updatedAt: Date;
  baseCountry: ICountry;
  type: INSTANCE_TYPES;
}

const request = () => apiClient.get<IGetInstance[]>(ApiUrls.instances).then((response) => response);
export default request;
