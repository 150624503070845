import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IParams {
  status: string;
  id: number;
}

const request = ({ status, id }: IParams) => {
  const url = ApiUrls.changePlanStatus.replace('{id}', `${id}`);
  return apiClient.patch<IResponseAxios<IParams>>(url, { status }).then((response) => response);
};

export default request;
