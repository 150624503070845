import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import Modal from '../Modal';

interface IProps {
  name: string;
  entity: string;
  title?: string;
  onDelete(): void;
}

const useStyles = makeStyles(() => ({
  boxButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
}));

const DeleteModalButton: React.FC<IProps> = ({ entity, name, title = 'Delete', onDelete }) => {
  const classes = useStyles();

  return (
    <Modal
      button={
        <IconButton aria-label="delete" title={title}>
          <DeleteIcon />
        </IconButton>
      }
      renderContent={(onClose: () => void) => (
        <div>
          <h2>Delete {entity}</h2>
          <div>
            Do you really want to delete an item <strong>{name} ?</strong>
          </div>

          <Box className={classes.boxButton}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => {
                onDelete();
                onClose();
              }}
            >
              Delete
            </Button>
            <Button variant="contained" color="default" onClick={() => onClose()}>
              Cancel
            </Button>
          </Box>
        </div>
      )}
    />
  );
};

export default DeleteModalButton;
