import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteGetTagProps,
} from '@material-ui/lab/Autocomplete';
import { chain } from 'lodash';
import React from 'react';
import createOptionList, { syncGroupWithOptions } from '../../hooks/permission';

export interface IPermissionOption {
  value: number[];
  label: string;
  groupName: string;
  priority: number;
  isGroup: boolean;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

interface IProps {
  value: number[];
  onChange(a: string, b: number[]): void;
  onBlur?(e: React.ChangeEvent<HTMLInputElement>): void;
}

const PermissionSelect: React.FC<IProps> = ({ value, onChange }) => {
  const classes = useStyles();
  const { selected, options, selectedGroupIds } = createOptionList(value as number[]);

  const onSelectChange = (
    e: any,
    selectedOptions: IPermissionOption[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<IPermissionOption>
  ) => {
    const isOptionRemoved = reason === 'remove-option';
    const filteredList = details?.option && syncGroupWithOptions(selectedOptions, details?.option);
    const values: number[] = chain(isOptionRemoved ? filteredList : selectedOptions)
      .map((i) => i.value)
      .flatten()
      .uniq()
      .value();
    onChange('permissionIds', values);
  };

  const renderChips = (tagValue: IPermissionOption[], getTagProps: AutocompleteGetTagProps) =>
    tagValue.map((option: IPermissionOption, index: number) => {
      //do not display selected permission chip, if it is included in selected group
      if (!option.isGroup && selectedGroupIds.includes(option.value[0])) {
        return null;
      }
      return (
        <Chip
          {...getTagProps({ index })}
          label={option.label}
          color={option.isGroup ? 'primary' : 'default'}
        />
      );
    });

  return (
    <Autocomplete
      id="permissionIds"
      options={
        options
          .sort((a, b) => -b.groupName.localeCompare(a.groupName))
          .sort((a, b) => a.priority - b.priority) as IPermissionOption[] | []
      }
      value={selected}
      onChange={onSelectChange}
      classes={{ option: classes.option }}
      autoHighlight
      multiple
      fullWidth
      openOnFocus
      getOptionLabel={(option) => option.label}
      groupBy={(option) => option.groupName}
      renderTags={renderChips}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a permission or a group"
          variant="outlined"
          fullWidth
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default PermissionSelect;
