import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminDeactivateModal from '../../components/AdminDeactivateModal';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import InputSearch from '../../components/InputSearch';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import deleteAdmin from '../../services/api/requests/deleteAdmin';
import editAdmin from '../../services/api/requests/editAdmin';
import getAdminList, { IAdmin, IMeta } from '../../services/api/requests/getAdminList';
import { editAdminUser } from '../../store/entities/actions';
import { selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const headCells = [
  { id: 'first_name', numeric: false, disablePadding: false, label: 'First Name' },
  { id: 'last_name', numeric: false, disablePadding: false, label: 'Last Name' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'role_name', numeric: false, disablePadding: false, label: 'Role' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date Created' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Date Updated' },
];

const EnhancedTable: React.FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();
  const permissions = useSelector(selectUserPermissions);
  const [sortDirection, setDirection] = React.useState<'asc' | 'desc'>('desc');
  const [sortBy, setSortBy] = React.useState<string>('updatedAt');
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [adminList, setList] = React.useState<IAdmin[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [meta, setMeta] = React.useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (permissions?.includes(Permissions.readAdminList)) {
      fetchAdminList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, sortDirection, rowsPerPage, searchTerm]);

  const fetchAdminList = async () => {
    try {
      const response = await getAdminList({
        page,
        sortBy,
        sortDirection,
        limit: rowsPerPage,
        searchTerm,
      });

      setLoading(false);
      setList(response.data.data.items);
      setMeta(response.data.data.meta);
    } catch (e) {
      console.warn('EWEWE', e);
    }
  };

  const createUser = () => {
    dispatch(push(pageLinks.createEditAdmin));
    dispatch(editAdminUser(null));
  };

  const handleRequestSort = (event: any, property: 'asc' | 'desc') => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteAdmin(id);
      fetchAdminList();
      setNotification('success');
    } catch (e) {
      console.warn('DELETE_ADMIN_ERROR', e);
    }
  };

  const handleEdit = (data: IAdmin) => {
    dispatch(editAdminUser(data));
    dispatch(push(pageLinks.createEditAdmin));
  };

  const onDeactivateAdmin = async (id: number) => {
    const admin = adminList?.find((a) => a.id === id);
    if (admin) {
      try {
        await editAdmin({ isActive: !admin.isActive, id });
        fetchAdminList();
        setNotification('success');
      } catch (e) {}
    }
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Admins</Title>
            <Can perform={Permissions.createAdmin}>
              <Button
                onClick={createUser}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add User
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <div className={classes.searchRow}>
          <InputSearch
            onSubmit={(val) => {
              setPage(1);
              setSearchTerm(val);
            }}
          />
        </div>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead
              classes={classes}
              order={sortDirection}
              orderBy={sortBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {loading && <TableSkeleton />}
              {!loading &&
                adminList?.map((admin) => {
                  const { id, firstName, lastName, createdAt, updatedAt, role, email, isActive } =
                    admin;
                  const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
                  const update = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');
                  const name = `${firstName} ${lastName}`;

                  return (
                    <TableRow key={id}>
                      <TableCell align="left">{firstName}</TableCell>
                      <TableCell align="left">{lastName}</TableCell>
                      <TableCell align="left">{email}</TableCell>
                      <TableCell align="left">{role.name}</TableCell>
                      <TableCell>{create}</TableCell>
                      <TableCell>{update}</TableCell>
                      <TableCell align="left">
                        <Can perform={Permissions.updateAdmin}>
                          <IconButton
                            aria-label="edit"
                            title={'Edit'}
                            onClick={() => handleEdit(admin)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Can>
                      </TableCell>
                      <TableCell align="left">
                        <Can perform={Permissions.updateAdmin}>
                          <AdminDeactivateModal
                            onDeactivateAdmin={() => onDeactivateAdmin(id)}
                            status={isActive}
                            disabled={role.name === 'super_admin'}
                            name={name}
                          />
                        </Can>
                      </TableCell>
                      <TableCell align="left">
                        <Can perform={Permissions.deleteAdmin}>
                          <DeleteModalButton
                            name={name}
                            entity={'User'}
                            onDelete={() => handleDelete(id)}
                          />
                        </Can>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {!loading && !adminList?.length && (
                <TableRow>
                  <TableCell>
                    There are no admins{' '}
                    {searchTerm.length > 0 ? `for current search - ${searchTerm} ` : ''}{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedTable;
